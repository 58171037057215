import { firebase, timestamp, db } from "../../services/firebase";
import {
    requestLogin, receiveLogin, loginError,
    requestLogout, receiveLogout, logoutError,
    verifyRequest, verifySuccess, verifyFailure,
    requestRegistration, receiveRegistration, registrationError,
    requestReset, recieveReset, resetError,
    receiveUser,
    assessmentLogout, resetAssessment,
    hideAuthNotification, updateAdmin,
    verifyCodeRequest, verifyCodeSuccess, verifyCodeFailure, verifyCodeReset,
    verifyEmailRequest, verifyEmailSuccess, verifyEmailFailure,
    claimCodeRequest, claimCodeSuccess, claimCodeFailure, claimCodeReset,
    setRegistation, setCode, setEmail, resetNewAssets
} from '../actions';

//login
export const loginUser = (data, code) => dispatch => {
    //dispatch request login (above) which tells app a user is logging in
    dispatch(requestLogin());
    //get firebase auth instance and pass credentials to handle login
    try {
        firebase
            .auth()
            .signInWithEmailAndPassword(data.email.toLowerCase(), data.password)
            .then(response => {
                //retrieve user
                const usersRef = firebase.firestore().collection('users').doc(response.user.uid);
                usersRef.get().then(async doc => {
                    if (doc.exists) {
                        // if (code) {
                        //     await dispatch(_claimCode(code, data.email))
                        // }
                        const userInfo = doc.data()
                        updateAdmin(false)
                        //store login response
                        dispatch(receiveLogin(response));
                        //store in userInfo 
                        dispatch(receiveUser(userInfo))
                    } else {
                        dispatch(loginError('User not found in collection.'));
                        // dispatch(_timeout(hideAuthNotification()));
                    }
                }).catch(error => {
                    console.log('error: ', error)
                        dispatch(loginError(error.message));
                        dispatch(_timeout(hideAuthNotification()));
                    });
            }).catch(error => {
                dispatch(loginError(error.message));
                //dispatch(_timeout(hideAuthNotification()));
            });
    } catch (error) {
        dispatch(loginError(error.message));
        //dispatch(_timeout(hideAuthNotification()));
    }
};
//admin login
export const loginAdmin = (data) => async dispatch => {
    //dispatch request login (above) which tells app a user is logging in
    dispatch(requestLogin());
    //get firebase auth instance and pass credentials to handle login
    try {

        //verify user exists and if is admin then authenicate
        const usersRef = db.collection('users').where('email', '==', data.email.toLowerCase()).limit(1);
        await usersRef.get().then(snapshot => {
            snapshot.forEach(doc => {
                if (doc.exists) {
                    const userInfo = doc.data()
                    //verify admin
                    if (userInfo.roles.admin) {
                        //authenticate

                        firebase
                            .auth()
                            .signInWithEmailAndPassword(data.email, data.password)
                            .then(response => {
                                if (response.user) {
                                    //allow admin access
                                    dispatch(updateAdmin(true))
                                    //store login response
                                    dispatch(receiveLogin(response));
                                    //store in userInfo 
                                    dispatch(receiveUser(userInfo))
                                }
                            })
                            .catch(error => {
                                dispatch(loginError(error.message));
                                dispatch(_timeout(hideAuthNotification()));
                            });
                    } else {
                        dispatch(loginError('You do not have administrative access.'));
                        dispatch(_timeout(hideAuthNotification()));
                    }
                } else {
                    dispatch(loginError('User not found in collection.'));
                    dispatch(_timeout(hideAuthNotification()));
                }
            })
        })
            .catch(error => {
                dispatch(loginError(error.message));
                dispatch(_timeout(hideAuthNotification()));
            });
    } catch (error) {
        dispatch(loginError(error.message));
        dispatch(_timeout(hideAuthNotification()));
    }
};
//logout
export const logoutUser = () => dispatch => {
    dispatch(requestLogout());
    try {
        firebase
            .auth()
            .signOut()
            .then(() => {
                dispatch(receiveLogout());
                dispatch(assessmentLogout());
                dispatch(resetAssessment());
            })
            .catch((error) => {
                dispatch(logoutError(error.message));
                dispatch(_timeout(hideAuthNotification()));
            });
    } catch (error) {
        dispatch(logoutError(error.message));
        dispatch(_timeout(hideAuthNotification()));
    }
};

//verify auth 
export const verifyAuth = () => dispatch => {
    dispatch(verifyRequest());
    try {
        //look for a preexisting user session and re-establish it 
        firebase.auth().onAuthStateChanged(response => {
            if (response !== null) {
                if (response.uid); {
                    //retrieve user
                    const usersRef = firebase.firestore().collection('users').doc(response.uid);
                    //verify data
                    usersRef.get().then(doc => {
                        if (doc.exists) {
                            dispatch(verifySuccess());
                        } else {
                            //console.log("No document found: collection('users')");
                            dispatch(verifyFailure());
                            dispatch(_timeout(hideAuthNotification()));
                        }
                    })
                        .catch(error => {
                            console.log(error.message);
                            dispatch(verifyFailure());
                            dispatch(_timeout(hideAuthNotification()));
                        });
                }
            } else {
                dispatch(verifyFailure());
                dispatch(_timeout(hideAuthNotification()));
            }
        });
    } catch (error) {
        dispatch(verifyFailure());
        dispatch(_timeout(hideAuthNotification()));
    }
};

//register
export const registerUser = (data, code) => dispatch => {
    dispatch(requestRegistration());

    try {
        //1.create firebase user
        firebase
            .auth()
            .createUserWithEmailAndPassword(data?.email.toLowerCase(), data?.password)
            .then(async response => {

                if (!response?.user?.uid) {
                    dispatch(registrationError('no response.user.uid'));
                    dispatch(_timeout(hideAuthNotification()));
                    return;
                }

                //on register success, create new user firestore collection and update redux state 
                const uid = response?.user?.uid;
                var userData = {
                    id: uid,
                    email: data?.email.toLowerCase(),
                    phone: data?.phoneNumber,
                    name: {
                        first: data?.firstName,
                        last: data?.lastName
                    },
                    company: {
                        //name: data?.companyName,
                        addressOne: data?.addressOne ? data.addressOne : '',
                        addressTwo: data?.addressTwo ? data.addressTwo : '',
                        city: data?.city ? data.city : '',
                        state: data?.state ? data.state : '',
                        zip: data?.zip ? data.zip : '',
                        poc: data?.poc ? data.poc : '', //point of contact
                        //title: '',//data?.jobTitle,
                    },
                    roles: {
                        admin: false
                    },
                    date: timestamp,
                    toc: timestamp
                };

                //2. write document in users table 
                const usersRef = firebase.firestore().collection('users').doc(uid);
                await usersRef.set(userData);
                //3. read document and confirm success
                usersRef.get().then(async doc => {
                    if (doc.exists) {
                        const userInfo = doc.data()
                        //store in userInfo 
                        if (code) {
                            await dispatch(_claimCode(code.accessCode, data))
                            dispatch(resetNewAssets())
                        }
                        dispatch(receiveUser(userInfo))
                        dispatch(receiveRegistration(response));
                    } else {
                        dispatch(registrationError('No document found: collection(users)'));
                        dispatch(_timeout(hideAuthNotification()));
                    }
                })
                    .catch(error => {
                        console.log('err1: ', error.message);
                        dispatch(registrationError(error.message));
                        dispatch(_timeout(hideAuthNotification()));
                    });
            })
            .catch(error => {
                console.log('err2: ', error.message);
                dispatch(registrationError(error.message));
                dispatch(_timeout(hideAuthNotification()));
            });
    } catch (error) {
        console.log('err3: ', error.message);
        dispatch(registrationError(error.message));
        dispatch(_timeout(hideAuthNotification()));
    }
}

export const updateUser = (data, assets) => async dispatch => {
    try {
        const history = db.collection('users');
        const query = history.where('email', '==', data.email).limit(1);

        await query.get().then((snapshot) => {
            //get assessment
            snapshot.forEach(async doc => {
                if (doc.exists) {
                    //update user
                    await doc.ref.update({
                        phone: data?.phoneNumber,
                        name: {
                            first: data?.firstName,
                            last: data?.lastName
                        },
                        company: {
                            name: data?.companyName ? data.companyName : '',
                            addressOne: data?.addressOne ? data.addressOne : '',
                            addressTwo: data?.addressTwo ? data.addressTwo : '',
                            city: data?.city ? data.city : '',
                            state: data?.state ? data.state : '',
                            zip: data?.zip ? data.zip : '',
                            poc: data?.poc ? data.poc : '', //point of contact                
                        },
                        toc: timestamp
                    })
                    await Promise.all(assets.map(async (asset) => {
                        await dispatch(_claimCode(asset.accessCode, data))
                    }))
                    dispatch(resetNewAssets())

                } else {
                    console.log("existing user could not be found")
                }
            });
        }).catch((error) => {
            console.log("error1: ", error)
        });
    } catch (error) {
        console.log("main catch: ", error)
    }
}

export const userAcceptToC = (data) => async dispatch => {
    try {
        const history = db.collection('users');
        const query = history.where('email', '==', data.email).limit(1);

        await query.get().then((snapshot) => {
            //get assessment
            snapshot.forEach(async doc => {
                if (doc.exists) {
                    //update user
                    await doc.ref.update({
                        toc: timestamp
                    })
                } else {
                    console.log("existing user could not be found")
                }
            });
        }).catch((error) => {
            console.log("error1: ", error)
        });
    } catch (error) {
        console.log("main catch: ", error)
    }
}

//reset
export const resetPassword = (data) => dispatch => {
    dispatch(requestReset());
    try {
        firebase
            .auth()
            .sendPasswordResetEmail(data.email)
            .then(() => {
                dispatch(recieveReset());
            })
            .catch(error => {
                dispatch(resetError(error.message));
            });
    } catch (error) {
        dispatch(resetError(error.message));
    }
}

//verify access code feature
export const verifyCode = (code) => dispatch => {

    if (!code) {
        dispatch(verifyCodeFailure('Missing access code param'));
        dispatch(_timeout(verifyCodeReset()));
        return;
    }

    dispatch(verifyCodeRequest());

    try {
        //pull the record 
        const query = db.collection('purchaseHistory').where('accessCode', '==', code).limit(1);
        query.get().then(async querySnapshot => {

            if (!querySnapshot.empty) {
                const purchase = querySnapshot.docs[0].data();

                //update the email if field is empty
                if (purchase.isCompleted) {
                    dispatch(verifyCodeFailure('Sorry, this assessment has already been taken.'));
                    return
                }
                if (purchase.isExpired) {
                    dispatch(verifyCodeFailure('Sorry, this assessment has expired.'));
                    return
                }
                // if (purchase.isClaimed) {
                //     dispatch(verifyCodeFailure('Sorry, this assessment has already been associated with another entity.'));
                //     return
                // }

                dispatch(verifyCodeSuccess(purchase));

                // if (purchase.ownerEmail === 'adminPurch@vectorreports.com' && !purchase.isCompleted && !purchase.isExpired) {
                //     dispatch(verifyCodeSuccess(code));
                // } else {
                //     console.log('This Access Code is already assigned1');
                //     dispatch(verifyCodeFailure('Sorry, this assessment has already been claimed.'));
                // }
            } else {
                console.log('This Access code does not Exist1');
                dispatch(verifyCodeFailure('We do not recognize this entry in our system. Please provide a valid entry.'));
            }
        }).catch(error => {
            console.log(error.message);
            dispatch(verifyCodeFailure(error.message));
        });
        //create record to test 
    } catch (error) {
        console.log(error.message)
        dispatch(verifyCodeFailure(error.message));
    }

    //reset notificatiion values 
    //dispatch(_timeout(verifyCodeReset()));
}

//verrify user email
export const verifyEmail = (email, checkRole) => dispatch => {

    try {
        if (email) {
            dispatch(verifyEmailRequest());
            // //retrieve user
            const query = db.collection('users').where('email', '==', email).limit(1);
            query.get().then(async querySnapshot => {
                if (!querySnapshot.empty) {
                    const email = querySnapshot.docs[0].data()?.email;
                    dispatch(verifyEmailSuccess(email));
                } else {
                    console.log('This email does not exist');
                    //check if user is assigned as owner or assessor if they do not have login creds
                    if (checkRole) {
                        const data = await _checkRole(email)
                        if (data[0]) {
                            dispatch(setCode({ accessCode: data[1] }))
                            dispatch(setEmail(email))
                            dispatch(setRegistation(true))
                        }
                    }
                    dispatch(setEmail(email))
                    dispatch(verifyEmailFailure('This email does not exist'));

                }
            }).catch(error => {
                console.log(error.message);
                dispatch(verifyEmailFailure(error.message));
            });
        } else {
            console.log('email is undefined');
            dispatch(verifyEmailFailure('email is undefined'));
        }
    } catch (error) {
        console.log(error.message);
        dispatch(verifyEmailFailure(error.message));
    }
}

const _checkRole = async (email) => {
    let exists = false;
    let code = null;

    try {
        const ownerQuery = db.collection('purchaseHistory').where('owner.email', '==', email).limit(1);
        const assessorQuery = db.collection('purchaseHistory').where('assessor.email', '==', email).limit(1);
        const proposedQuery = db.collection('purchaseHistory').where('proposedOwner', '==', email).limit(1);

        await ownerQuery.get().then(snapshot => {
            snapshot.forEach(async doc => {
                if (doc.exists) {
                    exists = true
                    code = doc.data().accessCode
                }
            });
        }).catch(error => {
            console.log('_checkRole: ', error.message);
        });

        await assessorQuery.get().then(snapshot => {
            snapshot.forEach(doc => {
                if (doc.exists) {
                    exists = true
                    code = doc.data().accessCode
                }
            });
        }).catch(error => {
            console.log('_checkRole: ', error.message);
        });

        await proposedQuery.get().then(snapshot => {
            snapshot.forEach(doc => {
                if (doc.exists) {
                    exists = true
                    code = doc.data().accessCode
                }
            });
        }).catch(error => {
            console.log('_checkRole: ', error.message);
        });
    } catch (error) {
        console.log('_checkRole: ', error.message);
    }

    return [exists, code]
}

export const claimAllCodes = (codes, user) => async dispatch => {
    await Promise.all(codes.map(async (asset) => {
        await dispatch(_claimCode(asset.accessCode, {
            email: user.email,
            firstName: user.name?.first,
            lastName: user.name?.last
        }))
    }))
    dispatch(resetNewAssets())
}

//claim access code feature
const _claimCode = (code, user) => async dispatch => {

    if (!code) {
        console.log('Missing access code param');
        dispatch(claimCodeFailure('Missing access code param'));
        dispatch(_timeout(claimCodeReset()));
        return;
    }

    dispatch(claimCodeRequest());

    try {
        //pull the record 
        const query = db.collection('purchaseHistory').where('accessCode', '==', code).limit(1);
        await query.get().then(async querySnapshot => {

            if (!querySnapshot.empty) {
                const purchase = querySnapshot.docs[0].data();
                //update the email if field is empty
                if (purchase?.proposedOwner || purchase?.owner?.isClaimed === false || purchase?.assessor?.isClaimed === false) {
                    let data
                    if (purchase.assessor.email === user.email) {
                        data = {
                            assessor: {
                                isClaimed: true,
                                name: `${user.firstName} ${user.lastName}`,
                                email: user.email
                            }
                        }
                        if (purchase.owner.email === user.email) {
                            data = {
                                ...data,
                                owner: {
                                    isClaimed: true,
                                    name: `${user.firstName} ${user.lastName}`,
                                    email: user.email
                                }
                            }
                        }
                    } else if (purchase.proposedOwner === user.email) {
                        data = {
                            owner: {
                                email: user.email,
                                isClaimed: true,
                                name: `${user.firstName} ${user.lastName}`,
                            },
                            proposedName: '',
                            proposedOwner: '',
                            previousOwner: purchase.owner.email,
                            assessor: {
                                isClaimed: false,
                                name: '',
                                email: ''
                            }
                        }
                    } else if (purchase.owner?.email === 'adminpurch@vectorreports.com' || purchase.owner?.email === user.email) {
                        data = {
                            owner: {
                                email: user.email,
                                isClaimed: true,
                                name: `${user.firstName} ${user.lastName}`,
                            }
                        }
                    }

                    try {
                        await querySnapshot.docs[0].ref.update(data)
                        dispatch(claimCodeSuccess(code, data, purchase));
                    } catch (err) {
                        console.log(error.message);
                        dispatch(claimCodeFailure(error.message));
                    }
                } else {
                    console.log('This Access Code is already assigned2');
                    dispatch(claimCodeFailure('This Access Code is already assigned'));
                }
            } else {
                console.log('This Access code does not Exist2');
                dispatch(claimCodeFailure('This Access code does not exist'));
            }
        }).catch(error => {
            console.log(error.message);
            dispatch(claimCodeFailure(error.message));
        });
        //create record to test 
    } catch (error) {
        console.log(error.message)
        dispatch(claimCodeFailure(error.message));
    }

    //reset notificatiion values 
    dispatch(_timeout(claimCodeReset()));
}


const _timeout = (action) => dispatch => {
    setTimeout(() => {
        dispatch(action);
    }, 10000);
}