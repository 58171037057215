import React, { useEffect, useState, useRef } from "react";
import { TextField } from "@mui/material"; // Assuming you're using MUI v5
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { updateInhibitor, updateTempInhibitor } from "src/redux/actions";

const KnownInhibitors = ({ categoryIndex, processIndex }) => {
    const dispatch = useDispatch();

    const categories = useSelector((state) => state.takingAssessment.evaluation);
    const tempInhibitor = useSelector((state) => state.takingAssessment.tempInhibitor);

    const [localInhibitor, setLocalInhibitor] = useState("");
    const textRef = useRef(null);

    useEffect(() => {
        // Initialize temp and local inhibitors
        dispatch(updateTempInhibitor(""));
        if (tempInhibitor) {
            setLocalInhibitor(tempInhibitor);
        } else {
            const inhibitor = categories?.[categoryIndex]?.processes?.[processIndex]?.inhibitor;
            if (inhibitor) setLocalInhibitor(inhibitor);
        }
    }, []);

    useEffect(() => {
        return () => {
            // On unmount, update the inhibitor with the cached value
            if (textRef.current?.length) {
                const newInhibitor = textRef.current === "***" ? "" : textRef.current;
                dispatch(updateInhibitor(newInhibitor, categoryIndex, processIndex));
            }
        };
    }, [categoryIndex, processIndex, dispatch]);

    const handleInhibitorChange = (event) => {
        const value = event.target.value;

        setLocalInhibitor(value);
        dispatch(updateTempInhibitor(value));

        textRef.current = value.length === 0 ? "***" : value;
    };

    return (
        <TextField
            label="Known inhibitors to improvement notes"
            multiline
            rows={3}
            fullWidth
            InputLabelProps={{ style: { fontStyle: "italic" } }}
            sx={{ backgroundColor: "white", mt: 1 }}
            value={localInhibitor}
            onChange={handleInhibitorChange}
            inputProps={{ maxLength: 330 }}
        />
    );
};

KnownInhibitors.propTypes = {
    categoryIndex: PropTypes.number.isRequired,
    processIndex: PropTypes.number.isRequired,
};

export default KnownInhibitors;
