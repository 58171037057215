import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'; 

import { connect  } from 'react-redux';
import { 
    updateSelection, 
    updateCategoryCounter, 
    updateProcessCounter, 
    resetAssessment,
    updateShowMainIntro,
    updateFinalScores, 
    updateReportContent,
    ownershipDataSuccess,
} from 'src/redux/actions';
import { getAdminReports, createAssessmentHistory } from 'src/redux/thunks';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

import { Helmet } from 'react-helmet-async';

import Introduction from 'src/components/assessment/Introduction'
import Process from 'src/components/assessment/Process'
import ProcessIntro from 'src/components/assessment/ProcessIntro'
import Footer from 'src/components/shared/Footer';

const AssessmentEvaluation = (props) => {

    const containerRef = useRef()

    //redux
    const {
        categories,
        updateSelection,
        globalCategoryCounter,
        globalProcessCounter,
        updateGlobalCategoryCounter,
        updateGlobalProcessCounter,
        resetAssessment,
        showMainIntro,
        updateShowMainIntro,
        didComplete,
        updateFinalScores,
        getAdminReports,
        adminReports,
        updateReportContent,
        assessmentType,
        userInfo,
        accessCode,
        assessorData,
        ownerEmail,
        createAssessmentHistory
    } = props;

    //state
    const [data, setData] = useState();
    const [categoryLength, setCategoryLength] = useState(0);
    const [processLength, setProcessLength] =  useState(0);
    const [openSubmit, setOpenSubmit] = useState(false);
    const [openLeaving, setOpenLeaving] = useState(false);
    //counters
    const [categoryCounter, setCategoryCounter] = useState(0); //categories
    const [processCounter, setProcessCounter] = useState(-1); //processes per category, a -1 shows the intro
    //variables
    const navigate = useNavigate();
    const classes = useStyles();

    useEffect(() => {
        getAdminReports()
    },[])

    useEffect(() => {
        //set data 
        if (categories) {
            setCategoryLength(categories.length);
            setProcessLength(categories[0].processes.length);
            setData(categories);
        }
    },[categories])

    //update category
    useEffect(() => { 
        if (Number.isInteger(globalCategoryCounter)){
            setCategoryCounter(globalCategoryCounter);
        }
        //setOpenSubmit(false)
    },[globalCategoryCounter])

    //update process
    useEffect(() => { 
        if (Number.isInteger(globalProcessCounter)){
            setProcessCounter(globalProcessCounter);
        }
        containerRef.current.scrollTop = 0
        //setOpenSubmit(false)
    },[globalProcessCounter])
    
    //handlers
    const handleBackClick = () => {
        /* cases
         * 1. beginning of assessment - show first <ProcessIntro />
         * 2. when there are processes behind you - can move back
         * 3. when backing into an intro - displays <ProcessIntro />
         * 4. when backing past an intro
         */

        const processIndexLength = processLength - 1;

        //at beginning of assessment
        if (categoryCounter === 0 && processCounter === 0) {
            //shows intro page for new category
            updateGlobalProcessCounter(-1);
        } else if (processCounter <= processIndexLength && processCounter > 0) {
            //when there are processes behind you - can move back
            updateGlobalProcessCounter(globalProcessCounter - 1);
            //setOpenSubmit(false);
        } else if (processCounter === 0) {
            //when you need to switch to the previous category
            //shows intro page for new category
            updateGlobalProcessCounter(globalProcessCounter - 1);
        } else if (processCounter === -1 && categoryCounter > 0) {
            //when backing past an intro
            //switch to the previous category
            updateGlobalCategoryCounter(globalCategoryCounter - 1);
            //set process to last in array
            updateGlobalProcessCounter(processIndexLength);
        }
 
    }

    const handleNextClick = () => {  
        const categoryIndexLength = categoryLength - 1;
        const processIndexLength = processLength - 1;
        
        // main introduction
        if (showMainIntro) {
            //hide it
            updateShowMainIntro(false)
        }

        if (categoryCounter === categoryIndexLength && processCounter === processIndexLength) {
            //when you finish the last process
            //setOpenSubmit(true);
            //updateGlobalCategoryCounter(globalCategoryCounter - 1);
            return;
        }

        //when there are more processes in the category
        if (processCounter < processIndexLength) {
            updateGlobalProcessCounter(globalProcessCounter + 1);
        } else if (processCounter === processIndexLength) {
            //when you need to switch to the next category
            updateGlobalCategoryCounter(globalCategoryCounter + 1);
            //shows intro page for new category
            updateGlobalProcessCounter(-1);
        }
    }

    //updates redux array 
    const setSelection = level => {
        updateSelection(level, categoryCounter, processCounter);
    }

    //init exit popup
    const handleQuitClick = () => {
        setOpenLeaving(true);
    }

    //show submit alert dialog
    const handleSubmitClick = () => {
        setOpenSubmit(true)
    }

    //close submit alert dialog
    const handleDialogClose = () => {
        setOpenSubmit(false);
    };

    //submit and open report page
    const handleDialogSubmit = async () => {
        //calculate and update score, navigate to reports page
        updateReportContent(adminReports)
        await updateScore();
        //navigate('/assessment/report', { replace: true });
        resetAssessment(true)
       // navigate('/app/mypanel', { replace: true });
    }

    //exit assessment and erase data
    const handleLeavingExit = () => {
        resetAssessment(true)
        navigate('/app/mypanel', { replace: true })
    } 
    //return to asessment
    const handleLeavingReturn = () => {
        setOpenLeaving(false);
    }
    
    //rate assessment and score 
    const updateScore = async () => {
        var finalArr = categories;
        await finalArr.forEach(async category => {
            var rawSum = 0;
            var average = 0;
            var processLength = category.processes.length;
            
            await category.processes.forEach(process => {
                rawSum = rawSum + process.selection;
            })
            
            average = rawSum / processLength;
            category.rawSum = rawSum;
            category.average = average;
            category.processLength = processLength;

        })
        // updateFinalScores(finalArr)
        await createAssessmentHistory(assessmentType, userInfo.id, accessCode, finalArr, assessorData, ownerEmail)

    }
    //display content view
    const MainView = () => {
        if (data) {
            // if(showMainIntro === true) {
            //     //very first page
            //     return (
            //         <Introduction />
            //     )  
            // } else 
            if (processCounter === -1) {
                //display intro
                return ( 
                    <ProcessIntro 
                        header={data[categoryCounter].intro.header} 
                        paragraphs={data[categoryCounter].intro.paragraphs} />
                )
            } else {
                //display processes
                return (
                    <Process 
                        category={data[categoryCounter].category}
                        name={data[categoryCounter].processes[processCounter].processName}
                        description={data[categoryCounter].processes[processCounter].description}
                        maturityLevels={data[categoryCounter].processes[processCounter].maturityLevels}
                        selection={data[categoryCounter].processes[processCounter].selection} 
                        handleSelection={setSelection}
                        categoryIndex={categoryCounter}
                        processIndex={processCounter}
                        assessmentType={assessmentType}
                        />
                )
            }
        } else {
            return (<></>)
        }

    }
    //submit dialog
    const SubmitDialog = () => {
        return (
            <Dialog  open={openSubmit} onClose={handleDialogClose} aria-labelledby="submit-dialog-title"  aria-describedby="submit-dialog-description">                
            <Box px={5} py={2} sx={{ backgroundColor: '#E2F0D9', border:'3px solid green'}}>
                <DialogTitle id="submit-dialog-title" sx={{ display:'flex', justifyContent: 'center',}}>
                    <Typography color="green" gutterBottom variant="h3">Every Process has been rated!</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="submit-dialog-description"  sx={{ display:'flex', flexDirection: 'row' }}  mt={0.5}>
                        <Typography color="textPrimary"  fontSize={18} gutterBottom variant="body1">Last chance to go back and&nbsp;</Typography> 
                        <Typography color="error" fontSize={18} gutterBottom variant="body1"> double-check&nbsp;</Typography> 
                        <Typography color="textPrimary"  fontSize={18} gutterBottom variant="body1">ratings.</Typography> 
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{display:'flex', justifyContent: 'space-evenly'}}>
                    <Button onClick={handleDialogClose} variant="contained" sx={{width: 100,color: "#fff", backgroundColor: '#eb8600', '&:hover': { backgroundColor: '#d77b00'}}} >
                        Return
                    </Button>
                    <Button onClick={handleDialogSubmit} variant="contained" sx={{width: 100,color: "#fff", backgroundColor: '#70309F', '&:hover': { backgroundColor: '#5A267F'}}}  autoFocus>
                        Submit
                    </Button> 
                </DialogActions>
            </Box>
        </Dialog>
        )
    }
    //leaving dialog
    const LeavingDialog = () => {
        return (
            <Dialog open={openLeaving} onClose={handleLeavingReturn} aria-labelledby="leaving-dialog-title"  aria-describedby="leaving-dialog-description">                
                <Box px={5} py={2} sx={{ backgroundColor: '#FBE5D5', border:'3px solid #F9292A'}}>
                    <DialogTitle id="leaving-dialog-title" sx={{ display:'flex', justifyContent: 'center',}}>
                        <Typography gutterBottom variant="h3">Leaving so soon?</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="leaving-dialog-description" mt={1}>
                            <Typography color="error" fontSize={18} gutterBottom variant="body1">Are you sure? Your ratings will <b>NOT</b> be saved.</Typography> 
                            <Typography color="textPrimary" fontSize={18} gutterBottom variant="body1">You will need to re-assess everything again.</Typography> 
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{display:'flex', justifyContent: 'space-evenly'}}>
                        <Button onClick={handleLeavingExit} variant="contained" sx={{width: 100,color: "#fff", backgroundColor: '#eb8600', '&:hover': { backgroundColor: '#d77b00'}}} >
                            Exit
                        </Button>
                        <Button onClick={handleLeavingReturn} variant="contained" sx={{width: 100,color: "#fff", backgroundColor: '#70309F', '&:hover': { backgroundColor: '#5A267F'}}}  autoFocus>
                            Return
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        )
    }

    return (
        <>  
            <Helmet>
                <title>Assessment</title>
            </Helmet>
            <Box className={classes.container} ref={containerRef}>
                {
                    <MainView />
                }
                {/* bottom nav buttons  */}
                <Box className={classes.buttonContainer} mt={6}>
                    <Box className={classes.buttonsLeft} ml={8}>
                        {/* quit btn */}
                        <Box mr={5}>
                            <Button variant="contained" color="error" sx={{width: 100}} 
                                onClick={() => handleQuitClick()}>
                                    Quit
                            </Button>
                        </Box>
                        {/*  submit btn */}
                        <Box mr={2}>
                            <Button variant="contained" sx={{width: 100,color: "#fff", backgroundColor: '#70309F', '&:hover': { backgroundColor: '#5A267F'}}} 
                                onClick={() => handleSubmitClick()}
                                disabled={!didComplete}>
                                    Submit
                            </Button>
                        </Box>
                    </Box>
                    <Box className={classes.buttonsRight} >
                        <Box mr={6}>
                            {/* back btn */}
                                <Button variant="contained" sx={{width: 100,}}
                                    disabled={showMainIntro || (categoryCounter === 0 && processCounter === -1 )}
                                    onClick={() => handleBackClick()}>
                                        Back
                                </Button>
                        </Box>
                        {/* next btn */}
                        <Box mr={2}>
                            <Button variant="contained" color="success" sx={{width: 100, color: "#fff"}} 
                                disabled={categoryCounter === categoryLength - 1 && processCounter === processLength - 1}
                                onClick={() => handleNextClick()}>
                                    Next
                            </Button>
                        </Box>
                    </Box>
                </Box>
                {/* submit popup */}
                <SubmitDialog />
                {/* exit popup */}
                <LeavingDialog />
                <Footer />
            </Box>

        </>
    );

    
}

const useStyles = makeStyles({
    container: {
        backgroundColor: '#DFE7F6', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        alignSelf:'flex-start', 
        overflow: 'scroll',
        paddingTop: 0,
    },
    buttonContainer: {
        display: 'flex', 
        //flexDirection: 'row',
        justifyContent: 'space-between', 
        alignItems:'flex-end', 
        width: '77%', 
        height: '100%', 
        paddingBottom: 50,
        maxWidth: 1000
    },
    buttonsLeft:{
        display: 'flex', 
        flexDirection: 'row', 
    },
    buttonsRight:{
        display: 'flex', 
        flexDirection: 'row', 
        
    }
});

const mapStateToProps = state => ({
    categories: state.takingAssessment.evaluation,
    globalCategoryCounter: state.takingAssessment.categoryCounter,
    globalProcessCounter: state.takingAssessment.processCounter,
    showMainIntro: state.takingAssessment.showMainIntro,
    didComplete: state.takingAssessment.didComplete,
    adminReports: state.adminReports.reports, 
    assessmentType: state.takingAssessment.assessmentType,
    userInfo: state.auth.userInfo,
    accessCode: state.takingAssessment.accessCode,
    assessorData: state.takingAssessment.assessorData,
    ownerEmail: state.takingAssessment.ownerEmail,
})

const mapDispatchToProps = dispatch => ({
    updateSelection: (score, categoryIndex, processIndex) => dispatch(updateSelection(score, categoryIndex, processIndex)),
    updateGlobalCategoryCounter: index => dispatch(updateCategoryCounter(index)),
    updateGlobalProcessCounter: index => dispatch(updateProcessCounter(index)),
    resetAssessment: bool => dispatch(resetAssessment(bool)),
    updateShowMainIntro: bool => dispatch(updateShowMainIntro(bool)),
    updateFinalScores: finalArr => dispatch(updateFinalScores(finalArr)),
    getAdminReports: () => dispatch(getAdminReports()),
    updateReportContent: content => dispatch(updateReportContent(content)),
    ownershipDataSuccess: data => dispatch(ownershipDataSuccess(data)),
    createAssessmentHistory: (assessmentType, userId, accessCode, evaluation, assessorData, ownerEmail, reports) => dispatch(createAssessmentHistory(assessmentType, userId, accessCode, evaluation, assessorData, ownerEmail, reports)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentEvaluation);
