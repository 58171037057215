//get PRESCRIPTION
export const GET_PRESCRIPTIONS_REQUEST = 'GET_PRESCRIPTIONS_REQUEST'
export const getPrescriptionsRequest = () => {
    return {
        type: GET_PRESCRIPTIONS_REQUEST, 
    }
}
export const GET_PRESCRIPTIONS_SUCCESS = 'GET_PRESCRIPTION_SUCCESS'
export const getPrescriptionsSuccess = data => {
    return {
        type: GET_PRESCRIPTIONS_SUCCESS,
        data
    }
}

export const GET_PRESCRIPTIONS_FAILURE = 'GET_PRESCRIPTION_FAILURE'
export const getPrescriptionsFailure = (message) => {
    return {
        type: GET_PRESCRIPTIONS_FAILURE,
        message
    }
}

//update PRESCRIPTION
export const UPDATE_PRESCRIPTION_REQUEST = 'UPDATE_PRESCRIPTION_REQUEST';
export const updatePrescriptionRequest = () => {
    return {
        type: UPDATE_PRESCRIPTION_REQUEST,
    };
}
export const UPDATE_PRESCRIPTION_SUCCESS = 'UPDATE_PRESCRIPTION_SUCCESS';
export const updatePrescriptionSuccess = data => {
    return {
        type: UPDATE_PRESCRIPTION_SUCCESS,
        data
    };
}
export const UPDATE_PRESCRIPTION_FAILURE = 'UPDATE_PRESCRIPTION_FAILURE';
export const updatePrescriptionFailure = (message) => {
    return {
        type: UPDATE_PRESCRIPTION_FAILURE,
        message
    };
}