import * as React from 'react';
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        width: 400
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    }
}))

const VARIABLES = {
    assign: [
        {
            key: 'name',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'owner',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'assessor',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'purchaserEmail',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'date',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'type',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'accessCode',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
    ],
    'assign-undo': [
        {
            key: 'name',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'owner',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'assessor',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'purchaserEmail',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'date',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'type',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'accessCode',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
    ],
    'transfer': [
        {
            key: 'name',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'owner',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'assessor',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'purchaserEmail',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'date',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'type',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'accessCode',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
    ],
    'transfer-undo': [
        {
            key: 'name',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'owner',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'assessor',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'purchaserEmail',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'date',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'type',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'accessCode',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
    ],
    'registration-existing': [
        {
            key: 'name',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'owner',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'assessor',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'purchaserEmail',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'date',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'type',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'accessCode',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
    ],
    'registration-new': [
        {
            key: 'name',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'owner',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'assessor',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'purchaserEmail',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'date',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'type',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
        {
            key: 'accessCode',
            description: 'Description of what the variable could be placed here if we feel it is needed'
        },
    ],

}

const Variables = ({ visible, onClose, currentTemplate }) => {

    if (!currentTemplate?.name) return null

    return (
        <BootstrapDialog
            onClose={onClose}
            aria-labelledby="customized-dialog-title"
            open={visible}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                Available Variables
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 2,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                x
            </IconButton>
            <DialogContent dividers>
                <List dense={true}>
                    {VARIABLES[currentTemplate.id].map(variable => {
                        return (
                            <ListItem key={variable.key}>
                                <ListItemText
                                    primary={`{{ ${variable.key} }}`}
                                    secondary={variable.description}
                                />
                            </ListItem>
                        )
                    })}
                </List>

            </DialogContent>
        </BootstrapDialog>
    )
}

export default Variables