import { db, timestamp } from "../../services/firebase";
import {
    getPrescriptionsFailure,
    getPrescriptionsRequest,
    getPrescriptionsSuccess,
    updatePrescriptionFailure,
    updatePrescriptionRequest,
    updatePrescriptionSuccess
}
    from "../actions/adminPrescriptions";


const DEFAULT = [
    {
        id: 'customerId',
        name: 'Customer Id',
        processNumber: 1,
        category: 'Product',
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'customerValue',
        name: 'Customer Value',
        processNumber: 2,
        category: 'Product',
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'utility',
        name: 'Utility',
        processNumber: 3,
        category: 'Product',
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'functionSolution',
        name: 'Function / Solution',
        processNumber: 4,
        category: 'Product',
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'form',
        name: 'Form',
        category: 'Product',
        processNumber: 5,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'ilities',
        name: '...ilities',
        category: 'Product',
        processNumber: 6,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'performance',
        name: 'Performance',
        category: 'Product',
        processNumber: 7,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'standards',
        name: 'Standards',
        category: 'Product',
        processNumber: 8,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'marketDomain',
        name: 'Market Domain',
        category: 'Market',
        processNumber: 9,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'opportunityDiscovery',
        name: 'Opportunity Discovery',
        category: 'Market',
        processNumber: 10,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'perceptions',
        name: 'Perceptions',
        category: 'Market',
        processNumber: 11,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'maturityStage',
        name: 'Maturity Stage',
        category: 'Market',
        processNumber: 12,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'competitors',
        name: 'Competitors',
        category: 'Market',
        processNumber: 13,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'differentiators',
        name: 'Differentiators',
        category: 'Market',
        processNumber: 14,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'potential',
        name: 'Potential',
        category: 'Market',
        processNumber: 15,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'channels',
        name: 'Channels',
        category: 'Market',
        processNumber: 16,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'decisionMaking',
        name: 'Decision Making',
        category: 'Business',
        processNumber: 17,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'profitModel',
        name: 'Profit Model',
        category: 'Business',
        processNumber: 18,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'organization',
        name: 'Organization',
        category: 'Business',
        processNumber: 19,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'partners',
        name: 'Partners',
        category: 'Business',
        processNumber: 20,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'futureFocus',
        name: 'Future Focus',
        category: 'Business',
        processNumber: 21,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'capitalAndGrowth',
        name: 'Capital and Growth',
        category: 'Business',
        processNumber: 22,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'people',
        name: 'People',
        category: 'Business',
        processNumber: 23,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'metrics',
        name: 'Metrics',
        category: 'Business',
        processNumber: 24,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'innovation',
        name: 'Innovation',
        category: 'Commercialization',
        processNumber: 25,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'fitValidation',
        name: 'Fit Validation',
        category: 'Commercialization',
        processNumber: 26,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'businessAnalysis',
        name: 'Business Analysis',
        category: 'Commercialization',
        processNumber: 27,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'marketTesting',
        name: 'Market Testing',
        category: 'Commercialization',
        processNumber: 28,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'implementation',
        name: 'Implementation',
        category: 'Commercialization',
        processNumber: 29,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'price',
        name: 'Price',
        category: 'Commercialization',
        processNumber: 30,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'sales',
        name: 'Sales',
        category: 'Commercialization',
        processNumber: 31,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    },
    {
        id: 'support',
        name: 'Support',
        category: 'Commercialization',
        processNumber: 32,
        data: [
            {
                name: '0',
                content: '',
            },
            {
                name: '1',
                content: '',
            },
            {
                name: '2',
                content: '',
            },
            {
                name: '3',
                content: '',
            },
            {
                name: '4',
                content: '',
            },
            {
                name: '5',
                content: '',
            }
        ],
    }
]

//get templates
export const getPrescriptions = () => async dispatch => {
    dispatch(getPrescriptionsRequest())

    try {
        //load all reports
        let query = db.collection("prescriptionContent")
        query.onSnapshot(querySnapshot => {
            let prescriptionsList = [];

            querySnapshot.forEach((doc) => {
                if (doc.exists) {
                    const data = doc.data()
                    prescriptionsList.push(data)
                } else {
                    dispatch(getPrescriptionsFailure('Prescriptions not found'))
                }
            });
            //dispatch reports 
            dispatch(getPrescriptionsSuccess(prescriptionsList))
        })

    } catch (error) {
        console.log("Error: ", error.message)
        dispatch(getPrescriptionsFailure(error.message))
    }
}

export const updatePrescription = (updatedData) => async dispatch => {
    dispatch(updatePrescriptionRequest())

    try {
        const promises = updatedData.map(update => {
            const { id, ...data } = update
            const docRef = db.collection("prescriptionContent").doc(id)
            return docRef.update(data) // Return the promise for each update
        });
        await Promise.all(promises) // Wait for all promises to resolve
        dispatch(updatePrescriptionSuccess())
    } catch (error) {
        console.log("Error: ", error.message)
        dispatch(updatePrescriptionFailure(error.message))
    }
}

export const uploadData = () => async dispatch => {
    // Function to upload data to Firestore

    try {
        for (const item of DEFAULT) {
            await db.collection("prescriptionContent").doc(item.id).set(item);
            console.log(`Document with ID ${item.id} uploaded successfully.`);
        }
        console.log("All documents uploaded successfully.");
    } catch (error) {
        console.error("Error uploading documents:", error);
    }
}