
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAssessmentHistory, getAdminReports, getCompletedAssessment } from 'src/redux/thunks'
import { updateReportContent } from 'src/redux/actions'
import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'

import EnhancedTable from 'src/components/shared/EnhancedTable';

const Individual = (props) => {

    //redux variables
    const {
        userInfo,
        history,
        isLoading,
        getList,
        getAdminReports,
        getCompletedAssessment,
        isCreateEvalLoading,
        adminReports,
        updateReportContent
    } = props;

    const navigate = useNavigate();

    const openDownload = async (row) => {
        if (row.accessCode) {
            await getCompletedAssessment(row.accessCode)
            if (!isCreateEvalLoading) {
                navigate('/app/download', { replace: true })
            }
        }
    }

    return (
        <>
            <Box sx={{ minHeight: '100%', py: 0 }} >
                <Box mt={2}>
                    <Typography color="textPrimary" variant="body1" sx={{ marginLeft: 30, fontSize: 20, textAlign: 'center', marginBottom: '.62em' }}>
                        Select the <b>View</b> button of the company you want to view
                    </Typography>
                    <EnhancedTable
                        headCells={headCells}
                        rows={history}
                        isLoading={isLoading}
                        openDownload={openDownload}

                    />
                </Box>
            </Box>
        </>
    );
}


const headCells = [
    { id: 'organizationAssessed', numeric: false, disablePadding: false, label: 'Company Assessed' },
    { id: 'project', numeric: false, disablePadding: false, label: 'Project Name' },
    { id: 'dateAssessed', numeric: true, disablePadding: false, label: 'Assessed Date' },
    { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
    { id: 'code', numeric: false, disablePadding: false, label: 'Code' },
    { id: 'assessorSortName', numeric: false, disablePadding: false, label: 'Assessor' },
    { id: 'owner', numeric: false, disablePadding: false, label: 'Owner' },
    { id: 'reports', numeric: false, disablePadding: false, label: 'View Reports' },
];

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

const mapStateToProps = state => ({
    userInfo: state.auth.userInfo,
    history: state.assessment.assessmentHistory,
    isLoading: state.assessment.isAssessmentLoading,
    isCreateEvalLoading: state.takingAssessment.isCreateEvalLoading,
    reportContent: state.takingAssessment.reports, //fetch report content on open  
    adminReports: state.adminReports.reports,
});

const mapDispatchToProps = dispatch => ({
    getList: email => dispatch(getAssessmentHistory(email)),
    getAdminReports: () => dispatch(getAdminReports()),
    getCompletedAssessment: code => dispatch(getCompletedAssessment(code)),
    updateReportContent: content => dispatch(updateReportContent(content)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Individual);