import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import EnhancedTable from 'src/components/shared/EnhancedTable';
import { exportToCSV } from 'src/libs/csvUtils';
import moment from 'moment';
import { createPrescriptionPDF } from 'src/views/app/ViewReports/generateAgencyPDF';
import { transformData } from './utils';

const Agency = ({ type }) => {
    const userInfo = useSelector((state => state.auth.userInfo))
    const history = useSelector((state) => state.assessment.assessmentHistory || []);
    const isLoading = useSelector((state) => state.assessment.isAssessmentLoading || false);

    const createPrescription = async (items) => {
        try {
            console.log("Perform actions with selected items:", items);

            items.forEach(item => {

                const dynamicContent = {
                    prescriptionTitle: "Process Improvement Prescription",
                    subTitle: "Demonstrate - Solve - Measure",
                    owner: item.owner.email,
                    guidanceText: "This document provides structured recommendations to improve processes. Use this as a guide for implementation.",
                    recommendationText: "Focus on HR and Sales processes identified as the most vulnerable during the assessment.",
                    providerGuidance: "Work closely with the external support organization to implement these recommendations effectively.",
                };

                createPrescriptionPDF(dynamicContent)
            })
            // Perform API call or logic here
        } catch (error) {
            console.error("Error in createPrescription:", error);
        }
    };


    const handleExportToCSV = (selectedItems) => {
        const headers = [
            "Data variables",
            "Unique Pulled",
            "Unique Pulled",
            "Unique Pulled",
        ];

        const transformedData = transformData(selectedItems)

        const transformedForCSV = [];
        transformedData.forEach((input, index) => {
            // Spacer row between objects
            if (index > 0) transformedForCSV.push([]);

            // Prepare metadata
            transformedForCSV.push(["Assessment Date:", moment.unix(input.dateAssessed).format('MM/DD/YYYY')]);
            transformedForCSV.push(["UID Last 4 digits:", `....${input.accessCode.slice(-4)}`]);
            transformedForCSV.push(["Assessment Type:", "VIA-Agency"]);
            transformedForCSV.push(["Vulnerable Quadrant", input.vulnerable]);

            // Calculate averageSection per category
            const categoryAverages = {};
            let mostVulnerableProcess = null;

            Object.keys(input).forEach(key => {
                if (!isNaN(key)) {
                    const { category, averageSection, processesName, selection } = input[key];

                    // Track the first selection that equals 0
                    if (mostVulnerableProcess === null && selection === 0) {
                        mostVulnerableProcess = processesName;
                    }

                    if (!categoryAverages[category]) {
                        categoryAverages[category] = { total: 0, count: 0 };
                    }
                    categoryAverages[category].total += averageSection;
                    categoryAverages[category].count += 1;
                }
            });

            // Find the category with the lowest averageSection
            let lowestCategory = null;
            let lowestAverage = Infinity;
            Object.keys(categoryAverages).forEach(category => {
                const average = categoryAverages[category].total / categoryAverages[category].count;
                if (average < lowestAverage) {
                    lowestAverage = average;
                    lowestCategory = category;
                }
            });

            // Add the lowest category to metadata
            transformedForCSV.push(["Vulnerable Category:", lowestCategory]);

            // Add remaining metadata
            transformedForCSV.push(["Overall rating average:", input.average]);
            transformedForCSV.push(["Most vulnerable process:", mostVulnerableProcess || "None"]);
            transformedForCSV.push([]);

            // Add processes
            let lastCategory = null;
            Object.keys(input).forEach(key => {
                if (!isNaN(key)) {
                    const { category, processesName, selection } = input[key];
                    if (category !== lastCategory) {
                        if (lastCategory) transformedForCSV.push([]);
                        transformedForCSV.push([`${category.toUpperCase()} CATEGORY`, category]);
                        lastCategory = category;
                    }
                    transformedForCSV.push([processesName, selection]);
                }
            });
        });

        exportToCSV(transformedForCSV, headers, `VIA-Business_Agency_DataExport_By_${userInfo.email}.csv`);
    };


    const createTitle = useMemo(() => {
        const action = type === 'prescription' ? 'create prescriptions' : 'add to the export file';
        return <>Select which Assessments to <b>{action}</b></>;
    }, [type]);

    const headCells = useMemo(() => [
        { id: 'organizationAssessed', numeric: false, disablePadding: false, label: 'Company Assessed' },
        { id: 'project', numeric: false, disablePadding: false, label: 'Project Name' },
        { id: 'dateAssessed', numeric: true, disablePadding: false, label: 'Assessed Date' },
        { id: 'type', numeric: false, disablePadding: false, label: 'Type' },
        { id: 'code', numeric: false, disablePadding: false, label: 'Code' },
        { id: 'assessorSortName', numeric: false, disablePadding: false, label: 'Assessor' },
        { id: 'owner', numeric: false, disablePadding: false, label: 'Owner' },
        {
            id: 'include',
            numeric: true,
            disablePadding: false,
            label: type === 'prescription' ? 'Select a Company' : 'Select to Include',
        },
    ], [type]);

    return (
        <Box sx={{ minHeight: '100%' }}>
            <Box mt={2}>
                <Typography
                    color="textPrimary"
                    variant="body1"
                    sx={{ fontSize: 20, textAlign: 'center', ml: 27, mb: 1 }}
                >
                    {createTitle}
                </Typography>
                <EnhancedTable
                    headCells={headCells}
                    rows={history}
                    isLoading={isLoading}
                    openSelected={type === 'prescription' ? createPrescription : handleExportToCSV}
                    checkBox
                    apiButton={type === 'prescription'}
                    toolbarButtonText={type === 'prescription' ? 'Create Prescription' : 'Export to CSV'}
                />
            </Box>
        </Box>
    );
};

export default Agency;
