//get templates
export const GET_TEMPLATES_REQUEST = 'GET_TEMPLATES_REQUEST'
export const getTemplateRequest = () => {
    return {
        type: GET_TEMPLATES_REQUEST, 
    }
}
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS'
export const getTemplatesSuccess = data => {
    return {
        type: GET_TEMPLATES_SUCCESS,
        data
    }
}
export const GET_TEMPLATES_FAILURE = 'GET_TEMPLATES_FAILURE'
export const getTemplatesFailure = (message) => {
    return {
        type: GET_TEMPLATES_FAILURE,
        message
    }
}

//update TEMPLATES
export const UPDATE_TEMPLATE_REQUEST = 'UPDATE_TEMPLATE_REQUEST';
export const updateTemplateRequest = () => {
    return {
        type: UPDATE_TEMPLATE_REQUEST,
    };
}
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const updateTemplateSuccess = data => {
    return {
        type: UPDATE_TEMPLATE_SUCCESS,
        data
    };
}
export const UPDATE_TEMPLATE_FAILURE = 'UPDATE_TEMPLATE_FAILURE';
export const updateTemplateFailure = (message) => {
    return {
        type: UPDATE_TEMPLATE_FAILURE,
        message
    };
}

// Send email
export const SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST'
export const sendEmailRequest = data => {
    return {
        type: SEND_EMAIL_REQUEST,
        data
    }
}

export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS'
export const sendEmailSuccess = data => {
    return {
        type: SEND_EMAIL_SUCCESS,
        data
    }
}

export const SEND_EMAIL_FAILURE = 'SEND_EMAIL_FAILURE'
export const sendEmailFailure = data => {
    return {
        type: SEND_EMAIL_FAILURE,
        data
    }
}