import {
    UPDATE_ACCESS_CODE, UPDATE_ASSESSMENT_ID, UPDATE_TAKING_ASSESSMENT, UPDATE_OWNER, UPDATE_ASSESSMENT_TYPE,
    GET_EVALUATION_REQUEST, GET_EVALUATION_SUCCESS, GET_EVALUATION_FAILURE, UPDATE_REPORT_CONTENT,
    UPDATE_SELECTION, UPDATE_NOTES, UPDATE_TEMP_NOTES,
    UPDATE_CATEGORY_COUNTER, UPDATE_PROCESS_COUNTER,
    UPDATE_DID_SUBMIT, UPDATE_FINAL_SCORES,
    RESET_ASSESSMENT, SHOW_MAIN_INTRO,
    GET_GRAPHIC_REQUEST, GET_GRAPHIC_SUCCESS, GET_GRAPHIC_FAILURE,
    EDIT_REPORT, UPDATE_ASSESSOR,
    CREATE_EVAL_REQUEST, CREATE_EVAL_SUCCESS, CREATE_EVAL_FAILURE,
    GET_EVAL_REQUEST, GET_EVAL_SUCCESS, GET_EVAL_FAILURE,
    UPDATE_INHIBITOR,
    UPDATE_TEMP_INHIBITOR,
} from '../actions';


const takingAssessmentState = {
    accessCode: '',
    assessmentId: '',
    assessmentType: '',
    assessorData: [],
    ownerEmail: '',
    isTakingAssessment: false,
    isEvaluationLoading: false,
    didEvalLoadPass: false,
    evaluation: [],
    evalError: '',
    tempNotes: '',
    tempInhibitor: '',
    categoryCounter: 0,
    processCounter: -1, //-1 used to show intro panels
    didSubmit: false,
    finalEvaluation: [],
    isGraphicLoading: false,
    graphic: '',
    showMainIntro: true,
    didComplete: false,
    hideSidebar: false,
    //report content
    reportContent: {},
    //vsba
    quadSequence: [],
    axisSequence: [],
    quadMessageId: 0,
    quadMessages: [], //can be set up for api
    fullTestoutMsg: [],
    isCreateEvalLoading: false,
    createEvalError: '',
    createEvalSuccess: '',
}

const takingAssessment = (state = takingAssessmentState, action) => {
    switch (action.type) {
        //reset assessment data
        case RESET_ASSESSMENT:
            return {
                ...state,
                accessCode: '',
                assessmentId: '',
                assessmentType: '',
                assessorData: [],
                ownerEmail: '',
                isTakingAssessment: false,
                isEvaluationLoading: false,
                didEvalLoadPass: false,
                evaluation: [],
                evalError: '',
                tempNotes: '',
                tempInhibitor: '',
                categoryCounter: 0,
                processCounter: -1, //-1 used to show intro panels
                didSubmit: false,
                finalEvaluation: [],
                isGraphicLoading: false,
                graphic: '',
                showMainIntro: true,
                didComplete: false,
                hideSidebar: false,
                //report content
                reportContent: {},
                //vsba
                quadSequence: [],
                axisSequence: [],
                quadMessageId: 0,
                quadMessages: [], //can be set up for api
                fullTestoutMsg: [],
                isCreateEvalLoading: false,
                createEvalError: '',
                createEvalSuccess: '',
            }
        //is taking assessment
        case UPDATE_TAKING_ASSESSMENT: {
            return {
                ...state,
                isTakingAssessment: action.isTakingAssessment
            }
        }
        //update assessment id
        case UPDATE_ASSESSMENT_ID: {
            return {
                ...state,
                assessmentId: action.data
            }
        }
        //update assessment type
        case UPDATE_ASSESSMENT_TYPE: {
            return {
                ...state,
                assessmentType: action.data
            }
        }
        //update owner
        case UPDATE_OWNER: {
            return {
                ...state,
                ownerEmail: action.data
            }
        }
        //is taking assessment
        case UPDATE_ACCESS_CODE: {
            return {
                ...state,
                accessCode: action.data
            }
        }
        //get evaluation
        case GET_EVALUATION_REQUEST:
            return {
                ...state,
                isEvaluationLoading: true,
                evalError: '',
                didEvalLoadPass: false
            }
        case GET_EVALUATION_SUCCESS:
            return {
                ...state,
                evaluation: action.data,
                accessCode: action.code,
                isEvaluationLoading: false,
                didEvalLoadPass: true,
                //isTakingAssessment: true,
            }
        case GET_EVALUATION_FAILURE:
            return {
                ...state,
                evalError: action.errMsg,
                isEvaluationLoading: false,
            }
        //update selection
        case UPDATE_SELECTION:
            let selectionArr = [...state.evaluation]
            selectionArr[action.categoryIndex].processes[action.processIndex].selection = action.score;
            //set didComplete true upon completion
            let didFinish = state.didComplete;
            let didFail = false;
            selectionArr.forEach(process => {
                process.processes.forEach(element => {
                    if (element.selection === -1) {
                        didFail = true;
                    }
                })
            }); 
            didFinish = !didFail;
            return {
                ...state,
                evaluation: selectionArr,
                didComplete: didFinish,
            }
        //show/hide main intro page
        case SHOW_MAIN_INTRO:
            return {
                ...state,
                showMainIntro: action.bool
            }

        //update inhibitor
        case UPDATE_INHIBITOR:
            let inhibitorArr = [...state.evaluation]
            inhibitorArr[action.categoryIndex].processes[action.processIndex].inhibitor = action.inhibitor;
            return {
                ...state,
                evaluation: inhibitorArr,
            }
        //update local notes
        case UPDATE_TEMP_INHIBITOR:
            return {
                ...state,
                tempInhibitor: action.inhibitor
            }

        //update notes
        case UPDATE_NOTES:
            let noteArr = [...state.evaluation]
            noteArr[action.categoryIndex].processes[action.processIndex].notes = action.notes;
            return {
                ...state,
                evaluation: noteArr,
            }
        //update local notes
        case UPDATE_TEMP_NOTES:
            return {
                ...state,
                tempNotes: action.notes
            }
        //update category index
        case UPDATE_CATEGORY_COUNTER:
            //set did view to true on category click
            let arr = [...state.evaluation];
            arr[state.categoryCounter].didView = true;
            return {
                ...state,
                categoryCounter: action.index,
                evaluation: arr,
            }
        //update process index
        case UPDATE_PROCESS_COUNTER:
            return {
                ...state,
                processCounter: action.index
            }
        //update did submit bool
        case UPDATE_DID_SUBMIT:
            return {
                ...state,
                didSubmit: action.didSubmit
            }
        //perform final calculations
        case UPDATE_FINAL_SCORES:
            //calculate quadrant sort order
            //let quadData = calcQuadrantSort(action.finalArr)
            let axisData = calcAxisSort(action.finalArr)
            let quadArr = state.reportContent[0].report[42].content
            // console.log('---->', quadArr[axisData[2]-1])
            return {
                ...state,
                finalEvaluation: action.finalArr,
                quadSequence: axisData[1],
                quadMessageId: axisData[2],
                quadMessages: quadArr[axisData[2] - 1],
                axisSequence: axisData[0],
                fullTestoutMsg: testOutArr,
                hideSidebar: true,
                isTakingAssessment: false
            }
        //get graphic
        case GET_GRAPHIC_REQUEST:
            return {
                ...state,
                isGraphicLoading: true,
            }
        case GET_GRAPHIC_SUCCESS:
            return {
                ...state,
                graphic: action.data,
                isGraphicLoading: false,
            }
        case GET_GRAPHIC_FAILURE:
            return {
                ...state,
                evalError: action.errMsg,
                isGraphicLoading: false,
            }
        case EDIT_REPORT:
            return {
                ...state,
                hideSidebar: false,
            }
        case UPDATE_ASSESSOR:
            return {
                ...state,
                assessorData: action.data
            }
        case CREATE_EVAL_REQUEST:
            return {
                ...state,
                isCreateEvalLoading: true,
                createEvalError: '',
                createEvalSuccess: ''
            }
        //save eval
        case CREATE_EVAL_SUCCESS:
            return {
                ...state,
                isCreateEvalLoading: false,
                createEvalSuccess: action.data
            }
        case CREATE_EVAL_FAILURE:
            return {
                ...state,
                isCreateEvalLoading: false,
                createEvalError: action.errMsg,
            }
        case UPDATE_REPORT_CONTENT:
            return {
                ...state,
                reportContent: action.data
            }
        //get taken eval
        case GET_EVAL_REQUEST:
            return {
                ...state,
                isCreateEvalLoading: true,
                createEvalError: '',
                createEvalSuccess: ''
            }
        case GET_EVAL_SUCCESS:
            let axis = calcAxisSort(action.data)
            let quadArrr = state.reportContent[0].report[42].content

            // if(!axis || ! quadArr) {
            //     return { ...state }
            // }

            return {
                ...state,
                isCreateEvalLoading: false,
                finalEvaluation: action.data.evaluation,
                quadSequence: axis[1],
                quadMessageId: axis[2],
                //quadMessages: quad[axisData[2]-1],
                quadMessages: quadArrr[axis[2] - 1],
                axisSequence: axis[0],
                dateAssessed: action.data.dateAssessed,
                assessorData: {
                    assessor: action.data.assessor,
                    company: action.data.company
                }
            }
        case GET_EVAL_FAILURE:
            return {
                ...state,
                isCreateEvalLoading: false,
                createEvalError: action.errMsg,
            }
        default:
            return state;
    }
}

/*
 * helper functions
 */

//gets axis
export const calcAxisSort = (categories) => {

    if (!categories.evaluation) {
        alert('categories.evaluation not found, contact dev')
        return;
    }

    let N = 0;
    let E = 0;
    let S = 0;
    let W = 0;

    let nTxt = 'North';
    let eTxt = 'East';
    let sTxt = 'South';
    let wTxt = 'West';

    let axisOrder = [];
    let quadrantOrder = [];
    let quadMsgId = 0;

    categories.evaluation.forEach(category => {
        //assign axis averages to variables
        if (category.axis == 'North') {
            N = category.rawSum;
        } else if (category.axis == 'East') {
            E = category.rawSum;
        } else if (category.axis == 'South') {
            S = category.rawSum;
        } else if (category.axis == 'West') {
            W = category.rawSum;
        }
    })

    //console.log('N:',N,' E:',E,' S:',S,' W:',W);


    if (S > N && N > W && W > E) {
        // 1
        // console.log('-- 1 -- ')
        axisOrder = [[sTxt, 0], [nTxt, 1], [wTxt, 1], [eTxt, 2]];

        quadrantOrder = ['Q3', 'Q2', 'Q4', 'Q1'];
        quadMsgId = 1;

    } else if (S > W && W > N && N > E) {
        // 2 
        // console.log('-- 2 -- ')
        axisOrder = [[sTxt, 0], [wTxt, 1], [nTxt, 1], [eTxt, 2]];

        quadrantOrder = ['Q3', 'Q2', 'Q4', 'Q1'];
        quadMsgId = 2;

    } else if (W > S && S > N && N > E) {
        // 3 *
        //console.log('-- 3 -- ')
        axisOrder = [[wTxt, 0], [sTxt, 1], [nTxt, 1], [eTxt, 2]];

        quadrantOrder = ['Q3', 'Q2', 'Q4', 'Q1'];
        quadMsgId = 3;

    } else if (S > W && W > E && E > N) {
        // 4
        // console.log('-- 4 -- ')
        axisOrder = [[sTxt, 0], [wTxt, 1], [eTxt, 1], [nTxt, 2]];

        quadrantOrder = ['Q3', 'Q4', 'Q2', 'Q1'];
        quadMsgId = 4;

    } else if (W > S && S > E && E > N) {
        // 5
        // console.log('-- 5 -- ')
        axisOrder = [[wTxt, 0], [sTxt, 1], [eTxt, 1], [nTxt, 2]];

        quadrantOrder = ['Q3', 'Q4', 'Q2', 'Q1'];
        quadMsgId = 5;

    } else if (W > E && E > S && S > N) {
        // 6
        // console.log('-- 6 -- ')
        axisOrder = [[wTxt, 0], [eTxt, 1], [sTxt, 1], [nTxt, 2]];

        quadrantOrder = ['Q3', 'Q4', 'Q2', 'Q1'];
        quadMsgId = 6;

    } else if (S > N && N > E && E > W) {
        // 7
        // console.log('-- 7 -- ')
        axisOrder = [[sTxt, 0], [nTxt, 1], [eTxt, 1], [wTxt, 2]];

        quadrantOrder = ['Q4', 'Q1', 'Q3', 'Q2'];
        quadMsgId = 7;

    } else if (S > E && E > N && N > W) {
        // 8
        // console.log('-- 8 -- ')
        axisOrder = [[sTxt, 0], [eTxt, 1], [nTxt, 1], [wTxt, 2]];

        quadrantOrder = ['Q4', 'Q1', 'Q3', 'Q2'];
        quadMsgId = 8;

    } else if (E > S && S > N && N > W) {
        // 9
        // console.log('-- 9 -- ')
        axisOrder = [[eTxt, 0], [sTxt, 1], [nTxt, 1], [wTxt, 2]];

        quadrantOrder = ['Q4', 'Q1', 'Q3', 'Q2'];
        quadMsgId = 9;

    } else if (S > E && E > W && W > N) {
        // 10
        //console.log('-- 10 -- ')
        axisOrder = [[sTxt, 0], [eTxt, 1], [wTxt, 1], [nTxt, 2]];

        quadrantOrder = ['Q4', 'Q3', 'Q1', 'Q2'];
        quadMsgId = 10;

    } else if (E > S && S > W && W > N) {
        // 11
        // console.log('-- 11 -- ')
        axisOrder = [[eTxt, 0], [sTxt, 1], [wTxt, 1], [nTxt, 2]];

        quadrantOrder = ['Q4', 'Q3', 'Q1', 'Q2'];
        quadMsgId = 11;

    } else if (E > W && W > S && S > N) {
        // 12
        //  console.log('-- 12 -- ')
        axisOrder = [[eTxt, 0], [wTxt, 1], [sTxt, 1], [nTxt, 2]];

        quadrantOrder = ['Q4', 'Q3', 'Q1', 'Q2'];
        quadMsgId = 12;

    } else if (E > N && N > W && W > S) {
        // 13
        //  console.log('-- 13 -- ')
        axisOrder = [[eTxt, 0], [nTxt, 1], [wTxt, 1], [sTxt, 2]];

        quadrantOrder = ['Q1', 'Q2', 'Q4', 'Q3'];
        quadMsgId = 13;

    } else if (E > W && W > N && N > S) {
        // 14
        //  console.log('-- 14 -- ')
        axisOrder = [[eTxt, 0], [wTxt, 1], [nTxt, 1], [sTxt, 2]];

        quadrantOrder = ['Q1', 'Q2', 'Q4', 'Q3'];
        quadMsgId = 14;

    } else if (N > E && E > W && W > S) {
        // 15
        //  console.log('-- 15 -- ')
        axisOrder = [[nTxt, 0], [eTxt, 1], [wTxt, 1], [sTxt, 2]];

        quadrantOrder = ['Q1', 'Q2', 'Q4', 'Q3'];
        quadMsgId = 15;

    } else if (E > N && N > S && S > W) {
        // 16
        //  console.log('-- 16 -- ')
        axisOrder = [[eTxt, 0], [nTxt, 1], [sTxt, 1], [wTxt, 2]];

        quadrantOrder = ['Q1', 'Q4', 'Q2', 'Q3'];
        quadMsgId = 16;

    } else if (N > E && E > S && S > W) {
        // 17
        //  console.log('-- 17 -- ')
        axisOrder = [[nTxt, 0], [eTxt, 1], [sTxt, 1], [wTxt, 2]];

        quadrantOrder = ['Q1', 'Q4', 'Q2', 'Q3'];
        quadMsgId = 17;

    } else if (N > S && S > E && E > W) {
        // 18
        //  console.log('-- 18 -- ')
        axisOrder = [[nTxt, 0], [sTxt, 1], [eTxt, 1], [wTxt, 2]];

        quadrantOrder = ['Q1', 'Q4', 'Q2', 'Q3'];
        quadMsgId = 18;

    } else if (N > W && W > E && E > S) {
        // 19
        //  console.log('-- 19 -- ')
        axisOrder = [[nTxt, 0], [wTxt, 1], [eTxt, 1], [sTxt, 2]];

        quadrantOrder = ['Q2', 'Q1', 'Q3', 'Q4'];
        quadMsgId = 19;

    } else if (W > N && N > E && E > S) {
        // 20
        //  console.log('-- 20 -- ')
        axisOrder = [[wTxt, 0], [nTxt, 1], [eTxt, 1], [sTxt, 2]];

        quadrantOrder = ['Q2', 'Q1', 'Q3', 'Q4'];
        quadMsgId = 20;

    } else if (W > E && E > N && N > S) {
        // 21
        //  console.log('-- 21')
        axisOrder = [[wTxt, 0], [eTxt, 1], [nTxt, 1], [sTxt, 2]];

        quadrantOrder = ['Q2', 'Q1', 'Q3', 'Q4'];
        quadMsgId = 21;

    } else if (N > S && S > W && W > E) {
        // 22
        //  console.log('-- 22')
        axisOrder = [[nTxt, 0], [sTxt, 1], [wTxt, 1], [eTxt, 2]];

        quadrantOrder = ['Q2', 'Q3', 'Q1', 'Q4'];
        quadMsgId = 22;

    } else if (N > W && W > S && S > E) {
        // 23
        //  console.log('-- 23')
        axisOrder = [[nTxt, 0], [wTxt, 1], [sTxt, 1], [eTxt, 2]];

        quadrantOrder = ['Q2', 'Q3', 'Q1', 'Q4'];
        quadMsgId = 23;

    } else if (W > N && N > S && S > E) {
        // 24
        //  console.log('-- 24')
        axisOrder = [[wTxt, 0], [nTxt, 1], [sTxt, 1], [eTxt, 2]];

        quadrantOrder = ['Q2', 'Q3', 'Q1', 'Q4'];
        quadMsgId = 24;

    } else if (N === W && W === S && S === E) {
        // 25
        //  console.log('-- 25')
        axisOrder = [[eTxt, 1], [sTxt, 1], [nTxt, 1], [wTxt, 1]];

        quadrantOrder = ['Q1', 'Q3', 'Q4', 'Q2'];
        quadMsgId = 25;

    } else if (N === S && W === E && S > E) {
        // 26
        //   console.log('-- 26')
        axisOrder = [[sTxt, 0], [nTxt, 0], [eTxt, 2], [wTxt, 2]];

        quadrantOrder = ['Q1', 'Q3', 'Q4', 'Q2'];
        quadMsgId = 26;

    } else if (N === S && W === E && S < E) {
        // 27
        //   console.log('-- 27')
        axisOrder = [[eTxt, 0], [wTxt, 0], [sTxt, 2], [nTxt, 2]];

        quadrantOrder = ['Q1', 'Q3', 'Q4', 'Q2'];
        quadMsgId = 27;

    } else if (E === W && N > S && W < S) {
        // 28
        //  console.log('-- 28')
        axisOrder = [[nTxt, 0], [sTxt, 1], [eTxt, 1], [wTxt, 1]];

        quadrantOrder = ['Q1', 'Q2', 'Q4', 'Q3'];
        quadMsgId = 28;

    } else if (E === W && N > S && W === S) {
        // 29
        //   console.log('-- 29')
        axisOrder = [[nTxt, 0], [eTxt, 1], [sTxt, 1], [wTxt, 1]];

        quadrantOrder = ['Q1', 'Q2', 'Q4', 'Q3'];
        quadMsgId = 29;

    } else if (E === W && N > S && S < W) {
        // 30
        //  console.log('-- 30')
        axisOrder = [[nTxt, 0], [eTxt, 1], [wTxt, 1], [sTxt, 2]];

        quadrantOrder = ['Q1', 'Q2', 'Q4', 'Q3'];
        quadMsgId = 30;

    } else if (N === S && W > E && N < E) {
        // 31
        //   console.log('-- 31')
        axisOrder = [[wTxt, 0], [eTxt, 1], [sTxt, 1], [nTxt, 1]];

        quadrantOrder = ['Q3', 'Q2', 'Q4', 'Q1'];
        quadMsgId = 31;

    } else if (N === S && W > E && N === E) {
        // 32
        //  console.log('-- 32')
        axisOrder = [[wTxt, 0], [eTxt, 1], [sTxt, 1], [nTxt, 1]];

        quadrantOrder = ['Q3', 'Q2', 'Q4', 'Q1'];
        quadMsgId = 32;

    } else if (N === S && W > E && E < N) {
        // 33
        //  console.log('-- 33')
        axisOrder = [[wTxt, 0], [sTxt, 1], [nTxt, 1], [eTxt, 2]];

        quadrantOrder = ['Q3', 'Q2', 'Q4', 'Q1'];
        quadMsgId = 33;

    } else if (E === W && S > N && W < N) {
        //  console.log('-- 34', axisOrder)
        // 34
        axisOrder = [[sTxt, 0], [eTxt, 1], [nTxt, 1], [wTxt, 1]];

        quadrantOrder = ['Q3', 'Q4', 'Q1', 'Q2'];
        quadMsgId = 34;

    } else if (E === W && S > N && W === N) {
        // 35
        //  console.log('-- 35')
        axisOrder = [[sTxt, 0], [eTxt, 1], [nTxt, 1], [wTxt, 1]];

        quadrantOrder = ['Q3', 'Q4', 'Q1', 'Q2'];
        quadMsgId = 35;

    } else if (E === W && S > N && N < W) {
        // 36
        //  console.log('-- 36 -- ')
        axisOrder = [[sTxt, 0], [eTxt, 1], [wTxt, 1], [nTxt, 2]];

        quadrantOrder = ['Q3', 'Q4', 'Q1', 'Q2'];
        quadMsgId = 36;

    } else if (N === S && E > W && W < S) {
        // 37
        // console.log('-- 37')
        axisOrder = [[eTxt, 0], [sTxt, 1], [nTxt, 1], [wTxt, 2]];

        quadrantOrder = ['Q4', 'Q1', 'Q3', 'Q2'];
        quadMsgId = 37;

    } else if (N === S && E > W && W === S) {
        // 38
        //  console.log('-- 38')
        axisOrder = [[eTxt, 0], [sTxt, 1], [nTxt, 1], [wTxt, 1]];

        quadrantOrder = ['Q4', 'Q1', 'Q3', 'Q2'];
        quadMsgId = 38;

    } else if (N === S && E > W && S < W) {
        // 39 
        // console.log('-- 39')
        axisOrder = [[eTxt, 0], [wTxt, 1], [sTxt, 1], [nTxt, 1]];

        quadrantOrder = ['Q4', 'Q1', 'Q3', 'Q2'];
        quadMsgId = 39;

    } else if (N === W && W > S && S === E && E < N) {
        // 40 
        // console.log('-- 40')
        axisOrder = [[nTxt, 0], [wTxt, 0], [eTxt, 2], [sTxt, 2]];

        quadrantOrder = ['Q2', 'Q1', 'Q3', 'Q4'];
        quadMsgId = 40;

    } else if (W === S && S > E && E === N && N < W) {
        // 41 
        // console.log('-- 41')
        axisOrder = [[sTxt, 0], [wTxt, 0], [eTxt, 2], [nTxt, 2]];

        quadrantOrder = ['Q3', 'Q4', 'Q2', 'Q1'];
        quadMsgId = 41;

    } else if (S === E && E > N && N === W && W < S) {
        // 42 
        //  console.log('-- 42')
        axisOrder = [[eTxt, 0], [sTxt, 0], [nTxt, 2], [wTxt, 2]];

        quadrantOrder = ['Q4', 'Q1', 'Q3', 'Q2'];
        quadMsgId = 42;

    } else if (E === N && N > W && W === S && S < E) {
        // 43 
        //   console.log('-- 43')
        axisOrder = [[eTxt, 0], [nTxt, 0], [sTxt, 2], [wTxt, 2]];

        quadrantOrder = ['Q1', 'Q4', 'Q2', 'Q3'];
        quadMsgId = 43;

    } else if (N === W && W > S && S < E && E < N) {
        // 44 
        //   console.log('-- 44')
        axisOrder = [[nTxt, 0], [wTxt, 0], [eTxt, 2], [sTxt, 2]];

        quadrantOrder = ['Q2', 'Q1', 'Q3', 'Q4'];
        quadMsgId = 44;

    } else if (W === S && S > E && E < N && N < W) {
        // 45 
        //  console.log('-- 45')
        axisOrder = [[sTxt, 0], [wTxt, 0], [nTxt, 2], [eTxt, 2]];

        quadrantOrder = ['Q3', 'Q2', 'Q4', 'Q1'];
        quadMsgId = 45;

    } else if (S === E && E > N && N < W && W < S) {
        // 46 
        // console.log('-- 46')
        axisOrder = [[eTxt, 0], [sTxt, 0], [wTxt, 2], [nTxt, 2]];

        quadrantOrder = ['Q4', 'Q3', 'Q1', 'Q2'];
        quadMsgId = 46;

    } else if (E === N && N > W && W < S && S < E) {
        // 47 
        // console.log('-- 47')
        axisOrder = [[eTxt, 0], [nTxt, 0], [sTxt, 2], [wTxt, 2]];

        quadrantOrder = ['Q1', 'Q4', 'Q2', 'Q3'];
        quadMsgId = 47;

    } else if (N === W && W > S && S > E && E < N) {
        // 48 
        // console.log('-- 48')
        axisOrder = [[nTxt, 0], [wTxt, 0], [sTxt, 2], [eTxt, 2]];

        quadrantOrder = ['Q2', 'Q3', 'Q1', 'Q4'];
        quadMsgId = 48;

    } else if (W === S && S > E && E > N && N < W) {
        // 49 
        // console.log('-- 49')
        axisOrder = [[sTxt, 0], [wTxt, 0], [eTxt, 2], [nTxt, 2]];

        quadrantOrder = ['Q3', 'Q4', 'Q2', 'Q1'];
        quadMsgId = 49;

    } else if (S === E && E > N && N > W && W < S) {
        // 50 
        // console.log('--50')
        axisOrder = [[eTxt, 0], [sTxt, 0], [nTxt, 2], [wTxt, 2]];

        quadrantOrder = ['Q4', 'Q1', 'Q3', 'Q2'];
        quadMsgId = 50;

    } else if (E === N && N > W && W > S && S < E) {
        // 51 
        // console.log('--51')
        axisOrder = [[eTxt, 0], [nTxt, 0], [wTxt, 2], [sTxt, 2]];

        quadrantOrder = ['Q1', 'Q2', 'Q4', 'Q3'];
        quadMsgId = 51;

    } else if (N === W && W > S && S < E && E > N) {
        // 52 
        //  console.log('--52')
        axisOrder = [[eTxt, 0], [nTxt, 1], [wTxt, 1], [sTxt, 2]];

        quadrantOrder = ['Q1', 'Q2', 'Q4', 'Q3'];
        quadMsgId = 52;

    } else if (W === S && S > E && E < N && N > W) {
        // 53 
        // console.log('--53')
        axisOrder = [[nTxt, 0], [sTxt, 1], [wTxt, 1], [eTxt, 2]];

        quadrantOrder = ['Q2', 'Q3', 'Q1', 'Q4'];
        quadMsgId = 53;

    } else if (S === E && E > N && N < W && W > S) {
        // 54 
        // console.log('--54')
        axisOrder = [[wTxt, 0], [eTxt, 1], [sTxt, 1], [nTxt, 2]];

        quadrantOrder = ['Q3', 'Q4', 'Q2', 'Q1'];
        quadMsgId = 54;

    } else if (E === N && N > W && W < S && S > E) {
        // 55 
        // console.log('--55')
        axisOrder = [[sTxt, 0], [eTxt, 1], [nTxt, 1], [wTxt, 2]];

        quadrantOrder = ['Q4', 'Q1', 'Q3', 'Q2'];
        quadMsgId = 55;

    } else if (N === W && W < S && S > E && E < N) {
        // 56 
        // console.log('--56')
        axisOrder = [[sTxt, 0], [nTxt, 1], [wTxt, 1], [eTxt, 2]];

        quadrantOrder = ['Q3', 'Q2', 'Q4', 'Q1'];
        quadMsgId = 56;

    } else if (W === S && S < E && E > N && N < W) {
        // 57 
        // console.log('--57')
        axisOrder = [[eTxt, 0], [sTxt, 1], [wTxt, 1], [nTxt, 2]];

        quadrantOrder = ['Q4', 'Q3', 'Q1', 'Q2'];
        quadMsgId = 57;

    } else if (S === E && E < N && N > W && W < S) {
        // 58 
        // console.log('--58')
        axisOrder = [[nTxt, 0], [eTxt, 1], [sTxt, 1], [wTxt, 2]];

        quadrantOrder = ['Q1', 'Q4', 'Q2', 'Q3'];
        quadMsgId = 58;

    } else if (E === N && N < W && W > S && S < E) {
        // 59 
        // console.log('--59')
        axisOrder = [[wTxt, 0], [eTxt, 1], [nTxt, 1], [sTxt, 2]];

        quadrantOrder = ['Q2', 'Q1', 'Q3', 'Q4'];
        quadMsgId = 59;

    } else if (N === W && W < S && S < E && E > N) {
        // 60 
        // console.log('--60')
        axisOrder = [[eTxt, 0], [sTxt, 0], [nTxt, 2], [wTxt, 2]];

        quadrantOrder = ['Q4', 'Q1', 'Q3', 'Q2'];
        quadMsgId = 60;

    } else if (W === S && S < E && E < N && N > W) {
        // 61 
        // console.log('--61')
        axisOrder = [[nTxt, 0], [eTxt, 0], [sTxt, 2], [wTxt, 2]];

        quadrantOrder = ['Q1', 'Q2', 'Q4', 'Q3'];
        quadMsgId = 61;

    } else if (S === E && E < N && N < W && W > S) {
        // 62 
        // console.log('--62')
        axisOrder = [[wTxt, 0], [nTxt, 0], [eTxt, 2], [sTxt, 2]];

        quadrantOrder = ['Q2', 'Q3', 'Q1', 'Q4'];
        quadMsgId = 62;

    } else if (E === N && N < W && W < S && S > E) {
        // 63 
        // console.log('--63')
        axisOrder = [[sTxt, 0], [wTxt, 0], [eTxt, 2], [nTxt, 2]];

        quadrantOrder = ['Q3', 'Q4', 'Q2', 'Q1'];
        quadMsgId = 63;

    } else if (N === W && W < S && S > E && E > N) {
        // 64 
        // console.log('--64')
        axisOrder = [[sTxt, 0], [eTxt, 0], [nTxt, 2], [wTxt, 2]];

        quadrantOrder = ['Q4', 'Q3', 'Q1', 'Q2'];
        quadMsgId = 64;

    } else if (W === S && S < E && E > N && N > W) {
        // 65 
        // console.log('--65')
        axisOrder = [[eTxt, 0], [nTxt, 0], [wTxt, 2], [sTxt, 2]];

        quadrantOrder = ['Q1', 'Q2', 'Q4', 'Q3'];
        quadMsgId = 65;

    } else if (S === E && E < N && N > W && W > S) {
        // 66 
        // console.log('--66')
        axisOrder = [[nTxt, 0], [wTxt, 0], [eTxt, 2], [sTxt, 2]];

        quadrantOrder = ['Q2', 'Q1', 'Q3', 'Q4'];
        quadMsgId = 66;

    } else if (E === N && N < W && W > S && S > E) {
        // 67 
        // console.log('--67')
        axisOrder = [[wTxt, 0], [sTxt, 0], [eTxt, 2], [nTxt, 2]];

        quadrantOrder = ['Q3', 'Q2', 'Q4', 'Q1'];
        quadMsgId = 67;

    } else {
        //test out
        //alert(' Axis Sequence not accounted for - Quad and Axis are fake values \n  N:',N,' E:',E,' S:',S,' W:',W)
        axisOrder = [[wTxt, 0], [sTxt, 0], [eTxt, 2], [nTxt, 2]];

        quadrantOrder = ['Q3', 'Q2', 'Q4', 'Q1'];
        quadMsgId = 68;
    }


    //loop through array and assign raw sum values
    axisOrder.forEach(axis => {
        //inject data based on axis name
        switch (axis[0]) {
            case 'North':
                axis.splice(2, 0, N)
                break;
            case 'East':
                axis.splice(2, 0, E)
                break;
            case 'South':
                axis.splice(2, 0, S)
                break;
            case 'West':
                axis.splice(2, 0, W)
                break;
        }
    })

    // console.log('axisOrder: ', axisOrder);

    //axisOrder array contains [axis, number of reports, rawum]
    return [axisOrder, quadrantOrder, quadMsgId];
}

/*
 * data
 */

const quadMsgArr = [
    {
        //1 - (1-3)
        name: 'Q3Q2-Q1SmMsg',
        pageBreakIndex: 2,
        paragraphs: [
            'You must crawl before you stand, you must stand before you walk, and you must walk before you run. Sometimes the hardest changes are at the beginning and ends of maturity change journeys—when starting at zero or when squeezing out the last tiny increment of positive change.',
            'This gold quadrant, the measure of the “capability to develop an offering” is where problems meet solutions. When this capability is mature you would likely observe some excitement, busy product designers generating ideas and prototypes, discussions about potential future outcomes, active work to research and analyze customers. You might even see a prototype being tested out with a customer, or market testing to reveal other ways the proposed product may solve problems—and the response itself would likely demonstrate innovation, utility, and value, with a clear path for commercialization. This performance integration is about analyzing and conceiving solutions to customer problems AND at the same time creating an offering and validating that offering\'s potential to solve those problems.',
            'However, your assessor\'s ratings indicate this is your least mature ability.',
            'The “capability to develop an offering” comes from brilliant minds of people who generate an idea and then utilize processes to adjust it to what the market wants.',
            'This is different than having a good understanding of the market demographics AND using that information to make money. Rather it is about: Your capability to conceive and refine value-adding ideas and products, AND concurrently designing and analyzing its manifestation effects in the real world. In other words, your organizational entity needs help sparking inspiration, innovation and its subsequent refinement as well as implementing the new idea, prototype or existing product\'s commercialization potential.',
            'Some people simply and intuitively possess a product engineering mindset and know what product / features the customer will love and the potential of it in both the market and business.  But if this does not come naturally to you then you can achieve the same results step-by-step. It starts with knowing your customer. Then creatively designing the product or offering that makes customers want it—all for right price, profit and potential. Your customers will essentially tell you what features and functions they want in your offering. And, if that is too difficult to accomplish solely on your own, you should consider augmenting your internal capabilities by bringing someone with those skills onboard.',
            'A solid product or prototype without the means to develop or change it is like imagining a more cost-effective buggy whip at the time automobiles entered the market. Likewise, having a solid commercialization process without something to change is like Alice in Wonderland not knowing where to go next. Your team or organization can have all the best intentions and yet simply be clueless what solves the problem in the correct way that makes a difference. It means your other, perhaps more mature, abilities to make money in the marketplace will likely be eroded. You may lose track of the market and what they want without developing your capabilities in this less-than-mature areas.',
            'These buggy whip and Alice analogies attempt to describe what can happen when these abilities are completely missing (which is almost never true), but are intended to be your wake-up call to have a balanced approach, and develop capabilities that better respond to your opportunity (and not only focus on your strengths). Simply stated, you likely will not achieve your overall goal unless all the parts of the system move together toward it.',
            'WARNING—You must take action!  The inability to transform ideas and products into a commercialized implication is running blind into the future and likely business failure. If there isn\'t a viable idea/product that customers want for the right price you business will be run over by the competition.'
        ]
    }, {
        //2 - (4-6)
        name: 'Q3Q4-Q1SmMsg',
        pageBreakIndex: 2,
        paragraphs: [
            'You must crawl before you stand, you must stand before you walk, and you must walk before you run. Sometimes the hardest changes are at the beginning and ends of maturity change journeys—when starting at zero or when squeezing out the last tiny increment of positive change.',
            'This gold quadrant, the measure of the “capability to develop an offering” is where problems meet solutions. When this capability is mature you would likely observe some excitement, busy product designers generating ideas and prototypes, discussions about potential future outcomes, active work to research and analyze customers. You might even see a prototype being tested out with a customer, or market testing to reveal other ways the proposed product may solve problems—and the response itself would likely demonstrate innovation, utility, and value, with a clear path for commercialization. This performance integration is about analyzing and conceiving solutions to customer problems AND at the same time creating an offering and validating that offering\'s potential to solve those problems.',
            'However, your assessor\'s ratings indicate this is your least mature ability.',
            'The “capability to develop an offering” comes from brilliant minds of people who generate an idea and then utilize processes to adjust it to what the market wants.',
            'This is different than having a good understanding of the market demographics AND using that information to make money. Rather it is about: Your capability to conceive and refine value-adding ideas and products, AND concurrently designing and analyzing its manifestation effects in the real world. In other words, your organizational entity needs help sparking inspiration, innovation and its subsequent refinement as well as implementing the new idea, prototype or existing product\'s commercialization potential.',
            'Some people simply and intuitively possess a product engineering mindset and know what product / features the customer will love and the potential of it in both the market and business.  But if this does not come naturally to you then you can achieve the same results step-by-step. It starts with knowing your customer. Then creatively designing the product or offering that makes customers want it—all for right price, profit and potential. Your customers will essentially tell you what features and functions they want in your offering. And, if that is too difficult to accomplish solely on your own, you should consider augmenting your internal capabilities by bringing someone with those skills onboard.',
            'A solid product or prototype without the means to develop or change it is like imagining a more cost-effective buggy whip at the time automobiles entered the market. Likewise, having a solid commercialization process without something to change is like Alice in Wonderland not knowing where to go next. Your team or organization can have all the best intentions and yet simply be clueless what solves the problem in the correct way that makes a difference. It means your other, perhaps more mature, abilities to make money in the marketplace will likely be eroded. You may lose track of the market and what they want without developing your capabilities in this less-than-mature areas.',
            'These buggy whip and Alice analogies attempt to describe what can happen when these abilities are completely missing (which is almost never true), but are intended to be your wake-up call to have a balanced approach, and develop capabilities that better respond to your opportunity (and not only focus on your strengths). Simply stated, you likely will not achieve your overall goal unless all the parts of the system move together toward it.',
            'WARNING—You must take action!  The inability to transform ideas and products into a commercialized implication is running blind into the future and likely business failure. If there isn\'t a viable idea/product that customers want for the right price you business will be run over by the competition.'
        ]
    }, {
        //3 - (7-9)
        name: 'Q4Q1-Q2SmMsg',
        pageBreakIndex: 3,
        paragraphs: [
            'You must crawl before you stand, you must stand before you walk, and you must walk before you run. Sometimes the hardest changes are at the beginning and ends of maturity change journeys—when starting at zero or when squeezing out the last tiny increment of positive change.',
            'This blue quadrant, the measure of the “capability to respond to market demand” is where you design, craft and build the desired product(s) or offering WITH a foundation based upon its market domain. When this capability is mature you would likely observe highly active and energetic people solving customer problems, talking and responding to customer feedback, and designing solutions that produce something better than what others offer. This performance integrates the path to a product that works AND validating it with what is wanted.',
            'However, your assessor\'s ratings indicate that this is your least mature ability.',
            'The “capability to respond to market demand” comes from processes to create a good product that solves challenges WITH an understanding of your product\'s / offering\'s fit with market demographics.',
            'This is different than utilizing processes to adjust to, respond, and test what the market wants (commercialization approach) AND using business resources as a base to operate from. Rather it is about: Your capability to see the unmet need in the market AND conceive a product that meets that need. Or in other words your organizational entity needs to understand the market environment your business and your offerings operate within AND design the product or offering to respond to that environment\'s opportunities.',
            'Some people simply and intuitively possess an entrepreneurial mindset and almost always sees market possibilities that others don\'t. But if this does not come naturally to you then you can achieve the same results step-by-step. It starts with creating process discipline, taking time to see the opportunity before you by listening to customers in your market in a new way, and then imagining a solution. And, if that is too difficult to accomplish solely on your own, you should consider augmenting your internal capabilities by bringing someone with those skills onboard.',
            'A solid product or prototype without a known market is like having a thoroughbred racehorse without a track to run on. Likewise, having a solid understanding of the market without something good to sell is like a trained car salesperson on an empty lot. Your team or organization can have all the best intentions and yet simply be clueless what solves the problem in the correct way that makes a difference. It means your other, perhaps more mature, abilities to establish a business model will likely erode without developing your capabilities in these less than mature areas.',
            'These racing and salesperson analogies attempt to describe what can happen when these abilities are completely missing (which is almost never true), but are intended to be your wake-up call to have a balanced approach, and develop capabilities that better respond to your opportunity (and not only focus on your strengths). Simply stated, you likely will not achieve your overall goal unless all the parts of the system move together toward it.',
            'WARNING—You must take action!  The inability to develop what the customer wants is the fast track to business failure.  [This applies to startups as well.]  If there isn\'t a viable path to connect a solution to a need what will you have to offer that anyone wants … then you just wasted time and money on a possible vanity project. Listen to the customer! The alternative is ugly.'
        ]
    }, {
        //4 - (10-12)
        name: 'Q4Q3-Q2SmMsg',
        pageBreakIndex: 3,
        paragraphs: [
            'You must crawl before you stand, you must stand before you walk, and you must walk before you run. Sometimes the hardest changes are at the beginning and ends of maturity change journeys—when starting at zero or when squeezing out the last tiny increment of positive change.',
            'This blue quadrant, the measure of the “capability to respond to market demand” is where you design, craft and build the desired product(s) or offering WITH a foundation based upon its market domain. When this capability is mature you would likely observe highly active and energetic people solving customer problems, talking and responding to customer feedback, and designing solutions that produce something better than what others offer. This performance integrates the path to a product that works AND validating it with what is wanted.',
            'However, your assessor\'s ratings indicate that this is your least mature ability.',
            'The “capability to respond to market demand” comes from processes to create a good product that solves challenges WITH an understanding of your product\'s / offering\'s fit with market demographics.',
            'This is different than utilizing processes to adjust to, respond, and test what the market wants (commercialization approach) AND using business resources as a base to operate from. Rather it is about: Your capability to see the unmet need in the market AND conceive a product that meets that need. Or in other words your organizational entity needs to understand the market environment your business and your offerings operate within AND design the product or offering to respond to that environment\'s opportunities.',
            'Some people simply and intuitively possess an entrepreneurial mindset and almost always sees market possibilities that others don\'t. But if this does not come naturally to you then you can achieve the same results step-by-step. It starts with creating process discipline, taking time to see the opportunity before you by listening to customers in your market in a new way, and then imagining a solution. And, if that is too difficult to accomplish solely on your own, you should consider augmenting your internal capabilities by bringing someone with those skills onboard.',
            'A solid product or prototype without a known market is like having a thoroughbred racehorse without a track to run on. Likewise, having a solid understanding of the market without something good to sell is like a trained car salesperson on an empty lot. Your team or organization can have all the best intentions and yet simply be clueless what solves the problem in the correct way that makes a difference. It means your other, perhaps more mature, abilities to establish a business model will likely erode without developing your capabilities in these less than mature areas.',
            'These racing and salesperson analogies attempt to describe what can happen when these abilities are completely missing (which is almost never true), but are intended to be your wake-up call to have a balanced approach, and develop capabilities that better respond to your opportunity (and not only focus on your strengths). Simply stated, you likely will not achieve your overall goal unless all the parts of the system move together toward it.',
            'WARNING—You must take action!  The inability to develop what the customer wants is the fast track to business failure.  [This applies to startups as well.]  If there isn\'t a viable path to connect a solution to a need what will you have to offer that anyone wants … then you just wasted time and money on a possible vanity project. Listen to the customer! The alternative is ugly.'
        ]
    }, {
        //5 - (13-15)
        name: 'Q1Q2-Q3SmMsg',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the top of your plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the bottom of your plot need more of your attention and improvements.',
            'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner:',
            'Your most mature capabilities are:',
            {
                unordered: [
                    '“Capability to respond to market demand” and',
                    '“Capability to develop an offering”',
                ]
            },
            'Your less-mature capabilities needing your attention and improvement are:',
            {
                unordered: [
                    '“Capability to establish a business model” and',
                    '“Capability to generate revenue”'
                ]
            },
            'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category.  In your case, the assessor rated your internal Business processes as having fewer overall maturities than the other categories.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your internal business process activities (your business organizational infrastructure) that enables profit creation.  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to establish a business model” and the “Capability to generate revenue” at the same time.',
            'Failure to improve this performance vulnerability suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great product with gee whiz features with good sales people to reach customers within a known target market, but none of that will succeed if it isn\'t supported by your business infrastructure.  Not improving could lead to possible business failure if not corrected.',
            'This area is where business management experience (often in the role of your Chief Operating Officer) can make a big difference to the business.  Again, the challenge is to build your business infrastructure: decision-making, planning, organizational design, capital and people management, and more - all to align and support the strengths you have in your other process categories.'
        ]
    }, {
        //6 - (16-18)
        name: 'Q1Q4-Q3SmMsg',
        pageBreakIndex: 3,
        paragraphs: [
            'You must crawl before you stand, you must stand before you walk, and you must walk before you run. Sometimes the hardest changes are at the beginning and ends of maturity change journeys—when starting at zero or when squeezing out the last tiny increment of positive change.',
            'This green quadrant measures your “capability to generate revenue”—where you make money. If this capability is mature you would likely observe energetic activity, customer interactions, supply chain activities, advertising, and delivering solutions for a price. This performance is about integrating your knowledge of the market WITH your business approach to actually deliver value to the customer at a profit.',
            'However, your assessor\'s ratings indicate this is your least mature capability.',
            'The “capability to generate revenue” comes from processes that connect knowledge about your customers WITH your business approach processes.',
            'Don\'t confuse this with having a solid product that solves customer challenges AND having the means to advertise it a larger way (analyzing the effect of the marketing change).',
            'Rather it is about: Your organizational capability to understand the market environment your business and your offerings operate within, while also utilizing your business infrastructure and business processes to deliver value to the customer better than your competitors. In other words, this result says your organizational entity needs help understanding your market implications (who they are, what they want, what they need, when, where, and at what price), then build better business processes and internal organizational processes that deliver solutions your market wants—all in a way that makes a profit.',
            'Some people simply and intuitively apply an operational mindset.  But if this does not come naturally to you then you can achieve the same results step-by-step. It starts with really knowing your markets well by listening to your customers and competition in a deep and disciplined way. This “listening” should guide a disciplined approach to business growth. This can be a challenge. If you have attempted to focus on this effort and failed, perhaps now is the time to consider augmenting your internal abilities by bringing someone onboard who possesses those marketing and/or business process skills.',
            'A solid business without market is like having a race horse without a track to run on. Likewise, having a market without the means to properly respond is like sitting on a river bank without a net watching cash float on by. You can have all the best intentions and simply be incapable of doing something significant to move closer to your desires and goals without developing your abilities in these areas. It means your other more mature abilities to create brilliant products (and analyze the effects of those products in both the market and to your business) may unfortunately grow stale.',
            'These race horse and river analogies attempt to describe what can happen when these abilities are completely missing (which is almost never true), but are intended to be your wake-up call to have a more balanced approach, and develop process abilities that better respond to your opportunity (and reduce focusing on your strengths). Simply stated, you likely will not achieve your overall goal unless all the parts of the system move together toward it.',
            'WARNING—You must take action!  The inability to create cash flow is the fast track to business failure.  [This applies to startups as well.]  If there isn\'t a viable plan to profit, chances are your funding will dry up.  If there isn\'t revenue coming in the door, there is no time to waste!  The alternative is ugly'
        ]
    }, {
        //7 - (19-21)
        name: 'Q2Q1-Q4SmMsg',
        pageBreakIndex: 2,
        paragraphs: [
            'You must crawl before you stand, you must stand before you walk, and you must walk before you run. Sometimes the hardest changes are at the beginning and ends of maturity change journeys—when starting at zero or when squeezing out the last tiny increment of positive change.',
            'This red quadrant, the measure of the “capability to establish a business model” is where your commercialization efforts meet your business\' profit-making infrastructure. When this capability is mature you would likely observe planning and testing, learning about your offering\'s potential (where things change and product ideas grow to a fuller potential) while then also connecting your offering(s) to your business infrastructure to actually deliver value to the customer. This performance is about integrating your know-how to identify innovation, to analyze and understand your offering\'s potential and internal costs to build, WITH your business approach and design to determine if you can actually deliver value to the customer at a profit.',
            'However, your assessor\'s ratings indicate this is your least mature ability.',
            'The “Capability to establish a business model” comes from utilizing processes to adjust to, respond, and test what the market wants (your commercialization approach to do all of that profitably) along with business resources (leaders, plans, partners, funds, people). Don\'t confuse this with having a good product that might solve challenges AND an understanding of your product(s) / offering market demographics. In other words, your organization needs to develop processes that deliver a path to profit.',
            'The classic example is where someone has a “gee whiz” new widget that customers want, but the current design costs too much and there is not a substantial business entity to operate from as a foundation to sell that widget.',
            'Some people simply and intuitively possess a business mindset and know a way to create profit with the right product for your organization and business.  But if this does not come naturally to you then you can achieve the same results step-by-step. Reduce your focus on the product itself and instead focus upon the impact of the product upon your business and market. And, if that is too difficult to accomplish solely on your own, you should consider augmenting your internal capabilities by bringing someone with those skills onboard.',
            'A solid commercialization process without the guidance of business principles and infrastructure is like a sports team without a game to play in. Likewise, having a business (and supporting infrastructure) without something to commercialize is like “all talk” and “no action” Salesman. Your team or organization can have all the best intentions and simply be clueless about purpose /direction and what to work on. It means your other, perhaps more mature, abilities to improve a product for what the market wants will fade as a business flounders and the product becomes stale.',
            'These sports team and empty-handed salesman analogies attempt to describe what can happen when these abilities are completely missing (which is almost never true), but are intended to be your wake-up call to have a balanced approach, and develop capabilities that better respond to your opportunity (and not only focus on your strengths). Simply stated, you likely will not achieve your overall goal unless all the parts of the system move together toward it.',
            'WARNING—You must take action!  The inability to assemble the integration of business infrastructure that supports commercialization is a fast track to business failure.  [This applies to startups as well.]  If there isn\'t the means to chase profit chances are that even with good ideas and a market that wants it you\'ll find other who will pick up the ball you just dropped and take that business away. There is no time to waste.'
        ]
    }, {
        //8 - (22-24)
        name: 'Q2Q3-Q4SmMsg',
        pageBreakIndex: 2,
        paragraphs: [
            'You must crawl before you stand, you must stand before you walk, and you must walk before you run. Sometimes the hardest changes are at the beginning and ends of maturity change journeys—when starting at zero or when squeezing out the last tiny increment of positive change.',
            'This red quadrant, the measure of the “capability to establish a business model” is where your commercialization efforts meet your business\' profit-making infrastructure. When this capability is mature you would likely observe planning and testing, learning about your offering\'s potential (where things change and product ideas grow to a fuller potential) while then also connecting your offering(s) to your business infrastructure to actually deliver value to the customer. This performance is about integrating your know-how to identify innovation, to analyze and understand your offering\'s potential and internal costs to build, WITH your business approach and design to determine if you can actually deliver value to the customer at a profit.',
            'However, your assessor\'s ratings indicate this is your least mature ability.',
            'The “Capability to establish a business model” comes from utilizing processes to adjust to, respond, and test what the market wants (your commercialization approach to do all of that profitably) along with business resources (leaders, plans, partners, funds, people). Don\'t confuse this with having a good product that might solve challenges AND an understanding of your product(s) / offering market demographics. In other words, your organization needs to develop processes that deliver a path to profit.',
            'The classic example is where someone has a “gee whiz” new widget that customers want, but the current design costs too much and there is not a substantial business entity to operate from as a foundation to sell that widget.',
            'Some people simply and intuitively possess a business mindset and know a way to create profit with the right product for your organization and business.  But if this does not come naturally to you then you can achieve the same results step-by-step. Reduce your focus on the product itself and instead focus upon the impact of the product upon your business and market. And, if that is too difficult to accomplish solely on your own, you should consider augmenting your internal capabilities by bringing someone with those skills onboard.',
            'A solid commercialization process without the guidance of business principles and infrastructure is like a sports team without a game to play in. Likewise, having a business (and supporting infrastructure) without something to commercialize is like “all talk” and “no action” Salesman. Your team or organization can have all the best intentions and simply be clueless about purpose /direction and what to work on. It means your other, perhaps more mature, abilities to improve a product for what the market wants will fade as a business flounders and the product becomes stale.',
            'These sports team and empty-handed salesman analogies attempt to describe what can happen when these abilities are completely missing (which is almost never true), but are intended to be your wake-up call to have a balanced approach, and develop capabilities that better respond to your opportunity (and not only focus on your strengths). Simply stated, you likely will not achieve your overall goal unless all the parts of the system move together toward it.',
            'WARNING—You must take action!  The inability to assemble the integration of business infrastructure that supports commercialization is a fast track to business failure.  [This applies to startups as well.]  If there isn\'t the means to chase profit chances are that even with good ideas and a market that wants it you\'ll find other who will pick up the ball you just dropped and take that business away. There is no time to waste.'
        ]
    }, {
        //9 - (25)
        name: 'CapAllEqualsMsg',
        pageBreakIndex: 2,
        paragraphs: [
            'Your process ratings indicate an enviable, but somewhat unusual condition of “Exactly Balanced”—as indicated by the four-colored square centered on your plot.  This means you have balanced capabilities across all four colored quadrants. These are the four necessary business abilities called for in this assessment and the assessor has rated each of them in equal capacity:',
            {
                unordered: [
                    '"Capability to respond to market demand” and',
                    '“Capability to establish a business model” and',
                    '“Capability to generate revenue” and',
                    '“Capability to develop an offering.”'
                ]
            },
            'This is considered the best-shaped result because your balanced capabilities in each process category allow each of them to support development in the others.',
            'If there is a warning, it may come from your zone score.  Unless your results are in the “Premature Zone”, a balanced condition indicates a degree of familiarity with the processes in all four categories: Product, Market, Business, and Commercialization.  There would not be much shown in your process improvement recommendations that would seem like foreign concepts to you or your team. As long as your zone is strongly represented in “Developmental” or in “Foundational”, then you have few “in”-capabilities preventing your movement forward by yourself.',
            'The exception to the overall positive message of an “Exactly Balanced” result may be that your inhibitors (if any) are likely something outside of your own and your business\'s immediate control, for example: the need for funding to grow, developed infrastructure that you can utilize, market conditions, or perhaps strong competition.',
            'The challenge to you is a simple one:  Continue in a balanced manner to further expand your maturity.  Keep up the good work and keep going!'
        ]
    }, {
        //10 - (26)
        name: '2PairVert',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a somewhat rare condition of “Centered and Partially Balanced”—as indicated by the vertical four-colored rectangle centered on your plot.  One could argue the performance of each major capability are the same size, but it is the shape of your plot area that points out certain issues.',
            'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner, which means each of them has a vulnerability, but for two different reasons:',
            {
                unordered: [
                    '“Capability to establish a business model”',
                    '“Capability to develop an offering”',
                    '“Capability to respond to market demand”',
                    '“Capability to generate revenue”',
                ]
            },
            'This means you have process vulnerabilities in two key process categories (each one affecting two capabilities). In your case, the assessor rated your both your external Market and Commercialization development processes as having fewer overall maturities than the other two process categories.  This pattern suggests you may be more comfortable with, have a bias for, internal processes that may be more in your control and influence.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in two process categories.  You and your team should examine and improve your external knowledge of the Market you operate within (value proposition, differentiation, competitors, channels to that market, etc.) AND your Commercialization activities.  Doing so will give you a “double twofer one” for your efforts—you\'ll mature all four major capabilities by addressing Market and Commercialization process categories.',
            'Failure to improve these equal vulnerabilities suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great product with gee whiz features with good sales people to reach customers, and good business processes, but none of that will succeed if it isn\'t focused upon the right market for the right profit.  And this could lead to possible business failure if not corrected or improved.'
        ]
    }, {
        //11 - (27)
        name: '2PairHorz',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a somewhat rare condition of “Centered and Partially Balanced”—as indicated by the horizontal four-colored rectangle centered on your plot.  One could argue the capabilities are the same size, but it is the shape of your plot area that points out certain issues.',
            'There are four necessary business capabilities called for in this assessment and the assessor has rated each of them in the following manner, which means each of them has a vulnerability, but for two different reasons:',
            {
                unordered: [
                    '“Capability to establish a business model”',
                    '“Capability to develop an offering” ',
                    '“Capability to respond to market demand”',
                    '“Capability to generate revenue” ',
                ]
            },
            'This means you have process vulnerabilities in two key process categories (each one affecting two capabilities). In your case, the assessor rated your both your internal Product and Business development processes as having fewer overall maturities than the other two process categories.  This pattern suggests you may be more comfortable with, have a bias for, external processes interacting with the marketplace.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in two process categories.  You and your team should examine and improve your internal knowledge of the Product development efforts AND your Business infrastructure and operational development activities.  Doing so will give you a “double twofer one” for your efforts—you\'ll mature all four major capabilities by addressing Product and Business process categories.',
            'Failure to improve these equal vulnerabilities suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a ready-made market with customer willing to buy, and a process focused upon the delivering the right product to right market for the right profit.  But, having no viable products in the pipeline and poor infrastructure to produce and deliver them could lead to possible business failure - if not corrected or improved.'
        ]
    }, {
        //12 - (28)
        name: 'NPairMsgSLg',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the top of your plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the bottom of your plot need more of your attention and improvements.',
            'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner:',
            'Your most mature capabilities are:',
            {
                unordered: [
                    '“Capability to respond to market demand” and',
                    '“Capability to develop an offering”',
                ]
            },
            'Your less-mature capabilities needing your attention and improvement are:',
            {
                unordered: [
                    '“Capability to establish a business model” and',
                    '“Capability to generate revenue”'
                ]
            },
            'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category.  In your case, the assessor rated your internal Business processes as having fewer overall maturities than the other categories.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your internal business process activities (your business organizational infrastructure) that enables profit creation.  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to establish a business model” and the “Capability to generate revenue” at the same time.',
            'Failure to improve this performance vulnerability suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great product with gee whiz features with good sales people to reach customers within a known target market, but none of that will succeed if it isn\'t supported by your business infrastructure.  Not improving could lead to possible business failure if not corrected.',
            'This area is where business management experience (often in the role of your Chief Operating Officer) can make a big difference to the business.  Again, the challenge is to build your business infrastructure: decision-making, planning, organizational design, capital and people management, and more - all to align and support the strengths you have in your other process categories.'
        ]
    }, {
        //13 - (29)
        name: 'NPairMsgEq',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the top of your plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the bottom of your plot need more of your attention and improvements.',
            'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner:',
            'Your most mature capabilities are:',
            {
                unordered: [
                    '“Capability to respond to market demand” and',
                    '“Capability to develop an offering”',
                ]
            },
            'Your less-mature capabilities needing your attention and improvement are:',
            {
                unordered: [
                    '“Capability to establish a business model” and',
                    '“Capability to generate revenue”'
                ]
            },
            'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category.  In your case, the assessor rated your internal Business processes as having fewer overall maturities than the other categories.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your internal business process activities (your business organizational infrastructure) that enables profit creation.  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to establish a business model” and the “Capability to generate revenue” at the same time.',
            'Failure to improve this performance vulnerability suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great product with gee whiz features with good sales people to reach customers within a known target market, but none of that will succeed if it isn\'t supported by your business infrastructure.  Not improving could lead to possible business failure if not corrected.',
            'This area is where business management experience (often in the role of your Chief Operating Officer) can make a big difference to the business.  Again, the challenge is to build your business infrastructure: decision-making, planning, organizational design, capital and people management, and more - all to align and support the strengths you have in your other process categories.'
        ]
    }, {
        //14 - (30)
        name: 'NPairMsgSSm',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the top of your plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the bottom of your plot need more of your attention and improvements.',
            'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner:',
            'Your most mature capabilities are:',
            {
                unordered: [
                    '“Capability to respond to market demand” and',
                    '“Capability to develop an offering”',
                ]
            },
            'Your less-mature capabilities needing your attention and improvement are:',
            {
                unordered: [
                    '“Capability to establish a business model” and',
                    '“Capability to generate revenue”'
                ]
            },
            'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category.  In your case, the assessor rated your internal Business processes as having fewer overall maturities than the other categories.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your internal business process activities (your business organizational infrastructure) that enables profit creation.  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to establish a business model” and the “Capability to generate revenue” at the same time.',
            'Failure to improve this performance vulnerability suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great product with gee whiz features with good sales people to reach customers within a known target market, but none of that will succeed if it isn\'t supported by your business infrastructure.  Not improving could lead to possible business failure if not corrected.',
            'This area is where business management experience (often in the role of your Chief Operating Officer) can make a big difference to the business.  Again, the challenge is to build your business infrastructure: decision-making, planning, organizational design, capital and people management, and more - all to align and support the strengths you have in your other process categories.'
        ]
    }, {
        //15 - (31)
        name: 'WPairMsgELg',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the left of the plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the right of your plot need more of your attention.',
            'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner:',
            'Your most mature capabilities are:',
            {
                unordered: [
                    '“Capability to respond to market demand” and',
                    '“Capability to generate revenue”',
                ]
            },
            'Your less-mature capabilities needing your attention and improvement are:',
            {
                unordered: [
                    '“Capability to establish a business model” and',
                    '“Capability to develop an offering”',
                ]
            },
            'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category. In your case, the assessor rated your external Commercialization development processes as having fewer overall maturities than the other categories.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your commercialization process activities to capture ideas and develop new product or service offerings that you know customers will love, for the right price.  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to establish a business model” and the “Capability to develop an offering” at the same time.',
            'Failure to improve this performance vulnerability suggests your current-state situation could create future business problems (e.g. cash flow) when it comes time for … well, survival.  You could have a great product with gee whiz features with good business infrastructure, and a known target market, but none of that will succeed if it is becoming “stale” and you have nothing else in the pipeline.  Not improving your development processes and answering the “What\'s next” question in your new product or service pipeline could lead to possible business failure if not corrected.',
            'This area is where market testing and analysis experience can make a big difference.  The challenge is to improve or add to your product offerings that match market demands / desires.  This requires understanding of the external market and customers in a way that help hone the design and implementation of new products, new features, for the right price, and more - all to align and support the strengths you have in your other process categories.',
        ]
    }, {
        //16 - (32)
        name: 'WPairMsgEq',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the left of the plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the right of your plot need more of your attention.',
            'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner:',
            'Your most mature capabilities are:',
            {
                unordered: [
                    '“Capability to respond to market demand” and',
                    '“Capability to generate revenue”',
                ]
            },
            'Your less-mature capabilities needing your attention and improvement are:',
            {
                unordered: [
                    '“Capability to establish a business model” and',
                    '“Capability to develop an offering”',
                ]
            },
            'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category. In your case, the assessor rated your external Commercialization development processes as having fewer overall maturities than the other categories.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your commercialization process activities to capture ideas and develop new product or service offerings that you know customers will love, for the right price.  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to establish a business model” and the “Capability to develop an offering” at the same time.',
            'Failure to improve this performance vulnerability suggests your current-state situation could create future business problems (e.g. cash flow) when it comes time for … well, survival.  You could have a great product with gee whiz features with good business infrastructure, and a known target market, but none of that will succeed if it is becoming “stale” and you have nothing else in the pipeline.  Not improving your development processes and answering the “What\'s next” question in your new product or service pipeline could lead to possible business failure if not corrected.',
            'This area is where market testing and analysis experience can make a big difference.  The challenge is to improve or add to your product offerings that match market demands / desires.  This requires understanding of the external market and customers in a way that help hone the design and implementation of new products, new features, for the right price, and more - all to align and support the strengths you have in your other process categories.',
        ]
    }, {
        //17 - (33)
        name: 'WPairMsgESm',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the left of the plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the right of your plot need more of your attention.',
            'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner:',
            'Your most mature capabilities are:',
            {
                unordered: [
                    '“Capability to respond to market demand” and',
                    '“Capability to generate revenue”',
                ]
            },
            'Your less-mature capabilities needing your attention and improvement are:',
            {
                unordered: [
                    '“Capability to establish a business model” and',
                    '“Capability to develop an offering”',
                ]
            },
            'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category. In your case, the assessor rated your external Commercialization development processes as having fewer overall maturities than the other categories.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your commercialization process activities to capture ideas and develop new product or service offerings that you know customers will love, for the right price.  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to establish a business model” and the “Capability to develop an offering” at the same time.',
            'Failure to improve this performance vulnerability suggests your current-state situation could create future business problems (e.g. cash flow) when it comes time for … well, survival.  You could have a great product with gee whiz features with good business infrastructure, and a known target market, but none of that will succeed if it is becoming “stale” and you have nothing else in the pipeline.  Not improving your development processes and answering the “What\'s next” question in your new product or service pipeline could lead to possible business failure if not corrected.',
            'This area is where market testing and analysis experience can make a big difference.  The challenge is to improve or add to your product offerings that match market demands / desires.  This requires understanding of the external market and customers in a way that help hone the design and implementation of new products, new features, for the right price, and more - all to align and support the strengths you have in your other process categories.',
        ]
    }, {
        //18 - (34)
        name: 'SPairMsgNLg',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the bottom of your plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the top of your plot need more of your attention.',
            'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner.',
            'Your most mature capabilities are:',
            {
                unordered: [
                    '“Capability to establish a business model” and',
                    '“Capability to generate revenue”',
                ]
            },
            'Your less-mature capabilities needing your attention and improvement are:',
            {
                unordered: [
                    '“Capability to respond to market demand” and',
                    '“Capability to develop an offering”',
                ]
            },
            'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category. In your case, the assessor rated your internal Product processes as having fewer overall maturities than the other categories.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your internal Product processes improve your understanding of how your product offerings relate to your customer.  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to respond to market demand” and the “Capability to develop an offering” at the same time.',
            'Failure to improve this possible vulnerability suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great sales people to reach customers, good business processes, and a solid understanding of your market, but none of that will succeed if it isn\'t focused upon improving the relationship between your customer and your product.  Not improving could lead to possible business failure if not corrected.',
            'This area is where research about your individual customers and what they like / don\'t like can make a big difference.  The challenge is to listen to your external relationships (individual customers and suppliers and the market) to learn more about how internally you can: identify customer demographics, define utility, function, value they desire, and develop needed product performance—all to align and support the strengths you have in your other process categories.'
        ]
    }, {
        //19 - (35)
        name: 'SPairMsgEq',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the bottom of your plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the top of your plot need more of your attention.',
            'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner.',
            'Your most mature capabilities are:',
            {
                unordered: [
                    '“Capability to establish a business model” and',
                    '“Capability to generate revenue”',
                ]
            },
            'Your less-mature capabilities needing your attention and improvement are:',
            {
                unordered: [
                    '“Capability to respond to market demand” and',
                    '“Capability to develop an offering”',
                ]
            },
            'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category. In your case, the assessor rated your internal Product processes as having fewer overall maturities than the other categories.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your internal Product processes improve your understanding of how your product offerings relate to your customer.  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to respond to market demand” and the “Capability to develop an offering” at the same time.',
            'Failure to improve this possible vulnerability suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great sales people to reach customers, good business processes, and a solid understanding of your market, but none of that will succeed if it isn\'t focused upon improving the relationship between your customer and your product.  Not improving could lead to possible business failure if not corrected.',
            'This area is where research about your individual customers and what they like / don\'t like can make a big difference.  The challenge is to listen to your external relationships (individual customers and suppliers and the market) to learn more about how internally you can: identify customer demographics, define utility, function, value they desire, and develop needed product performance—all to align and support the strengths you have in your other process categories.'
        ]
    }, {
        //20 - (36)
        name: 'SPairMsgNSm',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the bottom of your plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the top of your plot need more of your attention.',
            'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner.',
            'Your most mature capabilities are:',
            {
                unordered: [
                    '“Capability to establish a business model” and',
                    '“Capability to generate revenue”',
                ]
            },
            'Your less-mature capabilities needing your attention and improvement are:',
            {
                unordered: [
                    '“Capability to respond to market demand” and',
                    '“Capability to develop an offering”',
                ]
            },
            'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category. In your case, the assessor rated your internal Product processes as having fewer overall maturities than the other categories.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your internal Product processes improve your understanding of how your product offerings relate to your customer.  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to respond to market demand” and the “Capability to develop an offering” at the same time.',
            'Failure to improve this possible vulnerability suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great sales people to reach customers, good business processes, and a solid understanding of your market, but none of that will succeed if it isn\'t focused upon improving the relationship between your customer and your product.  Not improving could lead to possible business failure if not corrected.',
            'This area is where research about your individual customers and what they like / don\'t like can make a big difference.  The challenge is to listen to your external relationships (individual customers and suppliers and the market) to learn more about how internally you can: identify customer demographics, define utility, function, value they desire, and develop needed product performance—all to align and support the strengths you have in your other process categories.'
        ]
    }, {
        //21 - (37)
        name: 'EpairMsgWLg',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the right of the plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the left of your plot need more of your attention.',
            'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner.',
            'Your most mature capabilities are:',
            {
                unordered: [
                    '“Capability to establish a business model” and',
                    '“Capability to develop an offering”',
                ]
            },
            'Your less-mature capabilities needing your attention and improvement are:',
            {
                unordered: [
                    '“Capability to respond to market demand” and',
                    '“Capability to generate revenue”',
                ]
            },
            'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category. In your case, the assessor rated your external Market development processes as having fewer overall maturities than the other categories.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your external knowledge of the market you operate within (value proposition, differentiation, competitors, channels to that market, etc.).  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to respond to market demand” and the “Capability to generate revenue” at the same time.',
            'Failure to improve this possible vulnerability suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great product with gee whiz features with good sales people to reach customers, and good business processes, but none of that will succeed if it isn\'t focused upon the right market.  Not improving your Market processes and answering the “What\'s next?” in terms of new ideas and customer needs could lead to possible business failure if not corrected.',
            'This area is where research and promotion experience (Marketing / Sales management) can make a big difference.  The challenge is to build your external relationships to learn more about: your domain, customer perceptions, competitor intelligence, advertising channels that work, and more - all to align and support the strengths you have in your other process categories.'
        ]
    }, {
        //22 - (38)
        name: 'EpairMsgEq',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the right of the plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the left of your plot need more of your attention.',
            'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner.',
            'Your most mature capabilities are:',
            {
                unordered: [
                    '“Capability to establish a business model” and',
                    '“Capability to develop an offering”',
                ]
            },
            'Your less-mature capabilities needing your attention and improvement are:',
            {
                unordered: [
                    '“Capability to respond to market demand” and',
                    '“Capability to generate revenue”',
                ]
            },
            'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category. In your case, the assessor rated your external Market development processes as having fewer overall maturities than the other categories.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your external knowledge of the market you operate within (value proposition, differentiation, competitors, channels to that market, etc.).  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to respond to market demand” and the “Capability to generate revenue” at the same time.',
            'Failure to improve this possible vulnerability suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great product with gee whiz features with good sales people to reach customers, and good business processes, but none of that will succeed if it isn\'t focused upon the right market.  Not improving your Market processes and answering the “What\'s next?” in terms of new ideas and customer needs could lead to possible business failure if not corrected.',
            'This area is where research and promotion experience (Marketing / Sales management) can make a big difference.  The challenge is to build your external relationships to learn more about: your domain, customer perceptions, competitor intelligence, advertising channels that work, and more - all to align and support the strengths you have in your other process categories.'
        ]
    }, {
        //23 - (39)
        name: 'EpairMsgWLSm',
        pageBreakIndex: 3,
        paragraphs: [
            'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the right of the plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the left of your plot need more of your attention.',
            'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner.',
            'Your most mature capabilities are:',
            {
                unordered: [
                    '“Capability to establish a business model” and',
                    '“Capability to develop an offering”',
                ]
            },
            'Your less-mature capabilities needing your attention and improvement are:',
            {
                unordered: [
                    '“Capability to respond to market demand” and',
                    '“Capability to generate revenue”',
                ]
            },
            'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category. In your case, the assessor rated your external Market development processes as having fewer overall maturities than the other categories.',
            'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your external knowledge of the market you operate within (value proposition, differentiation, competitors, channels to that market, etc.).  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to respond to market demand” and the “Capability to generate revenue” at the same time.',
            'Failure to improve this possible vulnerability suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great product with gee whiz features with good sales people to reach customers, and good business processes, but none of that will succeed if it isn\'t focused upon the right market.  Not improving your Market processes and answering the “What\'s next?” in terms of new ideas and customer needs could lead to possible business failure if not corrected.',
            'This area is where research and promotion experience (Marketing / Sales management) can make a big difference.  The challenge is to build your external relationships to learn more about: your domain, customer perceptions, competitor intelligence, advertising channels that work, and more - all to align and support the strengths you have in your other process categories.'
        ]
    }, {
        //24 - (40)
        name: 'NWOrthPair',
        pageBreakIndex: 1,
        paragraphs: [
            'NWOrthPair'
        ]
    }, {
        //25 - (41)
        name: 'SWOrthPair',
        pageBreakIndex: 1,
        paragraphs: [
            'SWOrthPair'
        ]
    }, {
        //26 - (42)
        name: 'SEOrthPair',
        pageBreakIndex: 1,
        paragraphs: [
            'SEOrthPair'
        ]
    }, {
        //27 - (43)
        name: 'NEOrthPair',
        pageBreakIndex: 1,
        paragraphs: [
            'NEOrthPair'
        ]
    }, {
        //28 - (44)
        name: 'NWsqBigH',
        pageBreakIndex: 1,
        paragraphs: [
            'NWsqBigH'
        ]
    }, {
        //29 - (45)
        name: 'SWsqBigV',
        pageBreakIndex: 1,
        paragraphs: [
            'SWsqBigV'
        ]
    }, {
        //30 - (46)
        name: 'SEsqBigH',
        pageBreakIndex: 1,
        paragraphs: [
            'SEsqBigH'
        ]
    }, {
        //31 - (47)
        name: 'NEsqBigV',
        pageBreakIndex: 1,
        paragraphs: [
            'NEsqBigV'
        ]
    }, {
        //32 - (48)
        name: 'NWsqBigV',
        pageBreakIndex: 1,
        paragraphs: [
            'NWsqBigV'
        ]
    }, {
        //33 - (49)
        name: 'SWsqBigH',
        pageBreakIndex: 1,
        paragraphs: [
            'SWsqBigH'
        ]
    }, {
        //34 - (50)
        name: 'SEsqBigV',
        pageBreakIndex: 1,
        paragraphs: [
            'SEsqBigV'
        ]
    }, {
        //35 - (51)
        name: 'NEsqBigH',
        pageBreakIndex: 1,
        paragraphs: [
            'NEsqBigH'
        ]
    }, {
        //36 - (52)
        name: 'NWsqMedH',
        pageBreakIndex: 1,
        paragraphs: [
            'NWsqMedH'
        ]
    }, {
        //37 - (53)
        name: 'SWsqMedV',
        pageBreakIndex: 1,
        paragraphs: [
            'SWsqMedV'
        ]
    }, {
        //38 - (54)
        name: 'SEsqMedH',
        pageBreakIndex: 1,
        paragraphs: [
            'SEsqMedH'
        ]
    }, {
        //39 - (55)
        name: 'NEsqMedV',
        pageBreakIndex: 1,
        paragraphs: [
            'NEsqMedV'
        ]
    }, {
        //40 - (56)
        name: 'NWsqMedV',
        pageBreakIndex: 1,
        paragraphs: [
            'NWsqMedV'
        ]
    }, {
        //41 - (57)
        name: 'SWsqMedH',
        pageBreakIndex: 1,
        paragraphs: [
            'SWsqMedH'
        ]
    }, {
        //42 - (58)
        name: 'SEsqMedV',
        pageBreakIndex: 1,
        paragraphs: [
            'SEsqMedV'
        ]
    }, {
        //43 - (59)
        name: 'NEsqMedH',
        pageBreakIndex: 1,
        paragraphs: [
            'NEsqMedH'
        ]
    }, {
        //44 - (60)
        name: 'NWsqSmH',
        pageBreakIndex: 1,
        paragraphs: [
            'NWsqSmH'
        ]
    }, {
        //45 - (61)
        name: 'SWsqSmV',
        pageBreakIndex: 1,
        paragraphs: [
            'SWsqSmV'
        ]
    }, {
        //46 - (62)
        name: 'SEsqSmH',
        pageBreakIndex: 1,
        paragraphs: [
            'SEsqSmH'
        ]
    }, {
        //47 - (63)
        name: 'NEsqSmV',
        pageBreakIndex: 1,
        paragraphs: [
            'NEsqSmV'
        ]
    }, {
        //48 - (64)
        name: 'NWsqSmV',
        pageBreakIndex: 1,
        paragraphs: [
            'NWsqSmV'
        ]
    }, {
        //49 - (65)
        name: 'SWsqSmH',
        pageBreakIndex: 1,
        paragraphs: [
            'SWsqSmH'
        ]
    }, {
        //50 - (66)
        name: 'SEsqSmV',
        pageBreakIndex: 1,
        paragraphs: [
            'SEsqSmV'
        ]
    }, {
        //51 - (67)
        name: 'NEsqSmH',
        pageBreakIndex: 1,
        paragraphs: [
            'NEsqSmH'
        ]
    },
]

// const quadMsgArr = [
//     {
//         name: '',
//         pageBreakIndex: 3, 
//         paragraphs: [   // 1 pagebreak after 4th item (index 3)
//             'You must crawl before you stand, you must stand before you walk, and you must walk before you run. Sometimes the hardest changes are at the beginning and ends of maturity change journeys—when starting at zero or when squeezing out the last tiny increment of positive change.',
//             'This green quadrant measures your “capability to generate revenue”—where you make money. If this capability is mature you would likely observe energetic activity, customer interactions, supply chain activities, advertising, and delivering solutions for a price. This performance is about integrating your knowledge of the market WITH your business approach to actually deliver value to the customer at a profit.',
//             'However, your assessor\'s ratings indicate this is your least mature capability.', 
//             'The “capability to generate revenue” comes from processes that connect knowledge about your customers WITH your business approach processes.',
//             'Don\'t confuse this with having a solid product that solves customer challenges AND having the means to advertise it a larger way (analyzing the effect of the marketing change).', 
//             'Rather it is about: Your organizational capability to understand the market environment your business and your offerings operate within, while also utilizing your business infrastructure and business processes to deliver value to the customer better than your competitors. In other words, this result says your organizational entity needs help understanding your market implications (who they are, what they want, what they need, when, where, and at what price), then build better business processes and internal organizational processes that deliver solutions your market wants—all in a way that makes a profit.', 
//             'Some people simply and intuitively apply an operational mindset.  But if this does not come naturally to you then you can achieve the same results step-by-step. It starts with really knowing your markets well by listening to your customers and competition in a deep and disciplined way. This “listening” should guide a disciplined approach to business growth. This can be a challenge. If you have attempted to focus on this effort and failed, perhaps now is the time to consider augmenting your internal abilities by bringing someone onboard who possesses those marketing and/or business process skills.',  
//             'A solid business without market is like having a race horse without a track to run on. Likewise, having a market without the means to properly respond is like sitting on a river bank without a net watching cash float on by. You can have all the best intentions and simply be incapable of doing something significant to move closer to your desires and goals without developing your abilities in these areas. It means your other more mature abilities to create brilliant products (and analyze the effects of those products in both the market and to your business) may unfortunately grow stale.',        
//             'These race horse and river analogies attempt to describe what can happen when these abilities are completely missing (which is almost never true), but are intended to be your wake-up call to have a more balanced approach, and develop process abilities that better respond to your opportunity (and reduce focusing on your strengths). Simply stated, you likely will not achieve your overall goal unless all the parts of the system move together toward it.',   
//             'WARNING—You must take action!  The inability to create cash flow is the fast track to business failure.  [This applies to startups as well.]  If there isn\'t a viable plan to profit, chances are your funding will dry up.  If there isn\'t revenue coming in the door, there is no time to waste!  The alternative is ugly'
//         ]
//     },{
//         name: '',
//         pageBreakIndex: 2,
//         paragraphs: [ // 2 pagebreak after 3rd item 
//             'You must crawl before you stand, you must stand before you walk, and you must walk before you run. Sometimes the hardest changes are at the beginning and ends of maturity change journeys—when starting at zero or when squeezing out the last tiny increment of positive change.',
//             'This red quadrant, the measure of the “capability to establish a business model” is where your commercialization efforts meet your business\' profit-making infrastructure. When this capability is mature you would likely observe planning and testing, learning about your offering\'s potential (where things change and product ideas grow to a fuller potential) while then also connecting your offering(s) to your business infrastructure to actually deliver value to the customer. This performance is about integrating your know-how to identify innovation, to analyze and understand your offering\'s potential and internal costs to build, WITH your business approach and design to determine if you can actually deliver value to the customer at a profit.',  
//             'However, your assessor\'s ratings indicate this is your least mature ability.',       
//             'The “Capability to establish a business model” comes from utilizing processes to adjust to, respond, and test what the market wants (your commercialization approach to do all of that profitably) along with business resources (leaders, plans, partners, funds, people). Don\'t confuse this with having a good product that might solve challenges AND an understanding of your product(s) / offering market demographics. In other words, your organization needs to develop processes that deliver a path to profit.',         
//             'The classic example is where someone has a “gee whiz” new widget that customers want, but the current design costs too much and there is not a substantial business entity to operate from as a foundation to sell that widget.',       
//             'Some people simply and intuitively possess a business mindset and know a way to create profit with the right product for your organization and business.  But if this does not come naturally to you then you can achieve the same results step-by-step. Reduce your focus on the product itself and instead focus upon the impact of the product upon your business and market. And, if that is too difficult to accomplish solely on your own, you should consider augmenting your internal capabilities by bringing someone with those skills onboard.',
//             'A solid commercialization process without the guidance of business principles and infrastructure is like a sports team without a game to play in. Likewise, having a business (and supporting infrastructure) without something to commercialize is like “all talk” and “no action” Salesman. Your team or organization can have all the best intentions and simply be clueless about purpose /direction and what to work on. It means your other, perhaps more mature, abilities to improve a product for what the market wants will fade as a business flounders and the product becomes stale.',
//             'These sports team and empty-handed salesman analogies attempt to describe what can happen when these abilities are completely missing (which is almost never true), but are intended to be your wake-up call to have a balanced approach, and develop capabilities that better respond to your opportunity (and not only focus on your strengths). Simply stated, you likely will not achieve your overall goal unless all the parts of the system move together toward it.',
//             'WARNING—You must take action!  The inability to assemble the integration of business infrastructure that supports commercialization is a fast track to business failure.  [This applies to startups as well.]  If there isn\'t the means to chase profit chances are that even with good ideas and a market that wants it you\'ll find other who will pick up the ball you just dropped and take that business away. There is no time to waste.'
//         ]
//     },{
//         name: '',
//         pageBreakIndex: 2,
//         paragraphs: [ // 3 pagebreak after 3rd item
//             'You must crawl before you stand, you must stand before you walk, and you must walk before you run. Sometimes the hardest changes are at the beginning and ends of maturity change journeys—when starting at zero or when squeezing out the last tiny increment of positive change.',
//             'This gold quadrant, the measure of the “capability to develop an offering” is where problems meet solutions. When this capability is mature you would likely observe some excitement, busy product designers generating ideas and prototypes, discussions about potential future outcomes, active work to research and analyze customers. You might even see a prototype being tested out with a customer, or market testing to reveal other ways the proposed product may solve problems—and the response itself would likely demonstrate innovation, utility, and value, with a clear path for commercialization. This performance integration is about analyzing and conceiving solutions to customer problems AND at the same time creating an offering and validating that offering\'s potential to solve those problems.',
//             'However, your assessor\'s ratings indicate this is your least mature ability.',
//             'The “capability to develop an offering” comes from brilliant minds of people who generate an idea and then utilize processes to adjust it to what the market wants.',         
//             'This is different than having a good understanding of the market demographics AND using that information to make money. Rather it is about: Your capability to conceive and refine value-adding ideas and products, AND concurrently designing and analyzing its manifestation effects in the real world. In other words, your organizational entity needs help sparking inspiration, innovation and its subsequent refinement as well as implementing the new idea, prototype or existing product\'s commercialization potential.',       
//             'Some people simply and intuitively possess a product engineering mindset and know what product / features the customer will love and the potential of it in both the market and business.  But if this does not come naturally to you then you can achieve the same results step-by-step. It starts with knowing your customer. Then creatively designing the product or offering that makes customers want it—all for right price, profit and potential. Your customers will essentially tell you what features and functions they want in your offering. And, if that is too difficult to accomplish solely on your own, you should consider augmenting your internal capabilities by bringing someone with those skills onboard.',    
//             'A solid product or prototype without the means to develop or change it is like imagining a more cost-effective buggy whip at the time automobiles entered the market. Likewise, having a solid commercialization process without something to change is like Alice in Wonderland not knowing where to go next. Your team or organization can have all the best intentions and yet simply be clueless what solves the problem in the correct way that makes a difference. It means your other, perhaps more mature, abilities to make money in the marketplace will likely be eroded. You may lose track of the market and what they want without developing your capabilities in this less-than-mature areas.',  
//             'These buggy whip and Alice analogies attempt to describe what can happen when these abilities are completely missing (which is almost never true), but are intended to be your wake-up call to have a balanced approach, and develop capabilities that better respond to your opportunity (and not only focus on your strengths). Simply stated, you likely will not achieve your overall goal unless all the parts of the system move together toward it.', 
//             'WARNING—You must take action!  The inability to transform ideas and products into a commercialized implication is running blind into the future and likely business failure. If there isn\'t a viable idea/product that customers want for the right price you business will be run over by the competition.'
//         ]
//     },{
//         name: '',
//         pageBreakIndex:3,
//         paragraphs: [ // 4
//             'You must crawl before you stand, you must stand before you walk, and you must walk before you run. Sometimes the hardest changes are at the beginning and ends of maturity change journeys—when starting at zero or when squeezing out the last tiny increment of positive change.',
//             'This blue quadrant, the measure of the “capability to respond to market demand” is where you design, craft and build the desired product(s) or offering WITH a foundation based upon its market domain. When this capability is mature you would likely observe highly active and energetic people solving customer problems, talking and responding to customer feedback, and designing solutions that produce something better than what others offer. This performance integrates the path to a product that works AND validating it with what is wanted.',
//             'However, your assessor\'s ratings indicate that this is your least mature ability.',
//             'The “capability to respond to market demand” comes from processes to create a good product that solves challenges WITH an understanding of your product\'s / offering\'s fit with market demographics.', 
//             'This is different than utilizing processes to adjust to, respond, and test what the market wants (commercialization approach) AND using business resources as a base to operate from. Rather it is about: Your capability to see the unmet need in the market AND conceive a product that meets that need. Or in other words your organizational entity needs to understand the market environment your business and your offerings operate within AND design the product or offering to respond to that environment\'s opportunities.',
//             'Some people simply and intuitively possess an entrepreneurial mindset and almost always sees market possibilities that others don\'t. But if this does not come naturally to you then you can achieve the same results step-by-step. It starts with creating process discipline, taking time to see the opportunity before you by listening to customers in your market in a new way, and then imagining a solution. And, if that is too difficult to accomplish solely on your own, you should consider augmenting your internal capabilities by bringing someone with those skills onboard.',
//             'A solid product or prototype without a known market is like having a thoroughbred racehorse without a track to run on. Likewise, having a solid understanding of the market without something good to sell is like a trained car salesperson on an empty lot. Your team or organization can have all the best intentions and yet simply be clueless what solves the problem in the correct way that makes a difference. It means your other, perhaps more mature, abilities to establish a business model will likely erode without developing your capabilities in these less than mature areas.',
//             'These racing and salesperson analogies attempt to describe what can happen when these abilities are completely missing (which is almost never true), but are intended to be your wake-up call to have a balanced approach, and develop capabilities that better respond to your opportunity (and not only focus on your strengths). Simply stated, you likely will not achieve your overall goal unless all the parts of the system move together toward it.',
//             'WARNING—You must take action!  The inability to develop what the customer wants is the fast track to business failure.  [This applies to startups as well.]  If there isn\'t a viable path to connect a solution to a need what will you have to offer that anyone wants … then you just wasted time and money on a possible vanity project. Listen to the customer! The alternative is ugly.'
//         ]
//     },{
//         name: '',
//         pageBreakIndex: 4,
//         paragraphs: [ // 5
//             'What is your most vulnerable Organizational Performance Capability?  Given your ratings for all Categories of factors for business processes have exceeded the design limits of the Very Small Business Assessment the answer to that question is—None!   Congratulations!',  
//             'This is quite an achievement for a very small business to have all these processes fully understood, measured and managed, and producing reliable and predictable results.',
//             'This means the answers to the “Can you do …” questions in the previous section are a resounding, “YES!” You can do all those things!', 
//             'Your ratings indicate both strong and balanced capabilities across all four colored quadrants.',  
//             '1) Capability to respond to market demand, 2) Capability to establish a business model, 3) Capability to generate revenue, and 4) Capability to develop an offering',
//             'Consider what it means to stand on these capabilities, as a foundation, to now reach for higher aspirations your business may hold. Perhaps these four necessary business abilities could serve you in new ways.  Ask yourself:',
//             {unordered: [
//                 '"Where do we steer that capability with our well established and efficiently operating organizational machine\' to our next vision?"',
//                 '“Who next?”  Do we have the leadership skills and will to conceive and build that vision?'
//             ]},
//             'If you\'ve fully answered those two questions with defined “yes” responses then you\'re likely ready to consider two approaches to further maturity development to: “Go Vertical” and then “Go Horizontal.”', 
//             'What follows is only a hint about two more cmmi-like levels of development—Levels 4 and 5.  And both of them required lifting one\'s point of view from what “I” can do to what “we” can do together.  And the “we” quickly grows to include entities outside of your business.',
//             'Level 4 traditionally represents building alignment and integration of all processes across the entire organization (an internal “we”), to building alignment and integration of other organizational entities in your supply chain from your customers, to you, and then your suppliers (an external “we”).', 
//             'To “Go Vertical” doesn\'t mean you have to own the connecting parts of your customers base and your chain of suppliers (as in traditional vertical integration) if you are, in fact, poised to make such meaningful connections with them. Help your direct suppliers improve their quality for your requirements. Be ready to meet-and-exceed your customers\' needs and expectations—sometimes before they know them themselves. Reduce attrition costs of talent by creating an internal workforce of employees and contractors who prefer working with you.', 
//             'Level 5 traditionally represents applying best-in-class processes that become a standard of performance for the industry as you shape the future.',
//             'To “Go Horizontal” doesn\'t mean to take over the world like traditional capitalism might suggest, but instead asks you to recognize your role “in community.”  You are part of a larger system and this level suggests that you can influence the system for the good of all.  Or as the adage goes, “Don\'t fight over the slice of pie, go make the pie bigger.” You don\'t have to physically grow your organization (as in traditional horizontal growth models) if you can expand your connections and networks into your industry, other industries, community, align with educational institutions and governmental agencies, and even develop strategic alliances with competitors. Be a recognized model and share your robust processes through benchmarking.',
//             'Perhaps, your next goal could be to achieve these levels.'
//         ]
//     },{
//         name: '',
//         pageBreakIndex: 2,
//         paragraphs: [ // 6
//             'Your process ratings indicate an enviable, but somewhat unusual condition of “Exactly Balanced”—as indicated by the four-colored square centered on your plot.  This means you have balanced capabilities across all four colored quadrants. These are the four necessary business abilities called for in this assessment and the assessor has rated each of them in equal capacity:', 
//             {unordered: [
//                 '"Capability to respond to market demand” and',
//                 '“Capability to establish a business model” and', 
//                 '“Capability to generate revenue” and', 
//                 '“Capability to develop an offering.”'
//             ]},
//             'This is considered the best-shaped result because your balanced capabilities in each process category allow each of them to support development in the others.',  
//             'If there is a warning, it may come from your zone score.  Unless your results are in the “Premature Zone”, a balanced condition indicates a degree of familiarity with the processes in all four categories: Product, Market, Business, and Commercialization.  There would not be much shown in your process improvement recommendations that would seem like foreign concepts to you or your team. As long as your zone is strongly represented in “Developmental” or in “Foundational”, then you have few “in”-capabilities preventing your movement forward by yourself.',
//             'The exception to the overall positive message of an “Exactly Balanced” result may be that your inhibitors (if any) are likely something outside of your own and your business\'s immediate control, for example: the need for funding to grow, developed infrastructure that you can utilize, market conditions, or perhaps strong competition.',
//             'The challenge to you is a simple one:  Continue in a balanced manner to further expand your maturity.  Keep up the good work and keep going!'
//         ]
//     },{
//         name: '',
//         pageBreakIndex: 3,
//         paragraphs: [ // 7  
//             'Your process ratings indicate a somewhat rare condition of “Centered and Partially Balanced”—as indicated by the vertical four-colored rectangle centered on your plot.  One could argue the performance of each major capability are the same size, but it is the shape of your plot area that points out certain issues.',
//             'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner, which means each of them has a vulnerability, but for two different reasons:',
//             {unordered: [
//                 '“Capability to establish a business model”',
//                 '“Capability to develop an offering”', 
//                 '“Capability to respond to market demand”',
//                 '“Capability to generate revenue”', 
//             ]},
//             'This means you have process vulnerabilities in two key process categories (each one affecting two capabilities). In your case, the assessor rated your both your external Market and Commercialization development processes as having fewer overall maturities than the other two process categories.  This pattern suggests you may be more comfortable with, have a bias for, internal processes that may be more in your control and influence.',
//             'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in two process categories.  You and your team should examine and improve your external knowledge of the Market you operate within (value proposition, differentiation, competitors, channels to that market, etc.) AND your Commercialization activities.  Doing so will give you a “double twofer one” for your efforts—you\'ll mature all four major capabilities by addressing Market and Commercialization process categories.',
//             'Failure to improve these equal vulnerabilities suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great product with gee whiz features with good sales people to reach customers, and good business processes, but none of that will succeed if it isn\'t focused upon the right market for the right profit.  And this could lead to possible business failure if not corrected or improved.'      
//         ]
//     },{
//         name: '',
//         pageBreakIndex: 3,
//         paragraphs: [ // 8
//             'Your process ratings indicate a somewhat rare condition of “Centered and Partially Balanced”—as indicated by the horizontal four-colored rectangle centered on your plot.  One could argue the capabilities are the same size, but it is the shape of your plot area that points out certain issues.',
//             'There are four necessary business capabilities called for in this assessment and the assessor has rated each of them in the following manner, which means each of them has a vulnerability, but for two different reasons:',
//             {unordered: [
//                 '“Capability to establish a business model”',
//                 '“Capability to develop an offering” ',
//                 '“Capability to respond to market demand”',
//                 '“Capability to generate revenue” ',
//             ]},
//             'This means you have process vulnerabilities in two key process categories (each one affecting two capabilities). In your case, the assessor rated your both your internal Product and Business development processes as having fewer overall maturities than the other two process categories.  This pattern suggests you may be more comfortable with, have a bias for, external processes interacting with the marketplace.',
//             'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in two process categories.  You and your team should examine and improve your internal knowledge of the Product development efforts AND your Business infrastructure and operational development activities.  Doing so will give you a “double twofer one” for your efforts—you\'ll mature all four major capabilities by addressing Product and Business process categories.',
//             'Failure to improve these equal vulnerabilities suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a ready-made market with customer willing to buy, and a process focused upon the delivering the right product to right market for the right profit.  But, having no viable products in the pipeline and poor infrastructure to produce and deliver them could lead to possible business failure - if not corrected or improved.'
//         ]
//     },{
//         name: '',
//         pageBreakIndex: 3,
//         paragraphs: [ // 9
//             'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the top of your plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the bottom of your plot need more of your attention and improvements.',  
//             'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner:',
//             'Your most mature capabilities are:',
//             {unordered: [
//                 '“Capability to respond to market demand” and', 
//                 '“Capability to develop an offering”',
//             ]},
//             'Your less-mature capabilities needing your attention and improvement are:', 
//             {unordered: [
//                 '“Capability to establish a business model” and', 
//                 '“Capability to generate revenue”' 
//             ]},   
//             'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category.  In your case, the assessor rated your internal Business processes as having fewer overall maturities than the other categories.',
//             'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your internal business process activities (your business organizational infrastructure) that enables profit creation.  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to establish a business model” and the “Capability to generate revenue” at the same time.',
//             'Failure to improve this performance vulnerability suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great product with gee whiz features with good sales people to reach customers within a known target market, but none of that will succeed if it isn\'t supported by your business infrastructure.  Not improving could lead to possible business failure if not corrected.',
//             'This area is where business management experience (often in the role of your Chief Operating Officer) can make a big difference to the business.  Again, the challenge is to build your business infrastructure: decision-making, planning, organizational design, capital and people management, and more - all to align and support the strengths you have in your other process categories.'
//         ]
//     },{
//         name: '',
//         pageBreakIndex: 3,
//         paragraphs: [ // 10
//             'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the left of the plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the right of your plot need more of your attention.',  
//             'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner:',
//             'Your most mature capabilities are:',
//             {unordered: [
//                 '“Capability to respond to market demand” and', 
//                 '“Capability to generate revenue”', 
//             ]},
//             'Your less-mature capabilities needing your attention and improvement are:', 
//             {unordered: [           
//                 '“Capability to establish a business model” and', 
//                 '“Capability to develop an offering”', 
//             ]},
//             'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category. In your case, the assessor rated your external Commercialization development processes as having fewer overall maturities than the other categories.',
//             'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your commercialization process activities to capture ideas and develop new product or service offerings that you know customers will love, for the right price.  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to establish a business model” and the “Capability to develop an offering” at the same time.',
//             'Failure to improve this performance vulnerability suggests your current-state situation could create future business problems (e.g. cash flow) when it comes time for … well, survival.  You could have a great product with gee whiz features with good business infrastructure, and a known target market, but none of that will succeed if it is becoming “stale” and you have nothing else in the pipeline.  Not improving your development processes and answering the “What\'s next” question in your new product or service pipeline could lead to possible business failure if not corrected.',
//             'This area is where market testing and analysis experience can make a big difference.  The challenge is to improve or add to your product offerings that match market demands / desires.  This requires understanding of the external market and customers in a way that help hone the design and implementation of new products, new features, for the right price, and more - all to align and support the strengths you have in your other process categories.',
//         ]
//     },{
//         name: '',
//         pageBreakIndex: 3,
//         paragraphs: [ // 11
//             'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the bottom of your plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the top of your plot need more of your attention.',  
//             'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner.',            
//             'Your most mature capabilities are:',
//             {unordered: [
//                 '“Capability to establish a business model” and', 
//                 '“Capability to generate revenue”', 
//             ]},
//             'Your less-mature capabilities needing your attention and improvement are:', 
//             {unordered: [
//                 '“Capability to respond to market demand” and', 
//                 '“Capability to develop an offering”', 
//             ]},
//             'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category. In your case, the assessor rated your internal Product processes as having fewer overall maturities than the other categories.',  
//             'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your internal Product processes improve your understanding of how your product offerings relate to your customer.  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to respond to market demand” and the “Capability to develop an offering” at the same time.',            
//             'Failure to improve this possible vulnerability suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great sales people to reach customers, good business processes, and a solid understanding of your market, but none of that will succeed if it isn\'t focused upon improving the relationship between your customer and your product.  Not improving could lead to possible business failure if not corrected.',            
//             'This area is where research about your individual customers and what they like / don\'t like can make a big difference.  The challenge is to listen to your external relationships (individual customers and suppliers and the market) to learn more about how internally you can: identify customer demographics, define utility, function, value they desire, and develop needed product performance—all to align and support the strengths you have in your other process categories.'
//     ]},{
//         name: '',
//         pageBreakIndex: 3,
//         paragraphs: [ // 12
//             'Your process ratings indicate a less frequent condition of “Partially Balanced”—as indicated by the off-centered four-colored rectangle on your plot. The larger pair of equal-area, two-colored performance capabilities, on the right of the plot may be dominating your focus.  The smaller pair of equal-area, two-colored performance capabilities, on the left of your plot need more of your attention.',  
//             'There are four necessary business capabilities called for in this assessment, and your assessor has rated each of them in the following manner.',
//             'Your most mature capabilities are:',
//             {unordered: [
//                 '“Capability to establish a business model” and', 
//                 '“Capability to develop an offering”', 
//             ]},           
//             'Your less-mature capabilities needing your attention and improvement are:', 
//             {unordered: [
//                 '“Capability to respond to market demand” and', 
//                 '“Capability to generate revenue”', 
//             ]},
//             'This means you have vulnerabilities in two key organizational capabilities and individual processes in a process category. In your case, the assessor rated your external Market development processes as having fewer overall maturities than the other categories.',           
//             'The good news and the bad news are one and the same.  Your vulnerabilities and your opportunities to significantly improve are both represented in one process category.  You and your team should examine and improve your external knowledge of the market you operate within (value proposition, differentiation, competitors, channels to that market, etc.).  Doing so will give you a “twofer one” in your effort—you\'ll mature both the “Capability to respond to market demand” and the “Capability to generate revenue” at the same time.',            
//             'Failure to improve this possible vulnerability suggests your current-state situation could create future business performance problems when it comes time for … well, survival.  You could have a great product with gee whiz features with good sales people to reach customers, and good business processes, but none of that will succeed if it isn\'t focused upon the right market.  Not improving your Market processes and answering the “What\'s next?” in terms of new ideas and customer needs could lead to possible business failure if not corrected.',            
//             'This area is where research and promotion experience (Marketing / Sales management) can make a big difference.  The challenge is to build your external relationships to learn more about: your domain, customer perceptions, competitor intelligence, advertising channels that work, and more - all to align and support the strengths you have in your other process categories.'
//         ]
//     },{
//         name: 'NWOrthPair',
//         pageBreakIndex: 1,
//         paragraphs: [ // 13           
//             'NWOrthPair'
//         ]
//     },{
//         name: 'SWOrthPair',
//         pageBreakIndex: 1,
//         paragraphs: [ // 14           
//             'SWOrthPair'
//         ]
//     },{
//         name: 'SEOrthPair',
//         pageBreakIndex: 1,
//         paragraphs: [ // 15           
//             'SEOrthPair'
//         ]
//     },{
//         name: 'NEOrthPair',
//         pageBreakIndex: 1,
//         paragraphs: [ // 16           
//             'NEOrthPair'
//         ]
//     },{
//         name: 'NWsqBigH',
//         pageBreakIndex: 1,
//         paragraphs: [ // 17           
//             'NWsqBigH'
//         ]
//     },{
//         name: 'SWsqBigV',
//         pageBreakIndex: 1,
//         paragraphs: [ // 18           
//             'SWsqBigV'
//         ]
//     },{
//         name: 'SEsqBigH',
//         pageBreakIndex: 1,
//         paragraphs: [ // 19           
//             'SEsqBigH'
//         ]
//     },{
//         name: 'NEsqBigV',
//         pageBreakIndex: 1,
//         paragraphs: [ // 20           
//             'NEsqBigV'
//         ]
//     },{
//         name: 'NWsqBigV',
//         pageBreakIndex: 1,
//         paragraphs: [ // 21           
//             'NWsqBigV'
//         ]
//     },{
//         name: 'SWsqBigH',
//         pageBreakIndex: 1,
//         paragraphs: [ // 22           
//             'SWsqBigH'
//         ]
//     },{
//         name: 'SEsqBigV',
//         pageBreakIndex: 1,
//         paragraphs: [ // 23           
//             'SEsqBigV'
//         ]
//     },{
//         name: 'NEsqBigH',
//         pageBreakIndex: 1,
//         paragraphs: [ // 24           
//             'NEsqBigH'
//         ]
//     },{
//         name: 'NWsqMedH',
//         pageBreakIndex: 1,
//         paragraphs: [ // 25           
//             'NWsqMedH'
//         ]
//     },{
//         name: 'SWsqMedV',
//         pageBreakIndex: 1,
//         paragraphs: [ // 26           
//             'SWsqMedV'
//         ]
//     },{
//         name: 'SEsqMedH',
//         pageBreakIndex: 1,
//         paragraphs: [ // 27           
//             'SEsqMedH'
//         ]
//     },{
//         name: 'NEsqMedV',
//         pageBreakIndex: 1,
//         paragraphs: [ // 28           
//             'NEsqMedV'
//         ]
//     },{
//         name: 'NWsqMedV',
//         pageBreakIndex: 1,
//         paragraphs: [ // 29           
//             'NWsqMedV'
//         ]
//     },{
//         name: 'SWsqMedH',
//         pageBreakIndex: 1,
//         paragraphs: [ // 30           
//             'SWsqMedH'
//         ]
//     },{
//         name: 'SEsqMedV',
//         pageBreakIndex: 1,
//         paragraphs: [ // 31           
//             'SEsqMedV'
//         ]
//     },{
//         name: 'NEsqMedH',
//         pageBreakIndex: 1,
//         paragraphs: [ // 32           
//             'NEsqMedH'
//         ]
//     }
// ]

const testOutArr = [
    'Because you are in the enviable condition of a Level 3 in all four of your critical Process Categories, your Vector Reports Recommendation is as follows:',
    {
        ordered: [
            'Leverage these high-rated processes to assist your overall organizational development efforts.',
            'Now that you have achieved a full “Foundational” performance level for all process Categories (all scores at a “three”) then consider working on higher levels of maturity for your business, or consider contacting a consultant / coach to envision what could come next in your journey.']
    },
    'It is time to “lift your head up and see in a new way.”  Your team is standing on a solid foundation of all four of your critical processes: Product, Market, Business and Commercialization. Ask your team, “What\'s next?”',
    'Here\'s a glimpse into two higher cmmi-like levels of development—Levels 4 and 5.  Both of them require expanding one\'s point of view from what “I” can do as a business to what “we” can do together.  And the “we” quickly grows to include entities outside of your business in “community.”',
    'Level 4 behaviors might include process owners in all Categories beginning to work with external suppliers in new ways to better support your own customers—perhaps building and supporting pull systems throughout the value chain.',
    'Other Level 4 behaviors include every process owner working to support the success of the other process owners in this and the other Categories of processes to transform from a collection of good processes to team-based success standards: ',
    {
        unordered: [
            'For your Product Category, this might mean what you learn from customer interviews and feedback would be provided to the owners of the “…iliites” or “Performance” processes; or informing the process owners of the Market Category processes of “Competitors” or “Differentiators.”',
            'For your Market Category, this might mean what you learn from customer interviews and feedback from the owners of the “Opportunity Discovery” or “Perceptions” processes is provided to “Channels”; or informing the process owners of the Business Category processes of “Decision-making” or “Future Focus.”',
            'For your Business Category, this might mean what you learn from customer interviews and feedback from the owners of the “Partners” or “Future Focus” processes is provided to “Capital and Growth”; or informing the process owners of the Commercialization Category processes of “Market Testing” or “Innovation.”',
            'For your Commercialization Category, this might mean what you learn from customer interviews and feedback from the owners of the “Market Testing” or “Price” processes is provided to “Sales”; or informing the process owners of the Product Category processes of “Customer Value” or “Function / Solution.”']
    },
    'A description for a Level 5 organization might include behaviors such as: ',
    {
        ordered: [
            'Your entire organization is focused on continual improvement. Your company is leading the industry.  As proof of that you are being benchmarked, invited to speak at industry association sessions about where the industry is headed.',
            'Your company is a full member of the community it operates within.  As proof of that you are a good and charitable corporate citizen, and you help build business ecosystems that grow the economic pie.']
    },
    'Vector Reports wishes you well in your continued journey.',
]







export default takingAssessment;