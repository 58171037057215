import React, { useEffect, useState } from 'react'
//redux
import { useSelector, useDispatch } from 'react-redux'
import { getEmailTemplates, updateEmailTemplate } from 'src/redux/thunks'
//ui
import { Helmet } from 'react-helmet-async'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Divider from '@mui/material/Divider'
import TabPanel from './TabPanel'
import Preview from './Preview'
import Variables from './Variables'
import { Eye as EyeIcon, Save as SaveIcon, Info as InfoIcon } from 'react-feather'

const a11yProps = (index) => {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    }
}

const EmailTemplates = () => {

    const dispatch = useDispatch()
    const _templates = useSelector(state => state.emails?.templates)
    const error = useSelector(state => state.emails?.updateTemplateError)

    const [value, setValue] = useState(0)
    const [templates, setTemplates] = useState([])
    const [showPreview, setShowPreview] = useState(false)
    const [showVariables, setShowVariables] = useState(false)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        dispatch(getEmailTemplates())

        return () => {
           setShowPreview(false)
           setShowVariables(false)
          }
    }, [])

    useEffect(() => {
        if (_templates) setTemplates(_templates)

    }, [_templates])

    const onSubjectChange = (value, index) => {
        let newArr = [...templates]
        newArr[index].subject = value
        setTemplates(newArr)

    }

    const onBodyChange = (value, index) => {
        let newArr = [...templates]
        newArr[index].html = value
        setTemplates(newArr)
    }

    const onSave = () => {

        let obj = templates[value]

        const regex = /\{\{(.*?)\}\}/g
        let variables = []

        for (let key in obj) {
            if (typeof obj[key] === 'string') {
                let matches
                while (matches = regex.exec(obj[key])) {
                    variables.push(matches[1])
                }
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                // Recursive call to handle nested objects
                variables = variables.concat(extractVariables(obj[key]))
            }
        }

        dispatch(updateEmailTemplate(obj, variables))
    }

    return (
        <>
            <Helmet>
                <title>Admin | Email Templates</title>
            </Helmet>
            <Box sx={{ minHeight: '100%', py: 3 }} >
                {/* Header */}
                <Container maxWidth='lg' >
                    <Typography color="textPrimary" variant="h2" >
                        Email Templates
                    </Typography>
                    <Typography color="textSecondary" gutterBottom variant="body2" >
                        View and edit templates below
                    </Typography>
                </Container>
                {/* Table */}
                <Box mt={4}>
                    <Container maxWidth='lg'>

                        <Paper sx={{ width: '100%', marginBottom: 2 }}>
                            <Box
                                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%' }}
                            >
                                <Tabs
                                    orientation="vertical"
                                    variant="scrollable"
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="Vertical tabs example"
                                    sx={{ borderRight: 1, borderColor: 'divider' }}
                                >
                                    {templates.map((item, index) => <Tab label={item.name} {...a11yProps(index)} />)}
                                </Tabs>
                                {templates.map((item, index) => (
                                    <TabPanel value={value} index={index} style={{ width: '100%' }}>
                                        <Container>
                                            <Box sx={{ textAlign: 'center', mb: 1, color: 'red' }}>
                                                <Typography> {error} </Typography>
                                            </Box>
                                            <TextField
                                                sx={{ mb: 2, width: '100%' }}
                                                id="outlined-basic"
                                                label="Subject"
                                                variant="outlined"
                                                value={item.subject}
                                                onChange={(event) => {
                                                    onSubjectChange(event.target.value, index)
                                                }}
                                            />
                                            <Divider />
                                            <TextField
                                                sx={{ mt: 2, width: '100%' }}
                                                id="outlined-multiline-static"
                                                label="Body"
                                                multiline
                                                rows={8}
                                                value={item.html}
                                                onChange={(event) => {
                                                    onBodyChange(event.target.value, index)
                                                }}
                                            />
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, mt: 1 }}>
                                                <Button
                                                    variant="contained"
                                                    startIcon={<EyeIcon />}
                                                    onClick={() => setShowPreview(true)}>
                                                    Preview
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    startIcon={<InfoIcon />}
                                                    onClick={() => setShowVariables(true)}>
                                                    Available Variables
                                                </Button>

                                                <Button
                                                    variant="contained"
                                                    startIcon={<SaveIcon />}
                                                    onClick={onSave}>
                                                    Save
                                                </Button>
                                            </Box>
                                        </Container>
                                    </TabPanel>
                                ))}
                            </Box>
                        </Paper>
                    </Container>
                </Box>
            </Box>
            <Preview visible={showPreview} data={templates[value]} onClose={() => setShowPreview(false)} />
            <Variables visible={showVariables} currentTemplate={templates[value]} onClose={() => setShowVariables(false)} />
        </>
    )
}


export default EmailTemplates

