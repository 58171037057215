// src/utils/csvUtils.js
export const exportToCSV = (data, headers, fileName = "export.csv") => {
    if (!data || data.length === 0) {
        console.error("No data to export.");
        return;
    }

    // Format headers and rows
    const csvHeaders = headers.join(",");
    const csvRows = data.map(row =>
        row.map(value => `"${value}"`).join(",")
    );
    const csvContent = [csvHeaders, ...csvRows].join("\n");

    // Create a Blob and download the CSV
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
