import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import BuyButton from '../Stripe/BuyButton'
import Footer from 'src/components/shared/Footer'
import { Divider } from '@mui/material'
import Grid from '@mui/material/Grid';

const BuyAssessment = () => {


    return (
        <>
            <Helmet>
                <title>Vector Reports | Buy</title>
            </Helmet>
            <Box mt={8} mx={10} sx={{ height: '88%' }}>
                <Container maxWidth='lg'>
                    <Box>
                        <Typography color="textPrimary" gutterBottom variant="h3">
                            Pricing Information Page
                        </Typography>
                        <Typography color="textPrimary" variant="body1" gutterBottom sx={{ mt: 5, mb: 5 }}>
                            This choice represents an investment in yourself and your company to either mark a baseline,
                            identify business process vulnerabilities, or measure progress. It can become your own virtual
                            consulting service.
                        </Typography>

                        <Grid container spacing={2}>
                            <Grid item xs={10}>
                                <Typography color="textPrimary" variant="h6" sx={{fontSize: 16}}>
                                    Services
                                </Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={2}>
                                <Typography color="textPrimary" variant="h6" sx={{fontSize: 16}}>
                                    Price
                                </Typography>
                                <Divider />
                            </Grid>

                            <Grid item xs={10} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <img height={20} alt="Logo" src="/static/images/VIA-logo.png" />
                                    <Typography color="textPrimary" variant="body1" gutterBottom sx={{ ml: 3 }}>
                                        … assessment used by business leaders, mentors, consultants, and agencies who are looking to
                                        identify and improve vulnerable business processes.
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                            <Grid item xs={2} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBottom>
                                        $300
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={10} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <img height={20} alt="Logo" src="/static/images/VIA-ITAB.png" />
                                    <Typography color="textPrimary" variant="body1" gutterBottom sx={{ ml: 3 }}>
                                        <span style={{ color: 'red' }}>… Coming soon!</span> An assessment that helps your team increase their likelihood of success when
                                        applying for SBIR / STTR funding opportunities.
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                            <Grid item xs={2} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBottom>
                                        $ n/a
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>

                            <Grid item xs={10} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <img height={20} alt="Logo" src="/static/images/VIA-Readiness.png" />
                                    <Typography color="textPrimary" variant="body1" gutterBottom sx={{ ml: 3 }}>
                                        <span style={{ color: 'red' }}>… Coming soon!</span> An assessment that helps illuminate the way for new entrepreneurs.
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                            <Grid item xs={2} >
                                <Box sx={{ display: 'flex', alignItems: 'center', height: 80 }}>
                                    <Typography color="textPrimary" variant="body1" gutterBottom>
                                        $ n/a
                                    </Typography>
                                </Box>
                                <Divider />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ mt: 5 }} >
                        <Typography color="red" variant="body1" gutterBottom>
                            Note: The email you enter in Stripe will become the account owner and they will be sent the
                            Stripe receipt.
                        </Typography>
                        <Typography color="textPrimary" variant="body1" gutterBottom sx={{ mt: 2 }}>
                            Please know the Owner can transfer ownership on the My Panel page to another Owner as
                            needed.
                        </Typography>
                        <Typography color="textPrimary" variant="body1" gutterBottom sx={{ mt: 2 }}>
                            Please click the button below to be taken our Stripe Payment Processor where you can select
                            the assessments you want to purchase.
                        </Typography>


                        <BuyButton />
                    </Box>
                </Container>
            </Box>
            <Footer />
        </>
    )
}




export default BuyAssessment