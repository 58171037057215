import React, { useEffect, useState } from 'react';
import { connect, useDispatch  } from 'react-redux';
import { getOwnershipData, updateAssessor, createEmail } from 'src/redux/thunks';
import { Helmet } from 'react-helmet-async';
import { Box, Container, Typography, Paper, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, Toolbar, TableSortLabel, Collapse } from '@material-ui/core';

import CustomButton from 'src/components/shared/CustomButton';
import UpdateUserForm from 'src/components/shared/UpdateUserForm';
import TableSpinner from 'src/components/shared/TableSpinner';
import HeaderButtons from 'src/components/shared/HeaderButtons';
import Footer from 'src/components/shared/Footer';

const AssignAssessor = (props) => {
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('desc');
    const [open, setOpen] = useState(false);
    const [assessment, setAssessment] = useState(null);

    //redux variables
    const {
        userInfo,
        ownershipData,
        isUpdatingAssessor,
        didUpdateAssessorFail,
        updateAssessorMessage,
        didAssessorUpdatePass,
        isOwnershipLoading,
        getList,
        assignAssessor,
        sendEmail
    } = props;

    useEffect(() => {
        //get purchase history: on refresh, on nav click
        getHistory();
        //close form
        if (didAssessorUpdatePass) {
            setAssessment(null);
            setOpen(false);
        }
    }, [didAssessorUpdatePass])

    //dispatch assessment history
    const getHistory = () => {
        getList(userInfo.email);   
    }

    const handleRequestSort = (property) => {
        setOrderBy(property);
        setOrder(order === 'asc' ? 'desc' : 'asc');
    };

    const handleCustomClick = (row, buttonTitle) => {
        if(buttonTitle == 'Cancel') {
            //reset local state after Cancel is pressed 
            setAssessment(null);
        } else if(buttonTitle === 'Undo') { 
            assignAssessor(row.accessCode, '')
            sendEmail('assign-undo', userInfo.email, {
                owner: row.owner.email,
                accessCode: row.accessCode,
                name: row.owner.name,
                purchaserEmail: row.purchaserEmail,
                type: row.type,
                date: row.date.seconds,
                accessor: row.assessor.email
            } )
        } else {
            setAssessment(row);
        }
        setOpen(!open);
    }

    const handleAssessorSubmit = (row, info) => {
        assignAssessor(row.accessCode, info);

        sendEmail('assign', info.email, {
            owner: row.owner.email,
            accessCode: row.accessCode,
            firstName: info.firstName,
            lastName: info.lastName,
            purchaserEmail: row.purchaserEmail,
            type: row.type,
            date: row.date.seconds,
            accessor: row.assessor.email
        } )
    }

    return (
        <>
            <Helmet>
                <title>Vector Reports | Assign</title>
            </Helmet>
            <Box my={8} mx={4} sx={{ minHeight: '100%', py: 3 }} >
                {/* Header */}
                <Container maxWidth='lg' >
                    <Typography color="textPrimary" variant="h2" style={{ fontWeight: 600 }}>
                       Assign Assessor
                    </Typography>
                   <HeaderButtons />
                    
                </Container>
                {/* Table */}
                <Box mt={2}>
                    <Container maxWidth='lg'>
                        <Paper sx={{ width: '100%', marginBottom:2 }}>
                            <Toolbar>
                                <Typography variant="h4" id="assignLabel"  color="textSecondary" > 
                                You may Assign or change who may Take an assessment
                                </Typography>
                            </Toolbar>
                            <TableContainer sx={{pl:1.2}}>
                                <Table aria-label="assignTable" size={'medium'}>
                                    {/* Table Header - Sort */}
                                    <TableHead>
                                        <TableRow key="assign-header">
                                            {   
                                                headCells.map((headCell) =>{  
                                                    return (
                                                        <TableCell key={headCell.id} sortDirection={orderBy === headCell.id ? order : false}>
                                                            <TableSortLabel 
                                                                active={orderBy === headCell.id}
                                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                                onClick={() => handleRequestSort(headCell.id)}
                                                            >
                                                                { headCell.label }
                                                            </TableSortLabel>
                                                        </TableCell>
                                                )})
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {/* Table Body */}
                                    <TableBody>
                                        { ownershipData && ownershipData.length > 0 
                                            ? (
                                                stableSort(ownershipData, getComparator(order, orderBy))
                                                .map((row, index) => {
                                                    const isAssigned = row.assessor.email ? true : false
                                                    const isOwner =  row?.owner?.email === userInfo.email
                                                    const ownerIsPurchaser = row.owner.email == row.purchaserEmail;
                                                    const key = `assign-assessor-${index}`;
                                                    const key2 = `assign-assessor-${index}-cllps`;
                                                    const key3 = `assign-assessor-${index}-frag`;
                                                    const assessor = isAssigned ? row.assessor?.email : 'None';
                                                    const assessorName = row.assessor?.name ? row.assessor?.name : 'None';
                                                    // const statusColor = row.status == 'Available' ? '#4caf50' : ''
                                                    const statusColor = isAssigned ? 'red' : '#4caf50'
                                                    const statusWeight = row.status == 'Available' ? '600' : ''
                                                    let buttonTitle = !isAssigned ? "Select" : "Undo"
                                                    buttonTitle = (row.displayCode != assessment?.displayCode) ? buttonTitle : "Cancel";
                                                    let buttonColor = !isAssigned ? "primary" : "#ff9100";
                                                    let buttonHover = !isAssigned ? "" : "#b26a00" ;
                                                    //make red if cancel
                                                    buttonColor = (row.displayCode != assessment?.displayCode) ? buttonColor : "#f44336";
                                                    buttonHover = (row.displayCode != assessment?.displayCode) ? buttonHover : "#ba000d";
                                                    // console.log('row : ', row)
                                                    const isPromoCode = row.purchaserEmail == 'adminPurch@vectorreports.com'
                                                    if (
                                                        isOwner
                                                        && (//if row is the one that is opened or if none are opened
                                                            (assessment && row.displayCode == assessment?.displayCode) 
                                                            || !assessment
                                                        ) 
                                                        && !row.isCompleted
                                                    
                                                    ) {
                                                        return ( 
                                                            <React.Fragment key={key3}>
                                                                <TableRow key={key}>
                                                                    <TableCell sx={{ color:statusColor }}>
                                                                        {ownerIsPurchaser ? 'Purchase' : 'Transfer In'}
                                                                    </TableCell>
                                                                    <TableCell sx={{color:statusColor}}>
                                                                    {isPromoCode && !row?.previousOwner ? "VR Promo" : ownerIsPurchaser ? row?.owner?.email : row?.previousOwner}
                                                                    </TableCell>
                                                                    <TableCell sx={{color:statusColor}}>{ assessorName }</TableCell>
                                                                    <TableCell sx={{color:statusColor}}>{assessor}</TableCell>
                                                                    <TableCell sx={{color:statusColor}}>{row.type === 'VSBA' ? 'VIA·Biz' : row.type}</TableCell>
                                                                    <TableCell sx={{color:statusColor}}>{row.displayCode}</TableCell>
                                                                    <TableCell>
                                                                        <CustomButton 
                                                                            buttonTitle={buttonTitle}
                                                                            backgroundColor= { buttonColor }
                                                                            hoverColor= {buttonHover}
                                                                            handleClick={() => handleCustomClick(row, buttonTitle)}
                                                                            disabled={open && row.displayCode != assessment?.displayCode} 
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                                {row.displayCode == assessment?.displayCode && (
                                                                    <TableRow key={key2}>
                                                                        <TableCell colSpan={6}> 
                                                                            <Collapse in={open} timeout="auto" unmountOnExit>
                                                                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                    <UpdateUserForm 
                                                                                        role="Assessor" 
                                                                                        assessment={assessment}
                                                                                        handleSubmit={handleAssessorSubmit}
                                                                                        didUpdateFail={didUpdateAssessorFail}
                                                                                        errorMessage={updateAssessorMessage}
                                                                                        isLoading={isUpdatingAssessor}
                                                                                        didUpdateSucceed={didAssessorUpdatePass}
                                                                                        />
                                                                                </Box>
                                                                            </Collapse>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )}
                                                            </React.Fragment>
                                                        );
                                                    }
                                                })
                                            ) : ( 
                                                <TableSpinner isLoading={isOwnershipLoading} colSpan={headCells.length}/>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Container>
                    {/* <Container maxWidth='lg'>
                        {didAssessorUpdatePass  &&  ( 
                            <Typography color="#0bd17d" pt={2}  fontSize={15} >
                                Assessor Updated
                            </Typography>
                        )}
                    </Container> */}
                </Box>
            </Box>
            <Footer />
        </>
    );
}

// todo : add name columns

const headCells = [
    { id: 'status', numeric: false, label: 'Method' },
    { id: 'from', numeric: false, label: 'From' },
    { id: 'assessorName', numeric: false, label: 'Assessor Name' },
    { id: 'assessorEmail', numeric: false, label: 'Assessor Email' },
    { id: 'type', numeric: false, label: 'Type' },
    { id: 'displayCode', numeric: false, label: 'Code' },
    { id: 'assign', numeric: false, label: 'Action / Status' },
];

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const mapStateToProps = state => ({
    userInfo: state.auth.userInfo,
    ownershipData: state.assessment?.ownershipData,
    isUpdatingAssessor: state.assessment?.isUpdatingAssessor,
    didUpdateAssessorFail: state.assessment?.didUpdateAssessorFail,
    didAssessorUpdatePass: state.assessment?.didAssessorUpdatePass, 
    updateAssessorMessage: state.assessment?.updateAssessorMessage, 
    isOwnershipLoading: state.assessment?.isOwnershipLoading,
});

const mapDispatchToProps = dispatch => ({
    getList: email => dispatch(getOwnershipData(email)),
    assignAssessor: (accessCode, assessor) => dispatch(updateAssessor(accessCode, assessor)),
    sendEmail: (template, to, input) => dispatch(createEmail(template, to, input ))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignAssessor);