import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { Alert, Box, Typography, Container, Paper, Button, CircularProgress, TextField } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles'
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import TabBar from 'src/components/admin/TabBar';
import TextEditor from 'src/components/shared/TextEditor';
import { getPrescriptions, updatePrescription } from 'src/redux/thunks/adminPrescriptions';
import Loading from '../shared/Loading';

// Styled Components
const Accordion = styled(MuiAccordion)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': { borderBottom: 0 },
    '&:before': { display: 'none' },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': { transform: 'rotate(90deg)' },
    '& .MuiAccordionSummary-content': { marginLeft: theme.spacing(1) },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    height: 520,
}));

// TabView Component
const TabView = React.memo(({ index, tabsArr, onChange, onTaxonomyChange }) => {
    const [tabData, setTabData] = useState(tabsArr[0]);

    const handleTabChange = useCallback((newTab) => {
        setTabData(newTab);
    }, []);

    const handleEditorChange = useCallback((data) => {
        onChange(index, tabData.name, data);
    }, [index, tabData.name, onChange]
    );

    const handleTaxonomyChange = useCallback((e) => {
        onTaxonomyChange(index, tabData.name, e.target.value);
    }, [index, tabData.name, onTaxonomyChange]
    );

    return (
        <>
            <TabBar tabsArr={tabsArr} handleTabChange={handleTabChange} />
            <Box mt={2}>
                <TextField
                    fullWidth
                    label="Taxonomy"
                    margin="normal"
                    name="Taxonomy"
                    onChange={handleTaxonomyChange}
                    type="text"
                    value={tabData?.taxonomy || ''}
                    variant="outlined"
                />
                <TextEditor html={tabData?.content || ''} handleChange={handleEditorChange} />
            </Box>
        </>
    );
});

const EditPrescriptionReport = ({ handleBack }) => {
    const dispatch = useDispatch();

    const _prescriptions = useSelector((state) => state.adminPrescriptions.prescriptions);
    const isPrescriptionsLoading = useSelector(state => state.adminPrescriptions.isPrescriptionsLoading)
    const isPrescriptionsUpdating = useSelector((state) => state.adminPrescriptions.isPrescriptionsUpdating);
    const didPrescriptionsUpdatePass = useSelector((state) => state.adminPrescriptions.didPrescriptionsUpdatePass);
    const didPrescriptionsUpdateFail = useSelector((state) => state.adminPrescriptions.didPrescriptionsUpdateFail);

    const [content, setContent] = useState(null);
    const [expanded, setExpanded] = useState(false);
    const [changedIds, setChangedIds] = useState(new Set());

    const classes = useStyles();

    // useEffect(() => {
    //     dispatch(uploadData());
    // }, []);

    useEffect(() => {
        dispatch(getPrescriptions());
    }, [dispatch]);

    useEffect(() => {
        if (_prescriptions) {
            setContent(_prescriptions.sort((a, b) => a.processNumber - b.processNumber));
        }
    }, [_prescriptions]);

    const handleAccordionChange = useCallback(
        (panel) => (event, newExpanded) => {
            if (expanded !== panel) {
                setExpanded(newExpanded ? panel : false);
            } else {
                setExpanded(false)
            }
        },
        [expanded]
    );

    const handleTaxonomyChange = useCallback((id, contentName, newContent) => {
        setContent((prevContent) => {
            const updatedContent = prevContent.map((item, idx) => {
                if (idx === id) {
                    const updatedData = item.data.map((tab) =>
                        tab.name === contentName ? { ...tab, taxonomy: newContent } : tab
                    );
                    return { ...item, data: updatedData };
                }
                return item;
            });
            return updatedContent;
        });

        setChangedIds((prevChangedIds) => {
            const newChangedIds = new Set(prevChangedIds);
            newChangedIds.add(id); // Add the changed ID
            return newChangedIds;
        });
    }, []);

    const handleChange = useCallback((id, contentName, newContent) => {
        setContent((prevContent) => {
            const updatedContent = prevContent.map((item, idx) => {
                if (idx === id) {
                    const updatedData = item.data.map((tab) =>
                        tab.name === contentName ? { ...tab, content: newContent } : tab
                    );
                    return { ...item, data: updatedData };
                }
                return item;
            });
            return updatedContent;
        });

        setChangedIds((prevChangedIds) => {
            const newChangedIds = new Set(prevChangedIds);
            newChangedIds.add(id); // Add the changed ID
            return newChangedIds;
        });
    }, []);

    const saveChanges = () => {
        const changedContent = Array.from(changedIds).map((id) => content[id]); // Extract only the changed items
        dispatch(updatePrescription(changedContent));
    }

    if(isPrescriptionsLoading) return  <Loading />

    return (
        <>
            <Helmet>
                <title>Edit Prescription</title>
            </Helmet>
            <Box sx={{ minHeight: '100%', py: 3 }}>
                <Container maxWidth="lg">
                    <Typography variant="h2">Edit Prescription</Typography>
                </Container>
                <Box mt={4}>
                    <Container maxWidth='md'>
                        <Box mb={2}>
                            {isPrescriptionsUpdating && (
                                <Box className={classes.center}>
                                    <CircularProgress />
                                </Box>
                            )}
                            {didPrescriptionsUpdatePass && (
                                <Alert severity="success">Success — Prescription content updated</Alert>
                            )}
                            {didPrescriptionsUpdateFail && (
                                <Alert severity="error">Error - Prescription content did not update</Alert>
                            )}
                        </Box>
                    </Container>
                    <Container maxWidth="lg">
                        <Paper sx={{ padding: 5 }}>
                            <Box mb={4} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant="outlined" color="primary" onClick={handleBack} sx={{ width: 100 }}>
                                    Back
                                </Button>
                                <Button variant="outlined" color="secondary" onClick={saveChanges} sx={{ width: 100 }}>
                                    Save
                                </Button>
                            </Box>
                            {content &&
                                content.map((c, index) => (
                                    <Box key={`editcontent-${index}`} mt={1}>
                                        <Accordion
                                            expanded={expanded === index}
                                            onChange={handleAccordionChange(index)}
                                        >
                                            <AccordionSummary>
                                                <Typography>
                                                    <b>{c.category}:</b> &nbsp;{c.name}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {expanded === index && (
                                                    <TabView
                                                        index={index}
                                                        tabsArr={c.data}
                                                        onChange={handleChange}
                                                        onTaxonomyChange={handleTaxonomyChange}
                                                    />
                                                )}
                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                ))}
                            <Box mt={4} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button variant="outlined" color="primary" onClick={handleBack} sx={{ width: 100 }}>
                                    Back
                                </Button>
                                <Button variant="outlined" color="secondary" onClick={saveChanges} sx={{ width: 100 }}>
                                    Save
                                </Button>
                            </Box>
                        </Paper>
                    </Container>
                </Box>
            </Box>
        </>
    );
};

const useStyles = makeStyles({
    center: {
        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', width: '100%'
    }
});

EditPrescriptionReport.propTypes = {
    handleBack: PropTypes.func.isRequired,
};

export default EditPrescriptionReport;
