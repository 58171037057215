import { calcAxisSort } from 'src/redux/reducers/takingAssessment'

const parseCategory = (category) => {

    if (category === 'COMMERCIALIZATION: Category of Processes' || category === 'COMMERCIALIZATION') {
        return 'Commercialization'
    } else {
        return category
    }
}

export const transformData = (data) =>
    data.map((item) => {
        const evaluation = transformEvaluation(item.evaluation)
        const selections = evaluation.map((evalItem) => evalItem.selection)
        const average = calculateAverageSelection(selections)
        const quad = calcAxisSort(item)
        return {
            accessCode: item.accessCode,
            dateAssessed: item.dateAssessed.seconds,
            ...evaluation,
            vulnerable: quad[1][3],
            average,
        }
    })

const transformEvaluation = (evaluation) =>
    evaluation.flatMap(({ category, processes, average }) =>
        processes.flatMap(({ menuName, selection }) => ({
            category: parseCategory(category),
            processesName: menuName,
            selection,
            averageSection: average,
        }))
    )

// Function to calculate average of selections
const calculateAverageSelection = (selections) =>
    selections.reduce((acc, val) => acc + val, 0) / selections.length


const generateTableData = (data) => {
    const flattenedData = data.flatMap(item =>
        item.evaluation.map(evaluation => ({
            category: evaluation.category,
            process: evaluation.processesName,
        }))
    )

    const uniqueProcesses = [...new Map(flattenedData.map(item => [item.process, item])).values()]

    const dataWithEvaluations = uniqueProcesses.map(processItem => {
        const evaluations = data.reduce((acc, currentItem) => {
            const evaluation = currentItem.evaluation.find(e => e.processesName === processItem.process)
            if (evaluation) acc[currentItem.accessCode] = evaluation.selection
            return acc
        }, {})

        return { ...processItem, ...evaluations }
    })

    dataWithEvaluations.forEach(item => {
        const values = Object.values(item).filter(val => typeof val === 'number')
        const sum = values.reduce((acc, curr) => acc + curr, 0)
        item.average = values.length > 0 ? sum / values.length : 0
    })

    return dataWithEvaluations
}
