import {
    GET_TEMPLATES_REQUEST,
    GET_TEMPLATES_SUCCESS,
    GET_TEMPLATES_FAILURE,
    UPDATE_TEMPLATE_REQUEST,
    UPDATE_TEMPLATE_SUCCESS,
    UPDATE_TEMPLATE_FAILURE,
    SEND_EMAIL_REQUEST,
    SEND_EMAIL_SUCCESS,
    SEND_EMAIL_FAILURE,
} from '../actions/emails';

const defaultState = {
    //reports
    isSendingEmail: false,
    isTemplatesLoading: false,
    templates: [],
    getTemplateError: '',
    sendEmailError: ''
}

const emailTemplates = (state = defaultState, action) => {
    switch (action.type) {
        // case RESET_MSG:
        //     return {
        //         ...state,
        //         isTemplatesLoading: false,
        //         getReportError: '',
        //         didReportUpatePass: false,
        //         didReportUpdateFail: false,
        //         updateReportError: '',
        //     }
        //get Templates
        case GET_TEMPLATES_REQUEST:
            return {
                ...state,
                isTemplatesLoading: true,
            }
        case GET_TEMPLATES_SUCCESS:
            return {
                ...state,
                templates: action.data,
                isReportsLoading: false,
            }
        case GET_TEMPLATES_FAILURE:
            return {
                ...state,
                isTemplatesLoading: false,
                getTemplateError: action.message
            }
        case UPDATE_TEMPLATE_REQUEST:
            return {
                ...state,
                isTemplateUpdating: true,
                updateTemplateError: '',
            }
        case UPDATE_TEMPLATE_SUCCESS:
            return {
                ...state,
                isTemplateUpdating: false,
                templates: state.templates.map(item => item.name === action.data.name ? action.data : item)
            }
        case UPDATE_TEMPLATE_FAILURE:
            return {
                ...state,
                isTemplateUpdating: false,
                updateTemplateError: action.message
            }
        case SEND_EMAIL_REQUEST:
            return {
                ...state,
                isSendingEmail: true
            }
        case SEND_EMAIL_SUCCESS:
            return {
                ...state,
                isSendingEmail: false
            }
        case SEND_EMAIL_FAILURE:
            return {
                ...state,
                isSendingEmail: false,
                sendEmailError: action.data
            }
        default:
            return state;
    }
}

export default emailTemplates