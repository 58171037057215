import * as React from 'react';
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    }
}))

const Preview = ({ visible, onClose, data }) => {

    if(!data) return null

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={onClose}
                aria-labelledby="customized-dialog-title"
                open={visible}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {data.subject}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 2,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    x
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom
                    variant='body2'
                        dangerouslySetInnerHTML={{ __html: data.html }}
                    />
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    )
}

export default Preview