import React, { useEffect } from "react";
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css'; // Add css for snow theme
import PropTypes from "prop-types";

import { Box } from '@material-ui/core';

const TextEditor = (props) => {
    const { 
        html,
        handleChange,
        blur 
    } = props;

    const { 
        quill,
        quillRef 
    } = useQuill();

    useEffect(() => {
        if (quill) {
            // Paste existing HTML if different
            if (quill.root.innerHTML !== html) {
                quill.clipboard.dangerouslyPasteHTML(html || '');
            }

            // Attach the change handler
            const onTextChange = () => handleChange(quill.root.innerHTML);
            quill.on('text-change', onTextChange);

            if (blur) quill.blur();

            // Cleanup listener
            return () => {
                quill.off('text-change', onTextChange);
            };
        }
    }, [quill, html, blur, handleChange]);

    return (
        <Box style={{ height: 300 }}>
            <div ref={quillRef} />
        </Box>
    );
};

TextEditor.propTypes = {
    html: PropTypes.string,
    handleChange: PropTypes.func,
    blur: PropTypes.bool,
};

export default TextEditor;
