import { Navigate } from 'react-router-dom';
import NotFound from './views/NotFound';

import DashboardLayout from './components/navbar/app/DashboardLayout';
import HomeLayout from './components/navbar/landing/HomeLayout';
import AssessmentLayout from './components/navbar/assessment/AssessmentLayout';
import AdminLayout from './components/navbar/admin/AdminLayout';
//home layout
import LandingPage from './views/landing/LandingPage';
import About from './views/landing/About';
import Register from './views/landing/Register';
import Terms from './views/landing/Terms';
import Admin from './views/landing/Admin';
import ResetPassword from './views/landing/ResetPassword';
import DownloadReport from './views/app/DownloadReport';
import Learn from './views/landing/Learn';
import Welcome from './views/landing/Welcome';
//app layout
import ViewAssessment from './views/app/ViewAssessment';
import TakeAssessment from './views/app/TakeAssessment';
import AssignAssessor from './views/app/AssignAssessor';
import BuyAssessment from './views/landing/BuyAssessment';
import TransferOwnership from './views/app/TransferOwnership';
import Guide from './views/app/Guide';
import PurchaseHistory from './views/app/PurchaseHistory';
import Contact from './views/app/Contact';
import Logout from './views/app/Logout';
import InformationForm from './views/app/InformationForm';
import AssessmentDetails from './views/app/AssessmentDetails';
import MyPanel from './views/app/MyPanel';
import QuickHelp from './views/app/QuickHelp';
//assessment layout
import AssessmentEvaluation from './views/assessment/AssessmentEvaluation';
import AssessmentReport from './views/assessment/AssessmentReport';
//admin layout
import AssessmentList from './views/admin/AssessmentList';
import AccessCodes from './views/admin/AccessCodes';
import Success from './views/Stripe/Success';
import Cancel from './views/Stripe/Cancel';
import ReportsList from './views/admin/ReportsList';
import HeatMap from './views/app/ViewReports/HeatMap';
import EmailTemplates from './views/admin/EmailTemplates';

const routes = (isAuthenticated, isTakingAssessment, isAdmin) => {

    return (
        [
            {   //admin
                path: 'admin',
                element: (isAuthenticated && isAdmin) ? <AdminLayout /> : <Navigate to='/' />,
                children: [
                    { path: 'assessment-content', element: <AssessmentList /> },
                    { path: 'access-codes', element: <AccessCodes /> },
                    { path: 'buy', element: <BuyAssessment /> },
                    { path: 'reports', element: <ReportsList /> },
                    { path: 'templates', element: <EmailTemplates /> },
                    { path: 'view', element: <DownloadReport /> },
                    { path: 'logout', element: <Logout /> },
                ]
            }, {
                //assessment evaluation
                path: 'assessment',
                element: (isAuthenticated && isTakingAssessment) ? <AssessmentLayout /> : <Navigate to='/' />,
                children: [
                    { path: 'evaluation', element: <AssessmentEvaluation /> },
                    { path: 'report', element: <AssessmentReport /> },
                ]
            }, {
                //main dashboard
                path: 'app',
                element: isAuthenticated
                    ? (isTakingAssessment
                        ? <Navigate to='/assessment/evaluation' />
                        : (isAdmin
                            ? <Navigate to='/admin/assessment-content' />
                            : <DashboardLayout />
                        )
                    ) : <Navigate to='/' />,
                children: [
                    { path: 'view', element: <ViewAssessment /> },
                    { path: 'heatmap', element: <HeatMap />},
                    { path: 'take', element: <TakeAssessment /> },
                    { path: 'assign', element: <AssignAssessor /> },
                    { path: 'buy', element: <BuyAssessment /> },
                    { path: 'transfer', element: <TransferOwnership /> },
                    { path: 'guide', element: <Guide /> },
                    { path: 'history', element: <PurchaseHistory /> },
                    { path: 'contact', element: <Contact /> },
                    { path: 'logout', element: <Logout /> },
                    { path: 'information', element: <InformationForm /> },
                    { path: 'assessment-details', element: <AssessmentDetails /> },
                    { path: 'download', element: <DownloadReport /> },
                    { path: 'mypanel', element: <MyPanel /> },
                    { path: 'help', element: <QuickHelp /> },
                    { path: 'register', element: <Register /> },
                    { path: '404', element: <NotFound /> }
                ]
            },
            {
                //unauthenticated
                path: '/',
                element: isAuthenticated ? <Navigate to='app/mypanel' /> : <HomeLayout />,
                children: [
                    { path: '/', element: <LandingPage /> },
                    { path: 'learn', element: <Learn /> },
                    { path: 'about', element: <About /> },
                    { path: 'terms', element: <Terms /> },
                    { path: 'welcome', element: <Welcome /> },
                    { path: 'admin-login', element: <Admin /> },
                    { path: 'register', element: <Register /> },
                    { path: 'reset', element: <ResetPassword /> },
                    { path: '404', element: <NotFound /> },
                    { path: 'buy', element: <BuyAssessment /> },
                    { path: '/home', element: <Navigate to="/" /> },
                    { path: '*', element: <Navigate to="/404" /> }
                ]
            },
            {
                //unauthenticated
                path: '/payment',
                element: isAuthenticated ? <Navigate to='app/mypanel' /> : <HomeLayout />,
                children: [
                    { path: 'success', element: <Success /> },
                    { path: 'cancel', element: <Cancel /> },
                ]
            }
        ]
    );

}

export default routes;
