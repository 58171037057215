import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from "react-redux";
import { claimAllCodes, loadAllAssessmentData, logoutUser} from 'src/redux/thunks';
import { Container, Box, Typography } from '@material-ui/core'
import HeaderButtons from 'src/components/shared/HeaderButtons';
import { assignedSuccess, ownershipDataSuccess, setNavigation, setNewAssets } from 'src/redux/actions';
import NoAssetsModal from 'src/components/shared/NoAssetsModal';
import PageOverlay from 'src/components/shared/PageOverlay';
import RegistrationForm from '../landing/Registration/Form';
import Loading from 'src/components/shared/Loading';
import Footer from 'src/components/shared/Footer';
import AcceptTermsModal from 'src/components/shared/AcceptTermsModal';
import moment from 'moment';

const TERMS_DATE = 1733127563

function isEarlierDay(timestamp1, timestamp2) {
    const date1 = moment.unix(timestamp1).startOf('day'); // Convert to start of day
    const date2 = moment.unix(timestamp2).startOf('day'); // Convert to start of day
    return date1.isBefore(date2); // Check if date1 is before date2
}

const MyPanel = () => {

    const dispatch = useDispatch()

    const accessCode = useSelector(state => state.auth.accessCode)
    const userInfo = useSelector(state => state.auth.userInfo)
    const ownershipData = useSelector(state => state.assessment.ownershipData)
    const assessmentHistory = useSelector(state => state.assessment.assessmentHistory)
    const assignedAssessments = useSelector(state => state.assessment.assignedAssessments)
    const isLoadingAllData = useSelector(state => state.assessment.isLoadingAllData)
    const newAssets = useSelector(state => state.auth.newAssets)
    const newUser = useSelector(state => state.auth.newUser)

    const [showNoAssetsModal, setShowNoAssetsModal] = useState(false)
    const [showRegistration, setShowRegistration] = useState(false)
    const [showToC, setShowToc] = useState(false)

    useEffect(() => {

        if (isEarlierDay(userInfo.toc.seconds, TERMS_DATE)) {
            setShowToc(true)
        }

        dispatch(loadAllAssessmentData(userInfo.email))

        return () => {
            setShowToc(false); // Clear the data when the component unmounts or on re-run
        }
    }, [userInfo])

    useEffect(() => {
        if (!isLoadingAllData) {
            let noAssetsFound = true

            ownershipData.forEach(data => {
                if (!data.owner?.isClaimed || data.proposedOwner === userInfo.email) {
                    dispatch(setNewAssets(data))
                }
                noAssetsFound = false
            })

            assignedAssessments.forEach(assigned => {
                if (!assigned.assessor.isClaimed) {
                    dispatch(setNewAssets(assigned))
                }
                noAssetsFound = false

            })

            assessmentHistory.forEach(history => {
                noAssetsFound = false
            })

            if (noAssetsFound) {
                setShowNoAssetsModal(true)
            } else {
                setShowNoAssetsModal(false)
            }
        }
    }, [isLoadingAllData, ownershipData, assignedAssessments])

    useEffect(() => {
        if (accessCode && accessCode.owner?.hasOwnProperty('isClaimed') && !accessCode.owner?.isClaimed) {
            setShowNoAssetsModal(false)
            dispatch(setNewAssets(accessCode))
            if (accessCode.assessor?.email === userInfo.email) {
                dispatch(assignedSuccess(accessCode))
                dispatch(setNavigation([1, 0, 0, 0]))
            } else if (accessCode.owner?.email === 'adminpurch@vectorreports.com' || accessCode.owner?.email === userInfo.email) {
                dispatch(setNavigation([1, 0, 1, 1]))
                dispatch(ownershipDataSuccess(accessCode))
            }

        }
    }, [accessCode])

    useEffect(() => {

        if (newAssets.length > 0 && !newUser) {
            setShowRegistration(true)
        } else if (newAssets.length > 0 && newUser) {
            dispatch(claimAllCodes(newAssets, userInfo))
        } else {
            setShowRegistration(false)
        }

    }, [newAssets, newUser])


    const onCancelNewAssetRegistration = () => {
        dispatch(logoutUser())
    }

    if (isLoadingAllData) return <Loading />

    return (
        <>
            <Helmet>
                <title>Vector Reports | My Panel</title>
            </Helmet>
            {
                !showRegistration &&
                <Box my={8} mx={4} sx={{ minHeight: '86%', py: 3 }} >
                    <Container maxWidth={"lg"}>
                        {/* Header */}
                        <Typography color="textPrimary" variant="h2" style={{ fontWeight: 600 }}>
                            My Panel
                        </Typography>
                        <HeaderButtons />
                    </Container>
                </Box>
            }

            {!showRegistration && showToC && <AcceptTermsModal isVisible={true} handleClose={() => setShowToc(false)} />}

            {showRegistration && <PageOverlay isOpen={showRegistration}>
                <RegistrationForm onBack={onCancelNewAssetRegistration} />
            </PageOverlay>}


            {!showRegistration && <NoAssetsModal show={showNoAssetsModal} />}
            <Footer />
        </>
    )
}

export default MyPanel;