import { jsPDF } from "jspdf";
import moment from "moment";

const STATIC_TEXT = {
    welcome: "This report is a subset of the VIA-Business Main Report on the Most Vulnerable business process for this company for the date indicated above. It describes specific actions to be taken to reduce that vulnerability and improve to the next level of process maturity on the Maturity Development Scale. Please refer to that report for further context as needed."
}

// Utility function for adding footer
const addFooter = (doc, pageNumber, pageWidth, pageHeight) => {
    const halfWidth = pageWidth / 2;
    const rightMargin = pageWidth - 20;

    doc.setFontSize(8);
    doc.text(`Proprietary - Do Not Disclose Copyright Vector Reports`, halfWidth, pageHeight - 10, null, null, "center");
    doc.text(`${pageNumber}`, rightMargin, pageHeight - 10, { align: 'center' });
};

// Utility function for adding footer
const addHeader = (doc, pageNumber, pageWidth, pageHeight) => {
    const halfWidth = pageWidth / 2;
    const rightMargin = pageWidth - 20;

    doc.setFontSize(8);
    doc.text(`Proprietary - Do Not Disclose Copyright Vector Reports`, halfWidth, pageHeight - 10, null, null, "center");
    doc.text(`${pageNumber}`, rightMargin, pageHeight - 10, { align: 'center' });
};

// Utility function to add centered text
const addCenteredText = (doc, text, fontSize, yPosition, pageWidth, options = {}) => {
    const halfWidth = pageWidth / 2;
    doc.setFontSize(fontSize);
    doc.text(text, halfWidth, yPosition, null, null, "center", options);
};

export const createPrescriptionPDF = (dynamicContent) => {
    if (!dynamicContent) {
        console.error("No content provided to generate the PDF.");
        return;
    }

    try {
        const doc = new jsPDF();
        const pageHeight = doc.internal.pageSize.height;
        const pageWidth = doc.internal.pageSize.width;
        const margin = 20;

        const logo = '/static/logo.png';
        const date = moment().format("MM/DD/YYYY");

        // Cover Page
        addCenteredText(doc, `Date: ${date}`, 9, 20, pageWidth);
        const logoWidth = 40;
        const logoHeight = logoWidth * 0.456;
        doc.addImage(logo, (pageWidth - logoWidth) / 2, 45, logoWidth, logoHeight);

        addCenteredText(doc, "Improvement", 16, 110, pageWidth);
        addCenteredText(doc, "Prescription", 28, 120, pageWidth);

        addCenteredText(doc, "Customer Identification", 12, 155, pageWidth);

        doc.setFont('undefined', 'bold');
        addCenteredText(doc, dynamicContent.prescriptionTitle || "N/A", 12, 165, pageWidth);

        doc.setFont('undefined', 'normal');
        addCenteredText(doc, dynamicContent.subTitle || "N/A", 12, 175, pageWidth);

        addFooter(doc, 1, pageWidth, pageHeight);

        doc.addPage();

        // How to Use This Document
        doc.setFont('undefined', 'bold');
        doc.setFontSize(12);
        doc.text("Welcome", margin, 20);

        doc.setFont('undefined', 'normal');
        doc.text(doc.splitTextToSize(STATIC_TEXT.welcome, pageWidth - 2 * margin), margin, 30);

        addFooter(doc, 2, pageWidth, pageHeight);
        doc.addPage();

        // Most Vulnerable Process Recommendations
        doc.setFontSize(16);
        doc.text("Most Vulnerable Process Recommendations", margin, 20);

        doc.setFontSize(12);
        const recommendationText = dynamicContent.recommendationText || 
            "Focus on addressing the vulnerabilities identified in the process to achieve sustainable improvements.";
        doc.text(doc.splitTextToSize(recommendationText, pageWidth - 2 * margin), margin, 30);

        addFooter(doc, 3, pageWidth, pageHeight);
        doc.addPage();

        // Guidance to the Provider
        doc.setFontSize(16);
        doc.text("Guidance to the Provider", margin, 20);

        doc.setFontSize(12);
        const providerGuidance = dynamicContent.providerGuidance || 
            "Assist the company by presenting these recommendations to a relevant external support organization (ESO). Ensure all stakeholders understand the objectives and action plan.";
        doc.text(doc.splitTextToSize(providerGuidance, pageWidth - 2 * margin), margin, 30);

        addFooter(doc, 4, pageWidth, pageHeight);

        // Save the PDF
        doc.save("prescription_document.pdf");
    } catch (error) {
        console.error("Error generating PDF:", error);
    }
};
